import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { RelatedTransaction } from '../../../models/film/related-transactions.model';
import { Subscription ,  Subject } from 'rxjs';


declare var $;
declare type ModalMode = 'Add' | 'Edit';

export interface TransactionChangeEvent {
  action: 'add' | 'edit' | 'delete';
  relatedTransaction: RelatedTransaction;
  index: number;
}

@Component({
  selector: 'fl-related-transactions',
  templateUrl: './related-transactions.component.html',
  styleUrls: ['./related-transactions.component.css']
})
export class RelatedTransactionsComponent implements OnInit {
  @Input() relatedTransactions: RelatedTransaction[];
  readonly: boolean;
  relatedTransactionForm: FormGroup;
  mode: ModalMode;
  selectedTransaction: RelatedTransaction;
  selectedRelatedTransactionIndex: number;
  relatedTransactionChanged: Subject<TransactionChangeEvent>;

  constructor( private fb: FormBuilder) {}

  delete(index: number) {
    // Remove related transaction at particular index
    const removedTransactions = this.relatedTransactions.splice(index, 1);

    // Notify listeners that a transaction was deleted
    this.relatedTransactionChanged.next({
      action: 'delete',
      relatedTransaction: removedTransactions[0],
      index: index
    });

  }

  ngOnInit() {
    this.relatedTransactionForm = this.fb.group({
      name: ['', Validators.required],
      relationship: ['', Validators.required],
      transactionAmount: ['', Validators.required]
    });
  }

  save() {
    // Transfer data from form model to data model
    Object.assign(this.selectedTransaction, this.relatedTransactionForm.value);

    if (this.mode === 'Add') {
      // Add new Related Transaction
      this.relatedTransactions.push(this.selectedTransaction);
    }
  }
  select(modalMode: ModalMode, index?: number) {
    const that = this;
    const transaction =
      index !== undefined && index != null
        ? this.relatedTransactions[index]
        : new RelatedTransaction().init();
    this.mode = modalMode;
    this.selectedTransaction = transaction;
    this.selectedRelatedTransactionIndex = index;
  }
}

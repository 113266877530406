import { AuthenticationService } from '../../security/services/authentication.service';
import { environment } from '../../../environments/environment';
import { Observable, throwError, asyncScheduler } from 'rxjs';
import { map, catchError, subscribeOn } from 'rxjs/operators';
import { Payment } from '../../fiscal/models/payment.model';
import { Project } from '../models/project.model';
import { ProjectList } from '../models/project-list.model';
import { ResolvedPayment } from '../../fiscal/models/resolved-payment.model';
import { ProjectFormWrapper } from '../models/project-form-wrapper.model';
import { FormExternalReviewSummary } from '../../external/interfaces/external-review-summary.interface';
import { ProjectAccessRequest } from '../models/project-access-request.model';
import { StatusLog } from '../models/status-log.model';
import { ProjectDate } from '../models/project-date.model';
import { ProjectInfo } from '../models/project-info.model';
import { FormIdentifier } from '../models/form-identifier.model';
import { PublicAdvance } from '../../core/models/public-advance.model';
import { SwalService } from '../../fastlane-common/services/swal.service';
import { Legislator } from '../models/legislator.model';
import { PublishSubscribeService } from '../../fastlane-common/services/publish-subscribe.service';
import { Router } from '@angular/router';
import {
  incentiveProgram,
  incentiveCategory,
  entProjectStatus,
  dateTypes,
  legislationRuleNames
} from '../project.constants';
import { formStatuses, formTypes } from '../../form/form.constants';
import { FormStatusChange } from '../../fastlane-common/event/interfaces/form-status-event.interface';
import { EventContext } from '../../fastlane-common/event/interfaces/event-context.interface';
import { events } from '../../fastlane-common/event/event.constants';
import { getProjectFormIdFromWrapper } from '../project.functions';
import { oneToManyFormTypes } from '../../form/form.constants';
import { HybridProject } from '../models/hybrid-project.model';
import { ProjectBase } from '../models/project-base.model';
import { Credit as FilmCredit } from '../../entertainment/models/film/credit.model';
import { OutgoingPayment } from '../../fiscal/models/outgoing-payment.model';
import { ProjectDetailService } from './project-shared.service';
import {
  httpResponseCodes,
  httpStatusCodeToErrorMap
} from '../../shared/shared.constants';
import { CreditStatuses } from '../../entertainment/credit.constants';
import { ToastService } from '../../fastlane-common/services/toast.service';
import { Audit } from '../../entertainment/models/film/audit.model';
import { Credit as DigitalCredit } from '../../entertainment/models/digital/credit.model';
import { CreditType } from '../../entertainment/models/creditType.model';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavService } from '../../fastlane-common/services/nav.service';
import { getUserModule } from '../../user/user.functions';
import { UserContextService } from '../../user/services/user-context.service';
import { ExtensionRequest } from '../../project/models/extension-request.model';
import { addDays, addYears } from '../../shared/shared.functions';
import { boardStatus } from '../../management/management.constants';
import { Application } from '../../project/models/application.model';
import { Renewal } from '../../incentive/renewal/models/renewal.model';
import { ProjectForm } from '../models/form.model';
import { OrbiPayPaymentRequest} from '../../../app/form/models/orbipay-payment-request.model'

//#region Types
export interface ResolvedProject {
  project: ProjectBase;
  payments: Payment[];
  credits?: CreditType[];
  projectDates: ProjectDate[];
  projectBase?: ProjectBase;
}

export class HybridCredits {
  creditType: CreditType[];
  filmCredits?: FilmCredit[];
  digitalCredits?: DigitalCredit[];

  constructor(hybridCredit?: any) {
    hybridCredit = hybridCredit || {};
    this.filmCredits = hybridCredit.filmCredits || null;
    this.digitalCredits = hybridCredit.digitalCredits || null;
  }
}

export interface DataTablesResponse {
  draw: number;
  data: any[];
  recordsTotal: number;
  recordsFiltered: number;
}

export interface ResolvedPaymentsResponse {
  draw: number;
  data: ResolvedPayment[];
  recordsTotal: number;
  recordsFiltered: number;
}

export interface ExternalReveiwFlatFormResponse {
  draw: number;
  data: FormExternalReviewSummary[];
  recordsTotal: number;
  recordsFiltered: number;
}

export interface ProjectListResponse {
  draw: number;
  data: Project[];
  recordsTotal: number;
  recordsFiltered: number;
}

export interface ProjectAcessRequestListResponse {
  draw: number;
  data: ProjectAccessRequest[];
  recordsTotal: number;
  recordsFiltered: number;
}

export interface PublicAdvanceListResponse {
  draw: number;
  data: PublicAdvance[];
  recordsTotal: number;
  recordsFiltered: number;
}
//#endregion
@Injectable({ providedIn: 'root' })
export class ProjectDataService {
  //#region Properties
  private readonly canUserAccessProjectUrl = `${environment.apiUrl}/project/access?email={email}&projectGuid={projectGuid}`;
  private readonly createProjectUrl = environment.apiUrl + '/project/create';
  private readonly getBoardMeetingDateUrl = environment.apiUrl + '/board/date';
  private readonly getSignatureDocumentsForProjectUrl =
    environment.apiUrl + '/Agreement/getAgreements/';
  private readonly getManagementProjectsUrl =
    environment.apiUrl + '/form/datatables/list';
  private readonly getExternalReviewFormsUrl =
    environment.apiUrl + '/management/datatables/er-forms';
  private readonly getExternalReviewFlatFormsUrl =
    environment.apiUrl + '/management/datatables/er-flat-forms';
  private readonly getPaymentsUrl =
    environment.apiUrl + '/payment/getPayments?id=';
  private readonly getProjectsUrl = environment.apiUrl + '/project/all';
  private readonly getAssessorProjectsForDataTablesUrl =
    environment.apiUrl + '/project/datatables/assessorList';
  private readonly getProjectsForDataTablesUrl =
    environment.apiUrl + '/project/datatables/list';
  private readonly cocReqAdvanceUrl =
    environment.apiUrl + '/project/CocRequiredAdvance';
  private readonly getInitialCertsUrl =
    environment.apiUrl + '/form/datatables/initialCert';
  private readonly getFormDataUrl = environment.apiUrl + '/form/dashboard';

  private readonly getCreditsForProjectsUrl =
    environment.apiUrl + '/credit/credits';
  private readonly getPublicReportsForDataTablesUrl =
    environment.apiUrl + '/report/publicReport/datatables/list';
  private readonly getProjectIdUrl = environment.apiUrl + '/project/getId';
  private readonly getProjectUrl = environment.apiUrl + '/project/search?id=';
  private readonly getProjectFromIdUrl = environment.apiUrl + '/project/getProjectById?projectId=';
  private readonly getProjectFromProjectIdUrl =
    environment.apiUrl + '/project/access/request/validate/';
  private readonly insertProjectAccessRequestUrl =
    environment.apiUrl + '/project/access/request/create';
  private readonly updateProjectAccessRequestUrl =
    environment.apiUrl + '/project/access/request/update/';
  private readonly getProjectAccessRequestForDataTableUrl =
    environment.apiUrl + '/project/access/request/datatables/list';
  private readonly payPaymentUrl =
    environment.apiUrl + '/payment/getConfirmation?projectId=';
  private readonly updateCreditsUrl =
    environment.apiUrl + '/credit/update/{guid}';
  private readonly updateCreditStatusUrl =
    environment.apiUrl + '/credit/updatestatus/{status}/{sourceIndex}';
  private readonly updateProjectStatusUrl =
    environment.apiUrl + '/project/updatestatus/{status}';
  private readonly updateProjectUrl = environment.apiUrl + '/project/update';
  private readonly refreshProjectSnapshotsUrl =
    environment.apiUrl + '/FormSnapshot/generate';
  private readonly createFormSnapshotUrl =
    environment.apiUrl + '/FormSnapshot/create';
  private readonly getProjectsCountUrl = environment.apiUrl + '/project/count';
  private readonly resolveProjectsUrl =
    environment.apiUrl + '/project/resolveProject?id=';
  private readonly resolveProjectInfoUrl =
    environment.apiUrl + '/project/redactedProjectInfo?id=';
  private readonly createPaymentUrl =
    environment.apiUrl + '/payment/createPayment';
  private readonly createOutgoingPaymentUrl =
    environment.apiUrl + '/payment/createOutgoingPayment';
  private readonly updateOutgoingPaymentUrl =
    environment.apiUrl + '/payment/updateOutgoingPayment';
  private readonly getOutgoingPaymentUrl =
    environment.apiUrl + '/payment/getOutgoingPayment';
  private readonly updatePaymentsUrl = environment.apiUrl + '/payment/update';
  private readonly simulateSuccessfulPaymentUrl =
    environment.apiUrl + '/payment/simulateSuccessfulPayment';
  private readonly updateFormDeclinedPaymentFeesUrl =
    environment.apiUrl + '/form/updateFormDeclinedPaymentFees';
  private readonly updateProjectFormsUrl =
    environment.apiUrl + '/form/updateProjectForms';
  private readonly registerConfirmationUrl =
    environment.apiUrl + '/payment/registerConfirmation';
  private readonly getUnclassifiedPaymentsUrl =
    environment.apiUrl + '/payment/getUnclassifiedPayments';
    private readonly getPaymentConfirmationOrbiPayUrl =
    environment.apiUrl + '/payment/postToken';
  private readonly getAllPaymentsForDataTablesUrl =
    environment.apiUrl + '/payment/datatables/all?';
  private readonly getRelatedProjectsUrl =
    environment.apiUrl + '/project/getRelatedProjects?year=';
  private readonly getProjectDatesUrl =
    environment.apiUrl + '/project/dates/{id}';
  private readonly createProjectDateUrl =
    environment.apiUrl + '/project/date/create';
  private readonly updateProjectDateUrl =
    environment.apiUrl + '/project/date/update';
  private readonly updateProjectDatesUrl =
    environment.apiUrl + '/project/dates/update/{guid}';
  private readonly deleteProjectDateUrl =
    environment.apiUrl + '/project/date/delete/{id}';
  private readonly getProjectInfosForBaseProjectUrl =
    environment.apiUrl + '/project/getProjectPhases/{projectId}';
  private readonly createNewProjectPhaseUrl =
    environment.apiUrl + '/project/createProjectPhase/{projectId}/{phase}';
  private readonly getEntApplicationsCountUrl =
    environment.apiUrl + '/form/appCount?program=';
  private readonly getLegislatorsForFormUrl = `${environment.apiUrl}/form/legislators`;
  private readonly postAppealFormUrl = `${environment.apiUrl}/form/appeal/{appealBoardMeetingId}/{projectGuid}/{formType}{formIndex?}?appealComments={appealComments}`;
  // The delay in milliseconds and will be used by cancellable functions.
  private readonly cancellableDelay = 1000;
  //#endregion
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private swalService: SwalService,
    private projectShareService: ProjectDetailService,
    private pubSubService: PublishSubscribeService,
    private toast: ToastService,
    private navService: NavService,
    private router: Router,
    private userContext: UserContextService
  ) {
    const that = this;

    this.pubSubService.handle<EventContext<FormStatusChange>>(
      events.formStatusChanges.code,
      formStatuses.reviewComplete.name,
      eventContext =>
        that
          .getLegislatorsForForm(
            getProjectFormIdFromWrapper(eventContext.data.projectFormWrapper)
          )
          .subscribe()
    );

    pubSubService.handle<EventContext<FormStatusChange>>(
      events.formStatusChanges.code,
      formStatuses.reviewComplete.name,
      context => {
        // Update the credit status and project Status.
        const form = context.data.projectFormWrapper.form;
        const formId = new FormIdentifier({
          formType: form.type,
          formIndex: form.formIndex,
          projectGuid: context.data.projectFormWrapper.projectGuid
        });

        let status = '';
        if (
          context.data.projectFormWrapper.projectInfo.incentiveProgram ===
          incentiveProgram.dm.code
        ) {
          if (form.type === formTypes.application.abbrev) {
            const url = that.navService.convertUrlTreeToRootString([
              'management',
              'projects',
              context.data.projectFormWrapper.projectGuid,
              'certification',
              'initialCerts'
            ]);
            console.log(url);
            that.router.navigateByUrl(url);
          }
          if (form.type === formTypes.initialCert.abbrev) {
            if (formId.formIndex === 0) {
              status = entProjectStatus.projectActive;
              that.updateProjectStatus(formId, status).subscribe(
                success => {
                  toast.queue({
                    text: 'Updating Project status is successful!!'
                  });
                },
                error => {
                  toast.error({
                    text: 'Failed to update the Project status!!'
                  });
                }
              );
            }
          }
          if (form.type === formTypes.audit.abbrev) {
            // Since we cannot determine which audit is last audit we cant set the project status.
            // According to Kate the manager gets to decide when the project status changes to Project Complete.
            if (false) {
              status = entProjectStatus.projectComplete;
              that.updateProjectStatus(formId, status).subscribe(
                success => {
                  toast.queue({
                    text: 'Updating Project status is successful!!'
                  });
                },
                error => {
                  toast.error({
                    text: 'Failed to update the Project status!!'
                  });
                }
              );
            }
          }
        }
        if (
          context.data.projectFormWrapper.projectInfo.incentiveProgram ===
          incentiveProgram.film.code
        ) {
          if (form.type === formTypes.application.abbrev) {
            const url = that.navService.convertUrlTreeToRootString([
              'management',
              'projects',
              context.data.projectFormWrapper.projectGuid,
              'certification',
              'initialCerts'
            ]);
            console.log(url);
            that.router.navigateByUrl(url);
          }
          if (form.type === formTypes.initialCert.abbrev) {
            status = CreditStatuses.reserved;
            that.updateCreditStatus(formId, status, 0).subscribe(
              success => {
                if (formId.formIndex === 0) {
                  status = entProjectStatus.projectActive;
                  that.updateProjectStatus(formId, status).subscribe(
                    success => {
                      toast.queue({
                        text: 'Updating Credit status is successful!!'
                      });
                    },
                    error => {
                      toast.error({
                        text: 'Failed to update the Project status!!'
                      });
                    }
                  );
                } else {
                  toast.queue({
                    text: 'Updating Credit status is successful!!'
                  });
                }
              },
              error => {
                toast.error({
                  text: 'Failed to update the credit status!!'
                });
              }
            );
          }
          if (form.type === formTypes.audit.abbrev) {
            status = CreditStatuses.issued;
            const audit = <Audit>form;
            that
              .updateCreditStatus(formId, status, audit.sourceFormId.formIndex)
              .subscribe(
                success => {
                  if (
                    (audit.totalCreditsDeferred === 0 ||
                      !audit.totalCreditsDeferred) &&
                    audit.productionDetails.productionType !==
                      'Scripted Episodic Content'
                  ) {
                    status = entProjectStatus.projectComplete;
                    that.updateProjectStatus(formId, status).subscribe(
                      success => {
                        toast.queue({
                          text: 'Updating Credit status is successful!!'
                        });
                      },
                      error => {
                        toast.error({
                          text: 'Failed to update the Project status!!'
                        });
                      }
                    );
                  } else {
                    toast.queue({
                      text: 'Updating Credit status is successful!!'
                    });
                  }
                },
                error => {
                  toast.error({
                    text: 'Failed to update the credit status!!'
                  });
                }
              );
          }
        }
      }
    );
    //#region ProjectDatesEventHandlers

    /**
     * Sets or Updates the Application Due Date when the project extension request is review complete
     * and falls under RTA, ITE and EZ
     * @param eventType
     * @param eventAction
     * @param next
     */
    pubSubService.handle<EventContext<FormStatusChange>>(
      events.formStatusChanges.code,
      formStatuses.reviewComplete.name,
      context => {
        if (
          context.data.projectFormWrapper.form.type ===
          formTypes.extensionRequest.abbrev
        ) {
          const extensionRequest = context.data.projectFormWrapper
            .form as ExtensionRequest;
          const program =
            context.data.projectFormWrapper.projectInfo.incentiveProgram;
          const projectGuid = context.data.projectFormWrapper.projectGuid;
          if (
            [
              incentiveProgram.ez.code,
              incentiveProgram.ite.code,
              incentiveProgram.rta.code
            ].includes(program)
          ) {
            const newApplicationDueDate =
              program === incentiveProgram.ez.code ||
              program === incentiveProgram.ite.code
                ? addDays(new Date(extensionRequest.newEndDate), 90)
                : program === incentiveProgram.rta.code
                ? addDays(new Date(extensionRequest.newStartDate), 90)
                : new Date();
            that.getProjectDates(projectGuid).subscribe(projectDates => {
              const applicationDueDateIndex = projectDates.findIndex(
                pd => pd.type === dateTypes.applicationDueDate.name
              );
              if (applicationDueDateIndex >= 0) {
                projectDates[
                  applicationDueDateIndex
                ].date = newApplicationDueDate;
              } else {
                const newDate = new ProjectDate();
                newDate.type = dateTypes.applicationDueDate.name;
                newDate.category = dateTypes.applicationDueDate.category;
                newDate.formId = new FormIdentifier();
                newDate.formId.projectGuid = projectGuid;
                newDate.created = new Date();
                newDate.date = newApplicationDueDate;
                projectDates.push(newDate);
              }
              that.updateProjectDates(projectDates, projectGuid).subscribe();
            });
          }
        }
      }
    );

    /**
     * Sets or Updates the Contract Effective Date and Contract Expiration Date when the Application is Board Approved
     * for the applications under QJ, ITE and EZ
     * Sets or Updates the Renewal Contract Effective Date and Renewal Contract Expiration Date when the Application is Board Approved
     * for the applications under QJ, ITE and RTA
     * @param eventType
     * @param eventAction
     * @param next
     */
    pubSubService.handle<EventContext<ProjectFormWrapper[]>>(
      events.boardStatusChanges.code,
      boardStatus.Approved.name,
      context => {
        context.data.forEach(projectFormWrapper => {
          //#region Application Board Approved Handler
          if (projectFormWrapper.form.type === formTypes.application.abbrev) {
            const application = projectFormWrapper.form as Application;
            const program = projectFormWrapper.projectInfo.incentiveProgram;
            const projectGuid = projectFormWrapper.projectGuid;
            // Proceed for changing effective and expiration dates only if it falls in the following programs
            if (
              [
                incentiveProgram.qj.code,
                incentiveProgram.ite.code,
                incentiveProgram.ez.code
              ].includes(program)
            ) {
              // Get all the project dates for this project.
              that.getProjectDates(projectGuid).subscribe(projectDates => {
                //#region Contract Effective Date

                // This is the handler to determine ITE Effective date based on parish
                const getITEEffectiveDate = (
                  endDate: Date,
                  parish: string
                ): Date => {
                  if (parish === 'Orleans') {
                    const year =
                      endDate.getMonth() < 7
                        ? endDate.getFullYear()
                        : endDate.getFullYear() + 1;
                    // Setting date to 07/31/year
                    return new Date(year, 6, 31);
                  }
                  // Setting new date to 12/31/endDateYEAR
                  return new Date(endDate.getFullYear(), 11, 31);
                };

                // Setting the contract effective date.
                const contractEffectiveDate =
                  program === incentiveProgram.ez.code ||
                  program === incentiveProgram.qj.code
                    ? new Date(application.projectDetails.projectStartDate)
                    : program === incentiveProgram.ite.code
                    ? getITEEffectiveDate(
                        new Date(application.projectDetails.projectEndDate),
                        projectFormWrapper.projectInfo.location.parish
                      )
                    : new Date();

                // Finding index of the contract effective date.
                const contractEffectiveDateIndex = projectDates.findIndex(
                  pd => pd.type === dateTypes.contractEffectiveDate.name
                );
                // if found then set the new date.
                if (contractEffectiveDateIndex >= 0) {
                  projectDates[
                    contractEffectiveDateIndex
                  ].date = contractEffectiveDate;
                } else {
                  const newDate = new ProjectDate();
                  newDate.type = dateTypes.contractEffectiveDate.name;
                  newDate.category = dateTypes.contractEffectiveDate.category;
                  newDate.formId = new FormIdentifier();
                  newDate.formId.projectGuid = projectGuid;
                  newDate.created = new Date();
                  newDate.date = contractEffectiveDate;
                  projectDates.push(newDate);
                }
                //#endregion

                //#region Contract Expiration Date
                // Setting the contract expiration date.
                const yearsPenalized =
                  application.numberOfYearsPenalized > 0
                    ? Number(application.numberOfYearsPenalized)
                    : 0;
                const contractExpirationDate =
                  program === incentiveProgram.ez.code ||
                  program === incentiveProgram.qj.code
                    ? addDays(addYears(contractEffectiveDate, 5), -1)
                    : program === incentiveProgram.ite.code
                    ? addYears(contractEffectiveDate, 5 - yearsPenalized)
                    : new Date();
                // Find index of the contract expiration date in the project dates.
                const contractExpirationIndex = projectDates.findIndex(
                  pd => pd.type === dateTypes.contractExpirationDate.name
                );
                // Set the date if found.
                if (contractExpirationIndex >= 0) {
                  projectDates[
                    contractExpirationIndex
                  ].date = contractExpirationDate;
                } else {
                  const newDate = new ProjectDate();
                  newDate.type = dateTypes.contractExpirationDate.name;
                  newDate.category = dateTypes.contractExpirationDate.category;
                  newDate.formId = new FormIdentifier();
                  newDate.formId.projectGuid = projectGuid;
                  newDate.created = new Date();
                  newDate.date = contractExpirationDate;
                  projectDates.push(newDate);
                }
                //#endregion
                that.updateProjectDates(projectDates, projectGuid).subscribe();
              });
            }
          }
          //#endregion

          //#region Renewal Application Board Approved Handler
          if (
            projectFormWrapper.form.type === formTypes.renewalApplication.abbrev
          ) {
            const renewalApplication = projectFormWrapper.form as Renewal;
            const program = projectFormWrapper.projectInfo.incentiveProgram;
            const legislation = projectFormWrapper.projectInfo.legislation;
            const projectGuid = projectFormWrapper.projectGuid;
            // Proceed for changing renewal effective and expiration dates only if it falls in the following programs
            if (
              [
                incentiveProgram.qj.code,
                incentiveProgram.ite.code,
                incentiveProgram.rta.code
              ].includes(program)
            ) {
              // Get all the project dates.
              that.getProjectDates(projectGuid).subscribe(projectDates => {
                //#region  Renewal Contract Effective Date

                // Handler to determine Renewal Effective Date for RTA program
                const getRTArenewalContractDate = (
                  expirationDate: Date,
                  parish: string
                ): Date => {
                  if (parish === 'Orleans') {
                    // Setting new Date to 08/01/expirationYear
                    return new Date(expirationDate.getFullYear(), 7, 1);
                  }
                  // Setting new Date to 01/01/expirationYear
                  return new Date(expirationDate.getFullYear(), 0, 1);
                };

                // Get Actual Contract Expiration Date.
                const actualContractExpirationDate = projectDates.find(
                  pd => pd.type === dateTypes.contractExpirationDate.name
                ).date;
                // Determine the Renewal Contract Effective Date
                const renewalContractEffectiveDate =
                  program === incentiveProgram.qj.code
                    ? addDays(new Date(actualContractExpirationDate), 1)
                    : program === incentiveProgram.ite.code
                    ? new Date(actualContractExpirationDate)
                    : program === incentiveProgram.rta.code
                    ? getRTArenewalContractDate(
                        new Date(actualContractExpirationDate),
                        projectFormWrapper.projectInfo.location.parish
                      )
                    : new Date();
                // Get the index.
                const renewalContractEffectiveDateIndex = projectDates.findIndex(
                  pd => pd.type === dateTypes.renewalEffectiveDate.name
                );
                // If exists then set the date.
                if (renewalContractEffectiveDateIndex >= 0) {
                  projectDates[
                    renewalContractEffectiveDateIndex
                  ].date = renewalContractEffectiveDate;
                } else {
                  const newDate = new ProjectDate();
                  newDate.type = dateTypes.renewalEffectiveDate.name;
                  newDate.category = dateTypes.renewalEffectiveDate.category;
                  newDate.formId = new FormIdentifier();
                  newDate.formId.projectGuid = projectGuid;
                  newDate.created = new Date();
                  newDate.date = renewalContractEffectiveDate;
                  projectDates.push(newDate);
                }
                //#endregion

                //#region Renewal Contract Expiration Date

                // Handler to determine Renewal Expiration Date for RTA program
                const getRTArenewalExpirationDate = (
                  effectiveDate: Date,
                  parish: string
                ): Date => {
                  if (parish === 'Orleans') {
                    // Setting new Date to 07/31/expirationYear + 5
                    return addYears(
                      new Date(effectiveDate.getFullYear(), 6, 31),
                      5
                    );
                  }
                  // Setting new Date to 12/31/expirationYear + 5
                  return addYears(
                    new Date(effectiveDate.getFullYear(), 11, 31),
                    5
                  );
                };
                // Setting the Renewal Expiration Date
                const yearsPenalized =
                  renewalApplication.numberOfYearsPenalized > 0
                    ? Number(renewalApplication.numberOfYearsPenalized)
                    : 0;
                const renewalExpirationDate =
                  program === incentiveProgram.qj.code
                    ? addDays(addYears(renewalContractEffectiveDate, 5), -1)
                    : program === incentiveProgram.ite.code &&
                      [
                        legislationRuleNames.ite.PreExeOrd.name,
                        legislationRuleNames.ite.postExeOrd_2018.name
                      ].includes(legislation)
                    ? addYears(renewalContractEffectiveDate, 5 - yearsPenalized)
                    : program === incentiveProgram.ite.code &&
                      [legislationRuleNames.ite.postExeOrd_2017.name].includes(
                        legislation
                      )
                    ? addYears(renewalContractEffectiveDate, 3 - yearsPenalized)
                    : program === incentiveProgram.rta.code
                    ? getRTArenewalExpirationDate(
                        new Date(renewalContractEffectiveDate),
                        projectFormWrapper.projectInfo.location.parish
                      )
                    : new Date();
                // Find index of renewal contract expiration date.
                const renewalContractExpirationIndex = projectDates.findIndex(
                  pd => pd.type === dateTypes.renewalExpirationDate.name
                );
                // If exists then set the date.
                if (renewalContractExpirationIndex >= 0) {
                  projectDates[
                    renewalContractExpirationIndex
                  ].date = renewalExpirationDate;
                } else {
                  const newDate = new ProjectDate();
                  newDate.type = dateTypes.renewalExpirationDate.name;
                  newDate.category = dateTypes.renewalExpirationDate.category;
                  newDate.formId = new FormIdentifier();
                  newDate.formId.projectGuid = projectGuid;
                  newDate.created = new Date();
                  newDate.date = renewalExpirationDate;
                  projectDates.push(newDate);
                }
                //#endregion
                that.updateProjectDates(projectDates, projectGuid).subscribe();
              });
            }
          }
          //#endregion
        });
      }
    );

    //#endregion
  }

  appealForm(
    appealBoardMeetingId,
    projectGuid,
    formType,
    formIndex,
    appealComments
  ) {
    const url = this.postAppealFormUrl
      .replace('{appealBoardMeetingId}', appealBoardMeetingId)
      .replace('{projectGuid}', projectGuid)
      .replace('{formType}', formType)
      .replace(
        '{formIndex?}',
        oneToManyFormTypes.includes(formType) ? `/${formIndex}` : ''
      )
      .replace('{appealComments}', encodeURIComponent(appealComments));

    return this.http.post(
      url,
      null,
      this.authService.getAuthOptions('application/json')
    );
  }

  canUserAccessProject(
    userEmailAddress: string,
    projectGuid: string
  ): Observable<boolean> {
    const url = this.canUserAccessProjectUrl
      .replace('{email}', userEmailAddress)
      .replace('{projectGuid}', projectGuid);
    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(map((res: boolean) => res));
  }

  createProject(project: Project): Observable<Project> {
    const hybridProject = this.getHybridProject(project);
    return this.http
      .post(
        this.createProjectUrl,
        hybridProject,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((res: Project) => res))
      .pipe(catchError(this.handleError));
  }

  createNewProjectPhase(
    projectId: string,
    newPhase: string
  ): Observable<Project> {
    // Replace instances of projectId & phase
    const url = this.createNewProjectPhaseUrl
      .replace('{projectId}', projectId)
      .replace('{phase}', newPhase);

    return this.http
      .post(
        url,
        {},
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((res: Project) => res))
      .pipe(catchError(this.handleError));
  }

  getAllResolvedPaymentsForDatatablesCancellable(
    dataFilters: any,
    callback: any
  ) {
    let timerObj: any;

    const that = this;

    return (
      new Observable<any>(
        // This is the function or lambda that is called when the .subscribed() function is executed.
        subscriber => {
          timerObj = setTimeout(() => {
            console.log('went to database');
            this.http
              .post(
                this.getAllPaymentsForDataTablesUrl,
                dataFilters,
                this.authService.getAuthOptions()
              )
              .subscribe(
                response => {
                  console.log('returned from database');
                  subscriber.next(response);
                  subscriber.complete();
                },
                error => subscriber.error(error)
              );
          }, that.cancellableDelay);

          return () => {
            clearTimeout(timerObj);
          };
        }
      )
        // The map function translates each response using provided lambda before sending data to subscription handler
        .pipe(
          map(response => this.extractDataTableResponse(response, callback))
        )
        .pipe(catchError(this.handleError))
    );
  }

  getProjectFormsForDatatables(dataFilters: any, callback: any) {
    return this.http
      .post(
        this.getManagementProjectsUrl,
        dataFilters,
        this.authService.getAuthOptions()
      )
      .pipe(
        map((response: DataTablesResponse) =>
          this.extractDataTableResponse(response, callback)
        )
      )
      .pipe(catchError(this.handleError));
  }

  getProjectFormsForDatatablesCancellable(dataFilters: any, callback: any) {
    // -------------------------------OVERVIEW-----------------------------------
    // This function delays calling the database in case another request happens.
    // This was done in an effort to minimize going to the database
    // everytime that the user typed in the search box or changed any search criteria quickly.
    // Prior to this, many requests were sent to the api
    // Create a timer object which will be used to cancel the http request to fetch the data.

    // This is the timer which can be cancelled if the user affects the request within the specified DELAY
    let timerObj: any;

    const that = this;

    // This function immediately returns an Observable. The observable is passed a callback which is datatables.net data handler to bind
    // the data to the table.
    return (
      new Observable<any>(
        // This is the function or lambda that is called when the .subscribed() function is executed.
        subscriber => {
          timerObj = setTimeout(() => {
            console.log('went to database');
            this.http
              .post(
                this.getManagementProjectsUrl,
                dataFilters,
                this.authService.getAuthOptions()
              )
              .subscribe(
                response => {
                  console.log('returned from database');
                  // This when the subscription or subscriber is notified.
                  // Essentially, the a multiple values can be returned at different times, but
                  // this will only return one and then close.
                  subscriber.next(response);
                  subscriber.complete();
                },
                error => subscriber.error(error)
              );
          }, that.cancellableDelay);

          // This is the function that is executed when the .unsubscribed() function is executed. The instance of the subscription will
          // have the definition below. When unsubscribed() is called, the timeout will be cleared and therefore the lambda declared inside
          // will not be executed.
          return () => {
            clearTimeout(timerObj);
          };
        }
      )
        // The map function translates each response using provided lambda before sending data to subscription handler
        .pipe(
          map(response => this.extractDataTableResponse(response, callback))
        )
        .pipe(catchError(this.handleError))
    );
  }

  getExternalReviewFormsForDatatables(dataFilters: any, callback: any) {
    return this.http
      .post(
        this.getExternalReviewFormsUrl,
        dataFilters,
        this.authService.getAuthOptions()
      )
      .pipe(
        map((response: DataTablesResponse) =>
          this.extractDataTableResponse(response, callback)
        )
      )
      .pipe(catchError(this.handleError));
  }

  getExternalReviewFlatFormsForDatatables(dataFilters: any, callback: any) {
    return this.http
      .post(
        this.getExternalReviewFlatFormsUrl,
        dataFilters,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(
        map((response: DataTablesResponse) =>
          this.extractDataTableResponse(response, callback)
        )
      )
      .pipe(catchError(this.handleError));
  }

  getBoardMeetingDate(meetingId: string): Observable<Date> {
    return this.http
      .get(
        `${this.getBoardMeetingDateUrl}/${meetingId}`,
        this.authService.getAuthOptions()
      )
      .pipe(map((response: Date) => new Date(response)))
      .pipe(catchError(this.handleError));
  }

  getLegislatorsForForm(formId: FormIdentifier): Observable<Legislator> {
    let url =
      `${this.getLegislatorsForFormUrl}` +
      `?projectGuid=${encodeURIComponent(formId.projectGuid)}` +
      `&formType=${encodeURIComponent(formId.formType)}`;

    if (formId.formIndex !== undefined && formId.formIndex != null) {
      url += `&formIndex=${encodeURIComponent(formId.formIndex.toString())}`;
    }

    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(
        map((response: any) => {
          if (response) {
            return new Legislator(response);
          } else {
            return <Legislator>{};
          }
        })
      )
      .pipe(catchError(this.handleError));
  }

  getSignatureDocuments(id: string): Observable<any[]> {
    return this.http
      .get(
        this.getSignatureDocumentsForProjectUrl + id,
        this.authService.getAuthOptions()
      )
      .pipe(map((response: any[]) => response))
      .pipe(catchError(this.handleError));
  }

  getGovernorSignatureDocuments(id: string): Observable<any[]> {
    return this.http
      .get(
        this.getSignatureDocumentsForProjectUrl + id,
        this.authService.getAuthOptions()
      )
      .pipe(map((documents: any[]) => {
        const documentResponse = [];
        documents.forEach(element => {
          documentResponse.push({
            documentType:
            element.agreement.completedAgreementAttachment.documentType,
            documentName:
            element.agreement.completedAgreementAttachment.documentName,
            sentDate: new Date(element.agreement.sent),
            downloadLink:
            element.agreement.completedAgreementAttachment.fileLink,
            status: element.agreement.statuses[0].status,
            statuses: element.agreement.statuses
          });
        });
        const documentsSignedByGoverner = documentResponse.filter(x => (
            x.statuses &&
            x.statuses.length > 1 &&
            x.statuses.filter(y => y.status === 'Executed').length > 0 &&
            x.statuses.filter(y => y.status === 'Sent to Governor').length > 0
          )
        );
        return documentsSignedByGoverner;
      }))
      .pipe(catchError(this.handleError));
  }

  getManagementForms(): Observable<ProjectFormWrapper[]> {
    return this.http
      .get(this.getManagementProjectsUrl, this.authService.getAuthOptions())
      .pipe(map((resp: ProjectFormWrapper[]) => resp))
      .pipe(catchError(this.handleError));
  }

  getPayments(
    payments: Array<string>,
    projectId: string,
    feeType: string
  ): Observable<Payment[]> {
    return this.http
      .get(
        this.getPaymentsUrl +
          payments +
          '&projectId=' +
          projectId +
          '&feeType=' +
          feeType,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: Payment[]) => resp))
      .pipe(catchError(this.handleError));
  }

  getProject(id: string): Observable<Project> {
    return this.http
      .get(this.getProjectUrl + id, this.authService.getAuthOptions())
      .pipe(map((resp: Project) => resp))
      .pipe(catchError(this.handleError));
  }

  getProjectFromID(id: string): Observable<Project> {
    return this.http
      .get(this.getProjectFromIdUrl + id, this.authService.getAuthOptions())
      .pipe(map((resp: Project) => resp))
      .pipe(catchError(this.handleError));
  }

  // TODO: remove year as a parameter from all angular implementations. The server is now overwriting the value to ensure
  // consistent timing.
  getProjectId(): Observable<number> {
    return this.http
      .get(this.getProjectIdUrl, this.authService.getAuthOptions())
      .pipe(map((resp: number) => resp))
      .pipe(catchError(this.handleError));
  }

  getProjects(): Observable<ProjectList[]> {
    return this.http
      .get(this.getProjectsUrl, this.authService.getAuthOptions())
      .pipe(map((resp: ProjectList[]) => resp))
      .pipe(catchError(this.handleError));
  }

  getProjectsForDataTables(dataFilters: any, callback: any) {
    return this.http
      .post(
        this.getProjectsForDataTablesUrl,
        dataFilters,
        this.authService.getAuthOptions()
      )
      .pipe(
        map((response: DataTablesResponse) =>
          this.extractDataTableResponse(response, callback)
        )
      )
      .pipe(catchError(this.handleError));
  }

  //
  getInitialCertsCancellable(dataFilters: any, callback: any) {
    const that = this;
    let timerObj: any;
    return new Observable<any>(observer => {
      timerObj = setTimeout(() => {
        console.log('went to database');
        console.log(dataFilters);

        this.http.post(that.getInitialCertsUrl, dataFilters).subscribe(
          response => {
            console.log('returned from database');
            observer.next(response);
            observer.complete();
          },
          error => observer.error(error)
        );
      }, that.cancellableDelay);

      return () => {
        clearTimeout(timerObj);
      };
    })
      .pipe(map(response => this.extractDataTableResponse(response, callback)))
      .pipe(
        catchError(error => {
          that.swalService.error({
            title: 'Error loading results'
          });
          return this.handleError(error);
        })
      );
  }

  getDashboardForms(dataFilters: any) {
    const that = this;
    return this.http
      .post(that.getFormDataUrl, dataFilters, this.authService.getAuthOptions())
      .pipe(
        map(response => {
          return response as ProjectFormWrapper[];
        })
      )
      .pipe(
        catchError(error => {
          that.swalService.error({
            title: 'Error loading results'
          });
          return this.handleError(error);
        })
      );
  }

  getCreditsForProjects(ids: string[], status: string) {
    const that = this;
    const url = that.getCreditsForProjectsUrl + `?status=${status}`;
    return this.http
      .post(url, ids, this.authService.getAuthOptions())
      .pipe(
        map(response => {
          return response as CreditType[];
        })
      )
      .pipe(
        catchError(error => {
          that.swalService.error({
            title: 'Error loading results'
          });
          return this.handleError(error);
        })
      );
  }

  getPublicReportsForDataTablesCancellable(dataFilters: any, callback: any) {
    const that = this;
    let timerObj: any;
    return new Observable<any>(observer => {
      timerObj = setTimeout(() => {
        console.log('went to database');
        console.log(dataFilters);

        this.http
          .post(
            this.getPublicReportsForDataTablesUrl,
            dataFilters,
            this.authService.getAuthOptions()
          )
          .subscribe(
            response => {
              console.log('returned from database');
              observer.next(response);
              observer.complete();
            },
            error => observer.error(error)
          );
      }, that.cancellableDelay);

      return () => {
        clearTimeout(timerObj);
      };
    })
      .pipe(map(response => this.extractDataTableResponse(response, callback)))
      .pipe(
        catchError(error => {
          that.swalService.error({
            title: 'Error loading results'
          });
          return this.handleError(error);
        })
      );
  }

  getProjectsForDataTablesCancellable(dataFilters: any, callback: any) {
    const that = this;
    let timerObj: any;
    return new Observable<any>(observer => {
      timerObj = setTimeout(() => {
        console.log('went to database');
        console.log(dataFilters);
        this.http
          .post(
            this.getProjectsForDataTablesUrl,
            dataFilters,
            this.authService.getAuthOptions()
          )
          .subscribe(
            response => {
              console.log('returned from database');
              console.log(response);
              observer.next(response);
              observer.complete();
            },
            error => observer.error(error)
          );
      }, that.cancellableDelay);

      return () => {
        clearTimeout(timerObj);
      };
    })
      .pipe(
        subscribeOn(asyncScheduler),
        map(response => this.extractDataTableResponse(response, callback))
      )
      .pipe(
        catchError(error => {
          that.swalService.error({
            title: 'Error Loading Results'
          });

          return this.handleError(error);
        })
      );
  }

  getAssessorProjectsForDataTablesCancellable(dataFilters: any, callback: any) {
    const that = this;
    let timerObj: any;
    return new Observable<any>(observer => {
      timerObj = setTimeout(() => {
        console.log('went to database');
        console.log(dataFilters);
        this.http
          .post(
            this.getAssessorProjectsForDataTablesUrl,
            dataFilters,
            this.authService.getAuthOptions()
          )
          .subscribe(
            response => {
              console.log('returned from database');
              observer.next(response);
              observer.complete();
            },
            error => observer.error(error)
          );
      }, that.cancellableDelay);

      return () => {
        clearTimeout(timerObj);
      };
    })
      .pipe(map(response => this.extractDataTableResponse(response, callback)))
      .pipe(catchError(this.handleError));
  }

  getProjectsRange(start: number, length: number): Observable<ProjectList[]> {
    return this.http
      .get(
        this.getProjectsUrl + '/' + start + '/' + length,
        this.authService.getAuthOptions()
      )
      .pipe(map((resp: ProjectList[]) => resp))
      .pipe(catchError(this.handleError));
  }

  getProjectsCount(): Observable<number> {
    return this.http
      .get(this.getProjectsCountUrl, this.authService.getAuthOptions())
      .pipe(map((resp: number) => resp))
      .pipe(catchError(this.handleError));
  }

  getEntApplicationCount(program: string, year: number): Observable<number> {
    return this.http
      .get(
        this.getEntApplicationsCountUrl + program + '&year=' + year,
        this.authService.getAuthOptions()
      )
      .pipe(map((resp: number) => resp))
      .pipe(catchError(this.handleError));
  }

  getProjectInfosForBaseProjectId(projectId: string): Observable<Project[]> {
    // Replace instances of projectId
    const url = this.getProjectInfosForBaseProjectUrl.replace(
      '{projectId}',
      projectId
    );

    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(map((resp: Project[]) => resp))
      .pipe(catchError(this.handleError));
  }

  /**
   * The projects returned by the API are going to be in a minified format.
   * Only the ProjectInfo and ProjectStatusLog
   *
   * @param {number} year Project year
   * @param {number} counter Project counter
   * @param {string} program Project incentive program
   * @param {string} phase Project phase
   * @returns {Observable<Project[]>} Calls the API for a list of related projects
   * @memberof ProjectDataService
   */
  getRelatedProjects(
    year: number,
    counter: number,
    program: string,
    guid: string
  ): Observable<ProjectBase[]> {
    return this.http
      .get(
        this.getRelatedProjectsUrl +
          year +
          '&counter=' +
          counter +
          '&program=' +
          program +
          '&guid=' +
          guid,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(
        map((response: ProjectBase[]) => {
          if (response) {
            const projects: ProjectBase[] = response;
            projects.forEach(proj =>
              proj.projectStatusLog.forEach(
                log => (log.statusDate = new Date(log.statusDate))
              )
            );
            return projects;
          } else {
            return [];
          }
        })
      )
      .pipe(catchError(this.handleError));
  }

  cocReqAdvance(id: string, baseProjectId: string): Observable<boolean> {
    return this.http
      .get(this.cocReqAdvanceUrl + `?phaseId=${id}&parentId=${baseProjectId}` , this.authService.getAuthOptions())
      .pipe(
        subscribeOn(asyncScheduler),
        map((resp: boolean) => resp)
      )
      .pipe(catchError(this.handleError));
  }

  resolveProject(id: string): Observable<ResolvedProject> {
    return this.http
      .get(this.resolveProjectsUrl + id, this.authService.getAuthOptions())
      .pipe(
        subscribeOn(asyncScheduler),
        map(this.extractResolvedProject)
      )
      .pipe(catchError(this.handleError));
  }

  resolveProjectInfo(id: string): Observable<ProjectInfo> {
    return this.http
      .get(this.resolveProjectInfoUrl + id, this.authService.getAuthOptions())
      .pipe(map(this.extractResolvedProjectInfo))
      .pipe(catchError(this.handleError));
  }

  updateCredits(
    credits: CreditType[],
    guid: string,
    incentiveProgram: string
  ): Observable<CreditType[]> {
    const url = this.updateCreditsUrl.replace('{guid}', guid);
    const hybridCredits = this.getHybridCredits(credits, incentiveProgram);
    return this.http
      .put(
        url,
        hybridCredits,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((resp: CreditType[]) => resp))
      .pipe(catchError(this.handleError));
  }

  updateCreditStatus(
    formId: FormIdentifier,
    status: string,
    sourceFormIndex: number
  ): Observable<any> {
    const url = this.updateCreditStatusUrl
      .replace('{status}', status)
      .replace('{sourceIndex}', sourceFormIndex.toString());
    return this.http
      .put(
        url,
        formId,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((resp: any) => resp))
      .pipe(catchError(this.handleError));
  }

  updateProjectStatus(formId: FormIdentifier, status: string): Observable<any> {
    const url = this.updateProjectStatusUrl.replace('{status}', status);
    return this.http
      .put(
        url,
        formId,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((resp: any) => resp))
      .pipe(catchError(this.handleError));
  }

  addOutgoingPayment(
    outgoingPayment: OutgoingPayment
  ): Observable<OutgoingPayment> {
    return this.http
      .post(
        this.createOutgoingPaymentUrl,
        outgoingPayment,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((resp: OutgoingPayment) => resp))
      .pipe(catchError(this.handleError));
  }

  updateOutgoingPayment(
    outgoingPayment: OutgoingPayment
  ): Observable<OutgoingPayment> {
    return this.http
      .put(
        this.updateOutgoingPaymentUrl,
        outgoingPayment,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((resp: OutgoingPayment) => resp))
      .pipe(catchError(this.handleError));
  }

  getOutgoingPayment(formId: FormIdentifier): Observable<OutgoingPayment> {
    return this.http
      .post(
        this.getOutgoingPaymentUrl,
        formId,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((resp: OutgoingPayment) => resp))
      .pipe(catchError(this.handleError));
  }

  updateProject(project: ProjectBase): Observable<Project> {
    const that = this;

    // Set the appropriate project in the hybrid project object.
    // This helps api to strongly typed object which consists of either
    // Bi project, Film project or Digital project.
    const hybridProject = this.getHybridProject(project);
    return this.http
      .put(
        this.updateProjectUrl,
        hybridProject,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(
        map((response: Project) => {
          const jsonResponse = response;
          that.projectShareService.setProjectHash(jsonResponse.projectHash);
          return jsonResponse;
        })
      )
      .pipe(catchError(this.handleError));
  }
  getHybridProject(project: ProjectBase): HybridProject {
    const currentIP = project.projectInfo.incentiveProgram;
    const category = incentiveProgram[currentIP.toLowerCase()].category;
    const hybridProject = new HybridProject({
      biProject:
        category === incentiveCategory.business.abbrev ? project : null,
      filmProject: currentIP === incentiveProgram.film.code ? project : null,
      digitalProject: currentIP === incentiveProgram.dm.code ? project : null,
      stepProject: currentIP === incentiveProgram.step.code ? project : null
    });
    return hybridProject;
  }

  getHybridCredits(credits: CreditType[], currentIP: string): HybridCredits {
    const hybridCredits = new HybridCredits({
      filmCredits: currentIP === incentiveProgram.film.code ? credits : null,
      digitalCredits: currentIP === incentiveProgram.dm.code ? credits : null
    });
    return hybridCredits;
  }
  refreshProjectSnapshots(id: string): Observable<boolean> {
    return this.http
      .get(
        this.refreshProjectSnapshotsUrl + '/' + id,
        this.authService.getAuthOptions('application/json', 'gzip')
      )
      .pipe(map((resp: boolean) => resp))
      .pipe(catchError(this.handleError));
  }

  createFormSnapshot(formId: FormIdentifier): Observable<boolean> {
    let url = `${this.createFormSnapshotUrl}/${formId.projectGuid}/${formId.formType}`;

    if (formId.formIndex) {
      url += `/${formId.formIndex}`;
    }

    return this.http
      .post(url, null, this.authService.getAuthOptions('application/json'))
      .pipe(map((resp: boolean) => resp))
      .pipe(catchError(this.handleError));
  }

  getProjectGuidAndInfo(projectId: string): Observable<ProjectBase> {
    return this.http
      .get(
        this.getProjectFromProjectIdUrl + projectId,
        this.authService.getAuthOptions()
      )
      .pipe(map((resp: ProjectBase) => resp))
      .pipe(catchError(this.handleError));
  }

  insertProjectAccessRequest(
    projectAccessRequest: ProjectAccessRequest
  ): Observable<ProjectAccessRequest> {
    return this.http
      .post(
        this.insertProjectAccessRequestUrl,
        projectAccessRequest,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: ProjectAccessRequest) => resp))
      .pipe(catchError(this.handleErrorForAccessRequest));
  }

  updateProjectAccessRequest(
    projectGuid: string,
    status: StatusLog
  ): Observable<ProjectAccessRequest> {
    return this.http
      .post(
        this.updateProjectAccessRequestUrl + projectGuid,
        status,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: ProjectAccessRequest) => resp))
      .pipe(catchError(this.handleError));
  }

  getProjectAccessRequestForDataTablesCancellable(
    dataFilters: any,
    callback: any
  ) {
    const that = this;
    let timerObj: any;
    return new Observable<any>(observer => {
      timerObj = setTimeout(() => {
        this.http
          .post(
            this.getProjectAccessRequestForDataTableUrl,
            dataFilters,
            this.authService.getAuthOptions()
          )
          .subscribe(
            response => {
              observer.next(response);
              observer.complete();
            },
            error => observer.error(error)
          );
      }, that.cancellableDelay);

      return () => {
        clearTimeout(timerObj);
      };
    })
      .pipe(map(response => this.extractDataTableResponse(response, callback)))
      .pipe(catchError(this.handleError));
  }

  createPayment(payment: Payment): Observable<Payment> {
    return this.http
      .post(
        this.createPaymentUrl,
        payment,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: Payment) => resp))
      .pipe(catchError(this.handleError));
  }

  simulateSuccessfulPayment(payment: Payment): Observable<Payment> {
    return this.http
      .put(
        this.simulateSuccessfulPaymentUrl,
        payment,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: Payment) => resp))
      .pipe(catchError(this.handleError));
  }

  updatePayment(payments: Payment[]): Observable<Payment> {
    return this.http
      .put(
        this.updatePaymentsUrl,
        payments,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: Payment) => resp))
      .pipe(catchError(this.handleError));
  }
  updateFormDeclinedPaymentFees(formId: FormIdentifier, amountPaid: number) {
    return this.http
      .post(
        this.updateFormDeclinedPaymentFeesUrl + '/' + amountPaid,
        formId,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: any) => resp))
      .pipe(catchError(this.handleError));
  }
  updateProjectForms(projectGuids: string[]) {
    return this.http
      .post(
        this.updateProjectFormsUrl,
        projectGuids,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: any) => resp))
      .pipe(catchError(this.handleError));
  }

  registerConfirmation(payment: Payment): Observable<ResolvedProject> {
    return this.http
      .put(
        this.registerConfirmationUrl,
        payment,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: ResolvedProject) => resp))
      .pipe(catchError(this.handleError));
  }

  getUnclassifiedPaymentsCancellable(dataFilters: any, callback: any) {
    let timerObj: any;

    const that = this;

    return (
      new Observable<any>(
        // This is the function or lambda that is called when the .subscribed() function is executed.
        subscriber => {
          timerObj = setTimeout(() => {
            console.log('went to database');
            this.http
              .post(
                this.getUnclassifiedPaymentsUrl,
                dataFilters,
                this.authService.getAuthOptions()
              )
              .subscribe(
                response => {
                  console.log('returned from database');
                  subscriber.next(response);
                  subscriber.complete();
                },
                error => subscriber.error(error)
              );
          }, that.cancellableDelay);

          return () => {
            clearTimeout(timerObj);
          };
        }
      )
        // The map function translates each response using provided lambda before sending data to subscription handler
        .pipe(
          map(response => this.extractDataTableResponse(response, callback))
        )
        .pipe(catchError(this.handleError))
    );
  }

  getOrbiPayConfirmation(paymentRequest: OrbiPayPaymentRequest): Observable<any> {
    return this.http
      .post(
        this.getPaymentConfirmationOrbiPayUrl,
        paymentRequest,
        this.authService.getAuthOptions('application/json')
      )
       .pipe(catchError(this.handleError));
  }

  //#region ProjectDates

  getProjectDates(id: string): Observable<ProjectDate[]> {
    const url = this.getProjectDatesUrl.replace('{id}', id);
    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(
        map((resp: ProjectDate[]) =>
          resp ? (resp as any[]).map(date => new ProjectDate(date)) : []
        )
      )
      .pipe(catchError(this.handleError));
  }

  createProjectDate(projectDate: ProjectDate): Observable<string> {
    return this.http
      .post(
        this.createProjectDateUrl,
        projectDate,
        Object.assign(
          { responseType: 'text' },
          this.authService.getAuthOptions('application/json')
        )
      )
      .pipe(map((resp: string) => resp))
      .pipe(catchError(this.handleError));
  }

  updateProjectDate(projectDate: ProjectDate): Observable<number> {
    return this.http
      .put(
        this.updateProjectDateUrl,
        projectDate,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: number) => resp))
      .pipe(catchError(this.handleError));
  }

  updateProjectDates(
    projectDates: ProjectDate[],
    id: string
  ): Observable<number> {
    const url = this.updateProjectDatesUrl.replace('{guid}', id);
    return this.http
      .put(
        url,
        projectDates,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map((resp: number) => resp))
      .pipe(catchError(this.handleError));
  }

  deleteProjectDate(id: string): Observable<number> {
    const url = this.deleteProjectDateUrl.replace('{id}', id);
    return this.http
      .delete(url, this.authService.getAuthOptions())
      .pipe(map((resp: number) => resp))
      .pipe(catchError(this.handleError));
  }

  //#endregion

  private extractAccessRequestProject(res: Response) {
    return res.text() ? res.json() : null;
  }

  private extractDataTableResponse(res: DataTablesResponse, callback: any) {
    if (res) {
      const jsonResponse = res;
      if (jsonResponse.data !== undefined && jsonResponse.data.length > 0) {
        let projectForms: DataTablesResponse;

        projectForms = Object.assign(
          <DataTablesResponse>{
            draw: 0,
            data: [],
            recordsTotal: 0,
            recordsFiltered: 0
          },
          jsonResponse
        );

        callback(projectForms);
      } else {
        callback(<DataTablesResponse>{
          draw: 0,
          data: [],
          recordsTotal: 0,
          recordsFiltered: 0
        });
      }
    }
  }

  private extractResolvedProject(res: ResolvedProject): ResolvedProject {
    if (res) {
      const jsonResponse = res;
      if (
        jsonResponse.project !== undefined &&
        jsonResponse.payments !== undefined &&
        jsonResponse.credits !== undefined &&
        jsonResponse.projectDates !== undefined
      ) {
        const project = Object.assign(new ProjectBase(), jsonResponse.project);
        project.init();
        const response = {
          project: project,
          payments: jsonResponse.payments,
          credits: jsonResponse.credits,
          projectDates: jsonResponse.projectDates
        };
        return response;
      } else {
        return <ResolvedProject>{};
      }
    } else {
      return <ResolvedProject>{};
    }
  }

  private extractResolvedProjectInfo(res: ProjectInfo): ProjectInfo {
    if (res) {
      const jsonResponse = res;
      const projectInfo = Object.assign(new ProjectInfo(), jsonResponse);
      return projectInfo;
    } else {
      return new ProjectInfo();
    }
  }

  private handleError(error: HttpErrorResponse | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.log('Fantastic, it does not work!!');
    console.error(errMsg);
    if (error.status === httpResponseCodes.dataConflict) {
      return throwError(error.status);
    } else {
      return throwError(errMsg);
    }
  }

  private handleErrorForAccessRequest(error: Response | any) {
    let errMsg: string;
    errMsg = error._body ? error._body : error.error.toString();
    return throwError(errMsg);
  }
}

import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { Router, Params } from '@angular/router';
import { AuthenticationService } from '../../security/services/authentication.service';
import { EmailParameters } from '../models/email-parameters.model';
import { EmailTemplate } from '../email.enums';
import { environment } from '../../../environments/environment';
import { MailAddress } from '../models/mail-address.model';
import { Project } from '../../project/models/project.model';
import * as _ from 'underscore';
import { Observable, Subscription } from 'rxjs';
import { FormDetails } from '../models/form-details.model';
import { BoardMeetingDetails } from '../models/board-meeting-details.model';
import { BoardSpecificFormDecisionDetails } from '../models/board-specific-form-decision-details.model';
import { User } from '../../user/models/user.model';
import { ManagerDetails } from '../models/manager-details.model';
import { Address } from '../../shared/models/address.model';
import { convertUrlTreeToString } from '../../shared/shared.functions';
import {
  soloFormTypes,
  externalReviewAgencies,
  externalReviewStatuses,
  auditDepositTransactionActions
} from '../../project/project.constants';
import { formTypes, formStatuses } from '../../form/form.constants';
import { FormIdentifier } from '../../project/models/form-identifier.model';
import { getFormTypeName } from '../../form/form.functions';
import { NavService } from '../../fastlane-common/services/nav.service';
import {
  oneToOneFormTypes,
  formCertificationStatus
} from '../../form/form.constants';
import { EmployeeCertificationReport } from '../../incentive/compliance/models/employee-certification-report.model';
import { ProjectFormWrapper } from '../../project/models/project-form-wrapper.model';
import { SwalService } from '../../fastlane-common/services/swal.service';
import { AnnualCertificationReport } from '../../incentive/compliance/models/annual-certification-report.model';
import { map, catchError } from 'rxjs/operators';
import { userType, UserType } from '../../user/user.constants';
import { events } from '../../fastlane-common/event/event.constants';
import {
  publicBoardApprovedReports,
  incentiveProgram
} from '../../project/project.constants';
import { EmailSendParameters } from '../models/email-send-parameters.model';
import { PublishSubscribeService } from '../../fastlane-common/services/publish-subscribe.service';
import { EventContext } from '../../fastlane-common/event/interfaces/event-context.interface';
import {
  eventHandlers,
  shouldSkipAllEmailNotifications,
  scheduleReminders
} from '../email.functions';
import { BoardMeeting } from '../../board/models/board-meeting.model';
import { FormStatusChange } from '../../fastlane-common/event/interfaces/form-status-event.interface';
import { ExternalReviewSend } from '../../fastlane-common/event/interfaces/external-review-send-event.interface';
import { ProjectInfo } from '../../project/models/project-info.model';
import { FormExternalReviewStatusChange } from '../../fastlane-common/event/interfaces/form-external-review-status-event.interface';
import { SweetAlertOptions } from 'sweetalert2';
import { ProjectAgreement } from '../../management/signatures/models/project-agreement.model';
import { Reminder } from '../interfaces/reminder.interface';
import { ScheduledEmail } from '../interfaces/scheduled-email.interface';
import { UserContextService } from '../../user/services/user-context.service';
import { ToastService } from '../../fastlane-common/services/toast.service';
import { UserDataService } from '../../user/services/user-data.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { formStatus } from '../../form/form.constants';
import { Audit as FilmAudit } from '../../entertainment/models/film/audit.model';
import { AuditInfo } from '../../entertainment/interfaces/audit-info.interface';
import { ProjectInfo as FilmProjectInfo } from '../../entertainment/models/film/project-info.model';
import { ProjectInfo as DigitalProjectInfo } from '../../entertainment/models/digital/project-info.model';
import { AuditChange } from '../../fastlane-common/event/interfaces/audit-change';
import { ProjectInfoBase } from '../../project/models/project-info-base.model';
import { generateLinksForUsers } from '../email.functions';
import { getUserModule } from '../../user/user.functions';
import { EntertainmentForm } from '../../entertainment/models/entertainment-form.model';
import { permissions } from '../../security/security.constants';
import { OutgoingPayment } from '../../fiscal/models/outgoing-payment.model';
import { ProjectDataService } from '../../project/services/project-data.service';
import { EntertainmentId } from '../../entertainment/interfaces/ent-id.interface';
declare var moment: any;

@Injectable()
export class EmailService implements OnDestroy {
  private readonly getPrimaryProgramManagerRecipientsUrl = `${
    environment.apiUrl
  }/email/primaryProgramManagerRecipients`;
  private readonly sendEmailUrl = environment.apiUrl + '/email/send';
  private readonly getCarbonCopyUsersWithEmailPreferencesUrl =
    environment.apiUrl + '/email/getCarbonCopyUsersWithEmailPreferences';
  private readonly resendUserVerificationEmailUrl =
    environment.apiUrl + '/email/resendUserVerificationEmail/';
  private readonly cancelScheduledEmailsUrl =
    environment.apiUrl + '/email/cancelScheduledEmails';
  private readonly scheduleEmailUrl = `${
    environment.apiUrl
  }/email/scheduleEmail`;

  private readonly scheduleRemindersUrl = `${
    environment.apiUrl
  }/email/scheduleReminders`;

  private programManagerRecipients: {
    incentiveProgram: string;
    mailAddress: MailAddress;
  }[] = [];

  private subs: Subscription[] = [];
  loginLink: string = window.location.origin + '/login';

  // Establishes the relationship between eventName, eventAction and method to execute
  // { [key: string]: <T>(emailSendParams: EmailSendParameters<T>) => {} }
  private eventNameActionEmailer = {
    [events.formStatusChanges.code +
    formStatuses.informationRequired.name]: this.sendFormStatusChangeEmail,
    [events.formStatusChanges.code + formStatuses.reviewComplete.name]: this
      .sendFormStatusChangeEmail,
    [events.formStatusChanges.code + formStatuses.onHold.name]: this
      .sendFormStatusChangeEmail,
    [events.formStatusChanges.code + formStatuses.cancelled.name]: this
      .sendFormStatusChangeEmail,
    [events.formStatusChanges.code + formStatuses.auditorAssigned.name]: this
      .sendFormStatusChangeEmail,
    [events.formCertificationChanges.code +
    formCertificationStatus.certified.display]: this.sendCertificationEmail
  };

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private location: Location,
    private navigator: NavService,
    private router: Router,
    private swal: SwalService,
    private toast: ToastService,
    private pubSubService: PublishSubscribeService,
    private userContextService: UserContextService,
    private userService: UserDataService,
    private projectDataService: ProjectDataService,
    private httpClient: HttpClient
  ) {
    this.setupPrimaryProgramManagerMailAddresses();

    const that = this;
    //#region handlers

    // Provide Generic Event Handler to Generic Event
    pubSubService.handleAllActionsForEvents<EventContext<FormStatusChange>>(
      [events.formStatusChanges.code],
      context => {
        // Do not send emails if requested by manager
        if ((!!context.data.doSendEmails) === false) {
          return;
        }

        // Schedule Reminders if applicable [ONLY FOR FILM ENTERTAINMENT PROJECTS]
        if (
          context.data.projectFormWrapper.projectInfo.incentiveProgram ===
          incentiveProgram.film.code
        ) {
          scheduleReminders(
            {
              formIndex: context.data.projectFormWrapper.form.formIndex,
              formType: context.data.projectFormWrapper.form.type,
              projectGuid: context.data.projectFormWrapper.projectGuid
            } as FormIdentifier,
            context.eventAction,
            that.scheduleReminders.bind(that),
            () => {
              that.toast.success({
                title: 'Successfully created reminders for the applicant'
              });
            },
            err => {
              that.toast.error({
                title:
                  'Error creating reminders for the applicant. Please ensure it contains the necessary dates.'
              });
            }
          );
        }

        // Only send emails if configuration allows it
        if (
          shouldSkipAllEmailNotifications(
            context.data.projectFormWrapper.projectInfo,
            context.data.projectFormWrapper.form.type,
            context.data.projectFormWrapper.form.statuses[0].status
          )
        ) {
          return;
        }

        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[context.eventType](
          context.data.projectFormWrapper.projectInfo,
          context.data.projectFormWrapper.form.type,
          context.eventAction
        );
        // Fetch the carbon copy users from api
        that
          .getCarbonCopyUsersWithEmailPreferences(
            carbonCopyUserInfos,
            context.data.projectFormWrapper.projectInfo.location.parish,
            context.eventType,
            context.eventAction,
            context.data.projectFormWrapper.projectInfo
          )
          .subscribe(carbonCopyUsers => {
            context.data.ccList = carbonCopyUsers;

            // Send specific event type action email
            that.sendFormStatusChangeEmail(context.data).subscribe(
              success => {
                that.swal.success({
                  title: 'Notification of Form Status Change',
                  text: 'An email was sent to notify project contacts'
                });
              },
              error => {
                that.swal.error({
                  title: 'Error Sending Email',
                  text:
                    'An error ocurred while sending the Form Status Change email. Please try again later.'
                });
              }
            );
          });
      }
    );

    //#region NonProject Contact Recipients

    // Form Status Change (AUDITOR ASSIGNED) - Recipient Auditor, cc project contacts & managers
    // This one should use a different template
    pubSubService.handle<EventContext<AuditChange>>(
      events.auditManagementChange.code,
      formStatus.auditorAssigned,
      context => {
        // Get the auditor to be recipient
        const auditInfo = context.data.auditForm;

        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[context.eventType](
          context.data.projectInfoBase,
          context.data.auditForm.type,
          context.eventAction
        );

        // Fetch the carbon copy users from api
        that
          .getCarbonCopyUsersWithEmailPreferences(
            carbonCopyUserInfos,
            context.data.projectInfoBase.location.parish,
            context.eventType,
            context.eventAction,
            context.data.projectInfoBase
          )
          .subscribe(carbonCopyUsers => {
            context.data.ccList = carbonCopyUsers;

            // Send specific event type action email
            that
              .sendAuditorAssignedEmail(
                context,
                that.navigator.convertUrlTreeToStringsPerUserType.bind(that)
              )
              .subscribe(
                success => {
                  that.swal.success({
                    title: 'Notification of Auditor Assignment',
                    text:
                      'An email was sent to notify project contacts and auditors'
                  });
                },
                error => {
                  that.swal.error({
                    title: 'Error Sending Email',
                    text:
                      // tslint:disable-next-line:max-line-length
                      'An error ocurred while sending the Auditor Assignment email. Please make sure a user is created for the auditing company.Please try again later.'
                  });
                }
              );
          });
      }
    );

    // Form Status Change (AWAITING AUDIT) - Recipient Auditor, cc project contacts & managers
    // This one should use a different template
    pubSubService.handle<EventContext<AuditChange>>(
      events.auditManagementChange.code,
      formStatus.awaitingAudit,
      context => {
        // Get the auditor to be recipient
        const auditInfo = context.data.auditForm;

        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[context.eventType](
          context.data.projectInfoBase,
          context.data.auditForm.type,
          context.eventAction
        );
        // Fetch the carbon copy users from api
        that
          .getCarbonCopyUsersWithEmailPreferences(
            carbonCopyUserInfos,
            context.data.projectInfoBase.location.parish,
            context.eventType,
            context.eventAction,
            context.data.projectInfoBase
          )
          .subscribe(carbonCopyUsers => {
            context.data.ccList = carbonCopyUsers;

            // Send specific event type action email
            that
              .sendAwaitingAuditEmail(
                context,
                that.navigator.convertUrlTreeToStringsPerUserType.bind(that)
              )
              .subscribe(
                success => {
                  that.swal.success({
                    title: 'Notification of Cost Report Upload',
                    text:
                      'An email was sent to notify project contacts, auditors, and managers'
                  });
                },
                error => {
                  that.swal.error({
                    title: 'Error Sending Email',
                    text:
                      'An error ocurred while sending the Auditor Assignment email. Please try again later.'
                  });
                }
              );
          });
      }
    );

    pubSubService.handle<EventContext<AuditChange>>(
      events.auditManagementChange.code,
      formStatus.awaitingExpenditureVerification,
      context => {
        // Get the auditor to be recipient
        const auditInfo = context.data.auditForm;

        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[context.eventType](
          context.data.projectInfoBase,
          context.data.auditForm.type,
          context.eventAction
        );
        // Fetch the carbon copy users from api
        that
          .getCarbonCopyUsersWithEmailPreferences(
            carbonCopyUserInfos,
            context.data.projectInfoBase.location.parish,
            context.eventType,
            context.eventAction,
            context.data.projectInfoBase
          )
          .subscribe(carbonCopyUsers => {
            context.data.ccList = carbonCopyUsers;

            // Send specific event type action email
            that
              .sendAwaitingAuditEmail(
                context,
                that.navigator.convertUrlTreeToStringsPerUserType.bind(that)
              )
              .subscribe(
                success => {
                  that.swal.success({
                    title: 'Notification of Cost Report Upload',
                    text:
                      'An email was sent to notify project contacts, auditors, and managers'
                  });
                },
                error => {
                  that.swal.error({
                    title: 'Error Sending Email',
                    text:
                      'An error ocurred while sending the Auditor Assignment email. Please try again later.'
                  });
                }
              );
          });
      }
    );

    // Form Status Change [AUDIT] (RECEIVED) - Recipient Auditor, cc project contacts & managers
    // This one should use a different template
    pubSubService.handle<EventContext<AuditChange>>(
      events.auditManagementChange.code,
      formStatus.received,
      context => {
        // Get the auditor to be recipient
        const auditInfo = context.data.auditForm;

        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[context.eventType](
          context.data.projectInfoBase,
          context.data.auditForm.type,
          context.eventAction
        );
        // Fetch the carbon copy users from api
        that
          .getCarbonCopyUsersWithEmailPreferences(
            carbonCopyUserInfos,
            context.data.projectInfoBase.location.parish,
            context.eventType,
            context.eventAction,
            context.data.projectInfoBase
          )
          .subscribe(carbonCopyUsers => {
            context.data.ccList = carbonCopyUsers;

            // Send specific event type action email
            that
              .sendAuditReceivedEmail(
                context,
                that.navigator.convertUrlTreeToStringsPerUserType.bind(that)
              )
              .subscribe(
                success => {
                  that.swal.success({
                    title: 'Notification of Audit Completion',
                    text:
                      'An email was sent to notify managers and auditors'
                  });
                },
                error => {
                  that.swal.error({
                    title: 'Error Sending Email',
                    text:
                      'An error ocurred while sending the Audit Completion email. Please try again later.'
                  });
                }
              );
          });
      }
    );

    //#endregion

    // Provide Handler for External Review / Objection
    pubSubService.handle<EventContext<FormExternalReviewStatusChange>>(
      events.externalReviewStatusChanges.code,
      externalReviewStatuses.objection.displayName,
      context => {
        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const projInfo = new ProjectInfo();
        projInfo.incentiveProgram =
          context.data.formExternalReviewSummary.incentiveProgram;

        const carbonCopyUserInfos = eventHandlers[context.eventType](
          projInfo,
          context.data.formExternalReviewSummary.formType,
          context.eventAction
        );

        // Fetch the carbon copy users from api
        that
          .getCarbonCopyUsersWithEmailPreferences(
            carbonCopyUserInfos,
            context.data.formExternalReviewSummary.parish,
            context.eventType,
            context.eventAction,
            <ProjectInfo>{
              incentiveProgram:
                context.data.formExternalReviewSummary.incentiveProgram
            }
          )
          .subscribe(carbonCopyUsers => {
            context.data.ccList = carbonCopyUsers;

            // Send specific event type action email
            that.sendExternalReviewObjectionEmail(context.data).subscribe(
              success => {
                that.swal.success(<SweetAlertOptions>{
                  title: `Email sent to the contacts of ${
                    context.data.formExternalReviewSummary.projectId
                  } & ${context.data.formExternalReviewSummary.formType}`,
                  useRejections: false
                });
              },
              error => {
                that.swal.error(<SweetAlertOptions>{
                  title: 'Oops!',
                  text:
                    'An error occurred while emailing stakeholders of this project.',
                  useRejections: false
                });
              }
            );
          });
      }
    );

    // Provide Handler for External Review / Objection Lifted
    pubSubService.handle<EventContext<FormExternalReviewStatusChange>>(
      events.externalReviewStatusChanges.code,
      externalReviewStatuses.objectionLifted.displayName,
      context => {
        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const projInfo = new ProjectInfo();
        projInfo.incentiveProgram =
          context.data.formExternalReviewSummary.incentiveProgram;

        const carbonCopyUserInfos = eventHandlers[context.eventType](
          projInfo,
          context.data.formExternalReviewSummary.formType,
          context.eventAction
        );

        // Fetch the carbon copy users from api
        that
          .getCarbonCopyUsersWithEmailPreferences(
            carbonCopyUserInfos,
            context.data.formExternalReviewSummary.parish,
            context.eventType,
            context.eventAction,
            <ProjectInfo>{
              incentiveProgram:
                context.data.formExternalReviewSummary.incentiveProgram
            }
          )
          .subscribe(carbonCopyUsers => {
            context.data.ccList = carbonCopyUsers;

            // Send specific event type action email
            that.sendExternalReviewObjectionLiftedEmail(context.data).subscribe(
              success => {
                that.swal.success(<SweetAlertOptions>{
                  title: `Email sent to the contacts of ${
                    context.data.formExternalReviewSummary.projectId
                  } & ${context.data.formExternalReviewSummary.formType}`,
                  useRejections: false
                });
              },
              error => {
                that.swal.error(<SweetAlertOptions>{
                  title: 'Oops!',
                  text:
                    'An error occurred while emailing stakeholders of this project.',
                  useRejections: false
                });
              }
            );
          });
      }
    );

    // Provide Handler for External Review Send
    pubSubService.handle<EventContext<ExternalReviewSend>>(
      events.externalReviewStatusChanges.code,
      externalReviewStatuses.pendingReview.displayName,
      context => {
        // Show Intent to User
        that.swal.load('Sending Email...');

        const agency = context.data.agency;
        // const totalCount = context.data.projectFormWrappers.length;
        const carbonCopyUserInfos = [
          <User>{
            userType: userType.external.code,
            externalReviewAgency: agency
          }
        ];

        if (agency === externalReviewAgencies.lga.code) {
          // if LGA send emails based on parish
          const projectFormWrappers = context.data.projectFormWrappers;

          const groupedProjectsByParish = _.groupBy(
            projectFormWrappers,
            function(project) {
              return project.projectInfo.location.parish;
            }
          );

          // foe each parish send the external review email
          for (const key in groupedProjectsByParish) {
            if (groupedProjectsByParish.hasOwnProperty(key)) {
              context.data.projectFormWrappers = groupedProjectsByParish[key];
              that
                .getCarbonCopyUsersWithEmailPreferences(
                  carbonCopyUserInfos,
                  key,
                  context.eventType,
                  context.eventAction,
                  <ProjectInfo>{
                    incentiveProgram:
                      context.data.projectFormWrappers[0].projectInfo
                        .incentiveProgram
                  }
                )
                .subscribe(carbonCopyUsers => {
                  context.data.ccList = carbonCopyUsers;
                  that.sendExternalReviewSendEmail(context.data).subscribe(
                    success => {},
                    error => {
                      that.swal.error({
                        title: 'Error Sending Email',
                        text:
                          'An error ocurred while sending the External Review Email. Please try again later.'
                      });
                    }
                  );
                });
            }
          }

          that.swal.success({
            title: 'External Review Email',
            text: 'An email was sent to notify Respective LGA'
          });
        } else {
          that
            .getCarbonCopyUsersWithEmailPreferences(
              carbonCopyUserInfos,
              context.data.projectFormWrappers[0].projectInfo.location.parish,
              context.eventType,
              context.eventAction,
              <ProjectInfo>{
                incentiveProgram:
                  context.data.projectFormWrappers[0].projectInfo
                    .incentiveProgram
              }
            )
            .subscribe(carbonCopyUsers => {
              context.data.ccList = carbonCopyUsers;
              // Send specific event type action email
              that.sendExternalReviewSendEmail(context.data).subscribe(
                success => {
                  that.swal.success({
                    title: 'External Review Email',
                    text: 'An email was sent to notify Respective Agency'
                  });
                },
                error => {
                  that.swal.error({
                    title: 'Error Sending Email',
                    text:
                      'An error ocurred while sending the External Review Email. Please try again later.'
                  });
                }
              );
            });
        }
      }
    );

    // Provide handler for Board Agenda Changes / Publish
    pubSubService.handle<{ boardMeeting: BoardMeeting; boardMembers: User[] }>(
      events.boardAgendaChanges.code,
      'Publish',
      agendaPublishDetails => {
        try {
          // this.sendPublishBoardAgendaEmail(agendaPublishDetails.boardMembers, agendaPublishDetails.boardMeeting.date);
          console.log('Just an event handler ever');
          console.log(agendaPublishDetails);
        } catch (e) {
          // Log the error
          console.log(agendaPublishDetails);
        }
      }
    );

    // Provide Handler for ECR or ACR Certification Email.
    pubSubService.handle<ProjectFormWrapper>(
      events.formCertificationChanges.code,
      formCertificationStatus.certified.display,
      context => {
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[
          events.formCertificationChanges.code
        ](
          context.projectInfo,
          context.form.type,
          formCertificationStatus.certified.display
        );

        this.getCarbonCopyUsersWithEmailPreferences(
          carbonCopyUserInfos,
          context.projectInfo.location.parish,
          events.formCertificationChanges.code,
          formCertificationStatus.certified.display,
          context.projectInfo
        ).subscribe(carbonCopyUsers => {
          const emailSendParameters = <EmailSendParameters<ProjectFormWrapper>>{
            data: context,
            ccList: carbonCopyUsers
          };

          this.sendCertificationEmail(emailSendParameters).subscribe(
            success => {
              that.swal.success({
                title: 'Success!!',
                text: 'Certification Email was Sent Successfully'
              });
            },
            error => {
              that.swal.error({
                title: 'Error!!',
                text: 'Certification Email Failed'
              });
            }
          );
        });
      }
    );

    // Provide handler for generic emails to be scheduled by application based on actions
    pubSubService.handleAllActionsForEvents<Reminder>(
      [events.scheduleEmailReminder.code],
      info => {
        that.scheduleReminderEmail(info).subscribe(
          success => {
            that.toast.queue({
              text: 'Successfully Schedule Email Reminder'
            });
          },
          error => {
            that.toast.error({
              text: 'An error ocurred when setting up the email reminder'
            });
          }
        );
      }
    );
    // sends emails to applicant requesting additional payments.
    pubSubService.handle<EventContext<ProjectFormWrapper>>(
      events.auditDepositTransaction.code,
      auditDepositTransactionActions.requestPayment,
      context => {
        that.swal.load('Sending Email...');
        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[
          events.auditDepositTransaction.code
        ](
          context.data.projectInfo,
          context.data.form.type,
          auditDepositTransactionActions.requestPayment
        );

        this.getCarbonCopyUsersWithEmailPreferences(
          carbonCopyUserInfos,
          context.data.projectInfo.location.parish,
          events.auditDepositTransaction.code,
          auditDepositTransactionActions.requestPayment,
          context.data.projectInfo
        ).subscribe(carbonCopyUsers => {
          const emailSendParameters = <EmailSendParameters<ProjectFormWrapper>>{
            data: context.data,
            ccList: carbonCopyUsers
          };
          this.sendAuditDepositPaymentRequestedEmail(
            emailSendParameters
          ).subscribe(
            success => {
              that.swal.success({
                title: 'Success!!',
                text: 'Payment Request Email was Sent Successfully'
              });
            },
            error => {
              that.swal.error({
                title: 'Error!!',
                text: 'Payment Request Email Failed'
              });
            }
          );
        });
      }
    );
    // sends emails to fiscal managers to initiate refund who subscribed to this email.
    pubSubService.handle<EventContext<ProjectFormWrapper>>(
      events.auditDepositTransaction.code,
      auditDepositTransactionActions.requestRefund,
      context => {
        that.swal.load('Sending Email...');
        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[
          events.auditDepositTransaction.code
        ](
          context.data.projectInfo,
          context.data.form.type,
          auditDepositTransactionActions.requestRefund
        );
        this.userService
          .getUser(this.userContextService.currentUser.id)
          .subscribe(user => {
            that
              .getCarbonCopyUsersWithEmailPreferences(
                carbonCopyUserInfos,
                context.data.projectInfo.location.parish,
                events.auditDepositTransaction.code,
                auditDepositTransactionActions.requestRefund,
                context.data.projectInfo
              )
              .subscribe(carbonCopyUsers => {
                this.userService
                  .getManagersWithFilters(
                    context.data.projectInfo.incentiveProgram,
                    'payments',
                    events.auditDepositTransaction.code,
                    auditDepositTransactionActions.requestRefund
                  )
                  .subscribe(managers => {
                    const fiscalManagerUsers = managers;
                    const emailSendParameters = <
                      EmailSendParameters<ProjectFormWrapper>
                    >{
                      data: context.data
                    };

                    const programManagerEmails = carbonCopyUsers
                      .filter(u => !u.permissions.payments)
                      .map(
                        mgm =>
                          new MailAddress(
                            mgm.emailAddress,
                            `${mgm.firstName} ${mgm.lastName}`
                          )
                      );

                    this.sendOutgoingPaymentNotification(
                      emailSendParameters,
                      user,
                      fiscalManagerUsers,
                      programManagerEmails
                    ).subscribe(
                      success => {
                        that.swal.success({
                          title: 'Success!!',
                          text: 'Request Refund Email was Sent Successfully'
                        });
                      },
                      error => {
                        that.swal.error({
                          title: 'Error!!',
                          text: 'Request Refund Email Failed'
                        });
                      }
                    );
                  });
              });
          });
      }
    );

    // This is not a form status change but triggers an email when manager requests CPA payments
    pubSubService.handle<EventContext<AuditChange>>(
      events.auditDepositTransaction.code,
      auditDepositTransactionActions.OutgoingpaymentRequest,
      context => {
        // Show loader to the user
        that.swal.load('Sending Email...');

        // Get Carbon Copy User Infos
        const carbonCopyUserInfos = eventHandlers[
          events.auditDepositTransaction.code
        ](
          context.data.projectInfoBase,
          formTypes.audit.abbrev,
          auditDepositTransactionActions.OutgoingpaymentRequest
        );
        this.userService
          .getUser(this.userContextService.currentUser.id)
          .subscribe(user => {
            // Fetch the carbon copy users from api
            that
              .getCarbonCopyUsersWithEmailPreferences(
                carbonCopyUserInfos,
                context.data.projectInfoBase.location.parish,
                context.eventType,
                context.eventAction,
                context.data.projectInfoBase
              )
              .subscribe(carbonCopyUsers => {
                const auditInfo = context.data;
                // Send specific event type action email

                this.userService
                  .getManagersWithFilters(
                    auditInfo.incentiveProgram,
                    'payments',
                    events.auditDepositTransaction.code,
                    auditDepositTransactionActions.OutgoingpaymentRequest
                  )
                  .subscribe(fiscalRecipients => {
                    that
                      .sendCPAPaymentEmail(
                        auditInfo.auditingCompanyName,
                        auditInfo.invoiceNumber.toString(),
                        auditInfo.paymentAmount,
                        auditInfo.projectId,
                        auditInfo.projectName,
                        auditInfo.incentiveProgram,
                        fiscalRecipients,
                        user,
                        carbonCopyUsers.filter(u => !u.permissions.payments)
                      )
                      .subscribe(
                        success => {
                          that.swal.success({
                            title:
                              'Notification of Pay CPA is sent to fiscal managers',
                            text: 'An email was sent to notify managers'
                          });
                        },
                        error => {
                          that.swal.error({
                            title: 'Error Sending Email',
                            text:
                              'An error ocurred while sending the Auditor Assignment email. Please try again later.'
                          });
                        }
                      );
                  });
              });
          });
      }
    );

    // This is not a form status change but triggers an email when manager cancels a CPA payments
    pubSubService.handle<
      EventContext<{
        outgoingPayment: OutgoingPayment;
        auditingCompanyName: string;
        comment: string;
      }>
    >(
      events.auditDepositTransaction.code,
      auditDepositTransactionActions.OutgoingpaymentCancelled,
      context => {
        // Show loader to the user
        that.swal.load('Sending Email...');
        that.projectDataService
          .resolveProjectInfo(
            context.data.outgoingPayment.paymentProject.formId.projectGuid
          )
          .subscribe(pi => {
            const projectInfoBase = pi;
            // Get Carbon Copy User Infos
            const carbonCopyUserInfos = eventHandlers[
              events.auditDepositTransaction.code
            ](
              projectInfoBase,
              formTypes.audit.abbrev,
              auditDepositTransactionActions.OutgoingpaymentCancelled
            );
            // Fetch the carbon copy users from api
            that
              .getCarbonCopyUsersWithEmailPreferences(
                carbonCopyUserInfos,
                projectInfoBase.location.parish,
                context.eventType,
                context.eventAction,
                projectInfoBase
              )
              .subscribe(carbonCopyUsers => {
                const outgoingPayment = context.data.outgoingPayment;
                const entId =
                  projectInfoBase.incentiveProgram ===
                  incentiveProgram.film.code
                    ? projectInfoBase['filmId']
                    : projectInfoBase.incentiveProgram ===
                      incentiveProgram.dm.code
                    ? projectInfoBase['digitalId']
                    : null;
                // Send specific event type action email

                this.userService
                  .getAuditors(outgoingPayment.auditingCompanyId)
                  .subscribe(recipients => {
                    that
                      .sendCPAPaymentCancellationEmail(
                        context.data.auditingCompanyName,
                        outgoingPayment.invoiceId.toString(),
                        outgoingPayment.createDate,
                        entId,
                        outgoingPayment.paymentProject.paidAmount,
                        outgoingPayment.paymentProject.projectId,
                        outgoingPayment.paymentProject.formId,
                        projectInfoBase.projectName,
                        projectInfoBase.incentiveProgram,
                        context.data.comment,
                        recipients,
                        carbonCopyUsers
                      )
                      .subscribe(
                        success => {
                          that.swal.success({
                            title: 'Notification to CPA is sent ',
                            text: 'An email was sent to CPA'
                          });
                        },
                        error => {
                          that.swal.error({
                            title: 'Error Sending Email',
                            text:
                              'An error ocurred while sending the Cancellation email. Please try again later.'
                          });
                        }
                      );
                  });
              });
          });
      }
    );

    //#endregion
  }

  cancelScheduledEmails(scheduledEmail: ScheduledEmail): Observable<User[]> {
    const url = this.cancelScheduledEmailsUrl;

    return this.http
      .post<HttpResponse<User[]>>(
        url,
        scheduledEmail,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.emailErrorHandler));
  }

  /**
   * Get all primary program managers' programs, email addresses, and names from API, then store in constant.
   * This data will be used when sending certain emails.
   *
   * This function should be called once sometime prior to when the service is first used.
   *
   * @memberof EmailService
   */
  setupPrimaryProgramManagerMailAddresses() {
    const that = this;
    this.subs.push(
      this.http
        .get(
          this.getPrimaryProgramManagerRecipientsUrl,
          this.authService.getAuthOptions()
        )
        .pipe(
          map((res: any) => {
            if (res) {
              const recipientsDictionary = res;

              const recipients: {
                incentiveProgram: string;
                mailAddress: MailAddress;
              }[] = _.chain(recipientsDictionary)
                .pairs()
                .map(pair => {
                  return {
                    incentiveProgram: pair[0],
                    mailAddress: new MailAddress(
                      pair[1].address,
                      pair[1].displayName,
                      pair[1].phoneNumber
                    )
                  };
                })
                .value();

              return recipients;
            }

            return null;
          })
        )
        .subscribe(recipients => {
          recipients.forEach(recipient => {
            that.programManagerRecipients.push(recipient);
          });
        })
    );
  }

  getCarbonCopyUsersWithEmailPreferences(
    carbonCopyUserInfos: User[],
    parish: string,
    eventName: string,
    actionType: string,
    projectInfo: ProjectInfoBase
  ): Observable<User[]> {
    const url = this.getCarbonCopyUsersWithEmailPreferencesUrl;

    return this.http
      .post(
        url,
        {
          carbonCopyUserInfos: carbonCopyUserInfos,
          Parish: parish,
          EventName: eventName,
          ActionType: actionType,
          projectInfo: projectInfo
        },
        this.authService.getAuthOptions('application/json')
      )
      .pipe(map(this.extractData))
      .pipe(catchError(this.emailErrorHandler));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  resendUserVerificationEmail(userVerificationToken: string) {
    const redirectionUrl = encodeURIComponent(
      this.navigator.convertUrlTreeToString(['verify', '{{tok}}'])
    );
    let url = `${
      this.resendUserVerificationEmailUrl
    }${userVerificationToken}?redirectionUrl=${redirectionUrl}`;

    // * We need to add tester here so that we don't accidentally email real users when testing the application!
    if (
      environment.testEmailRecipientAddressess &&
      environment.testEmailRecipientAddressess.length > 0
    ) {
      url += `&testerEmailAddress=${
        environment.testEmailRecipientAddressess[0].emailAddress
      }&testerName=${environment.testEmailRecipientAddressess[0].displayName}`;
    }

    const fromDetails = `&fromEmailAddress=${encodeURIComponent(
      environment.emailFromAddress.emailAddress
    )}&fromName=${encodeURIComponent(
      environment.emailFromAddress.displayName
    )}`;

    return this.http
      .post(
        url + fromDetails,
        {},
        this.authService.getAuthOptions('application/json')
      )
      .pipe(catchError(this.emailErrorHandler));
  }

  sendAdditionalPaymentReceivedEmail(
    project: Project,
    amountRequested: number,
    paymentType: string,
    manager: User[]
  ): Observable<any> {
    const subject = `LED FastLane - Payment Submitted for "${paymentType}" for ${
      project.projectInfo.projectId
      } ${this.appendEntertainmentInfo(project.projectInfo)}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.AdditionalPaymentReceived
    );

    // params for additional email context
    params.amountRequested = amountRequested;
    params.paymentType = paymentType;
    params.recipients = manager
      .filter(u => !u.permissions.payments)
      .map(
        mgm =>
          new MailAddress(mgm.emailAddress, `${mgm.firstName} ${mgm.lastName}`)
      );
    params.redirectionUrl = this.navigator.convertUrlTreeToString([
      'management',
      'projects',
      project.id,
      'summary'
    ]);

    return this.sendEmail(params);
  }

  sendAdditionalPaymentRequestedEmail(
    project: Project,
    additionalPaymentComments: string,
    amountRequested: number,
    paymentType: string,
    formId: FormIdentifier,
    payeeEmail: string,
    payeeName: string,
    payeeIndex: number,
    payeeUserId: string
  ): Observable<any> {
    const formName = getFormTypeName(formId.formType);
    const subject =  `LED FastLane - Payment Request for "${paymentType}" for ${
      project.projectInfo.projectId
      } ${this.appendEntertainmentInfo(project.projectInfo)}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.AdditionalPaymentRequested
    );

    // params for additional email context
    params.additionalPaymentComments = additionalPaymentComments;
    params.amountRequested = amountRequested;
    params.paymentType = paymentType;

    const payee = new MailAddress(payeeEmail, payeeName);

    // params for additional email context
    params.doInjectToken = payeeUserId === undefined || payeeUserId == null;
    params.form = new FormDetails();
    params.form.name = formName;
    params.recipients = [payee];
    params.redirectionUrl = this.createRedirectionUrl(
      formId,
      params.doInjectToken,
      payeeIndex
    );

    return this.sendEmail(params);
  }

  sendAuditDepositPaymentRequestedEmail(
    emailParameters: EmailSendParameters<ProjectFormWrapper>
  ): Observable<any> {
    const formName = getFormTypeName(emailParameters.data.form.type);
    const subject = `LED FastLane - Payment Request for "${formName}" for ${
      emailParameters.data.projectInfo.projectId
    } ${this.appendEntertainmentInfo(emailParameters.data.projectInfo)}`;
    const params = new EmailParameters(
      emailParameters.data.projectInfo.projectId,
      subject,
      EmailTemplate.AdditionalPaymentRequested
    );

    // params for additional email context
    params.amountRequested = emailParameters.data.form.fees[0].amountDue;
    params.paymentType = emailParameters.data.form.type;
    params.ccList = _.chain(emailParameters.ccList)
      .map(
        user =>
          new MailAddress(
            user.emailAddress,
            user.firstName + ' ' + user.lastName
          )
      )
      .value();
    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;
    params.recipients = emailParameters.data.contacts.map(
      ct => new MailAddress(ct.emailAddress, `${ct.firstName} ${ct.lastName}`)
    );
    params.redirectionUrl = this.createRedirectionUrl(
      new FormIdentifier({
        formType: emailParameters.data.form.type,
        formIndex: emailParameters.data.form.formIndex,
        projectGuid: emailParameters.data.projectGuid
      }),
      params.doInjectToken
    );

    return this.sendEmail(params);
  }

  /**
   * @summary Sends a refund request
   *  TO: LED 'Fiscal' Managers that have the event notification preference for the action of refund request and can see the payment menu
   *  CC: Managers for the program without the payment menu with the event notification preference.
   * @param emailParameters
   * @param currentUser
   * @param recipients Fiscal Managers that may be responsible for sending the refund to the applicant
   * @param managers Program managers (non-fiscal) that need to be notified based on preferences
   */
  sendOutgoingPaymentNotification(
    emailParameters: EmailSendParameters<ProjectFormWrapper>,
    currentUser: User,
    recipients: User[],
    managers: MailAddress[]
  ): Observable<any> {
    const formName = getFormTypeName(emailParameters.data.form.type);
    const subject = `LED FastLane - Refund Request for "${formName}" for ${
      emailParameters.data.projectInfo.projectId
    } ${this.appendEntertainmentInfo(emailParameters.data.projectInfo)}`;
    const params = new EmailParameters(
      emailParameters.data.projectInfo.projectId,
      subject,
      EmailTemplate.RefundPayment
    );
    const finalRecipient = Object.assign(
      <AuditInfo>{},
      emailParameters.data.form
    ).finalCertificationRecipient;
    // params for additional email context
    params.amountRequested = emailParameters.data.form.fees[0].amountDue * -1;
    params.paymentType = emailParameters.data.form.type;
    // Add all links to all users
    const urlTreeForContext = [
      getUserModule('management'),
      'payments',
      'outgoing'
    ];
    const managerUserType = userType['management'];
    const outgoingPaymentLink = this.navigator.convertUrlTreeToStringsPerUserType(
      urlTreeForContext,
      [managerUserType]
    )[managerUserType.name];
    // Email Body
    params.body = `<p>A refund in the amount of $${
      params.amountRequested
    } is needed for ${emailParameters.data.projectInfo.projectId}.</p>
    <p>Date of Request: ${new Date().toDateString()}</p>
    <p>Requested by: ${currentUser.firstName + ' ' + currentUser.lastName}</p>
    <p>Project Id: ${emailParameters.data.projectInfo.projectId}</p>
    <p>Project Name: ${emailParameters.data.projectInfo.projectName}</p>
    <p>Refund Amount: $${params.amountRequested}</p>
    <p>Issue to: ${finalRecipient.firstName + ' ' + finalRecipient.lastName}
                 ${finalRecipient.address.addressLine1},${
      finalRecipient.address.addressLine2
    }
                 ${finalRecipient.address.city}
                 ${finalRecipient.address.state}
                 ${finalRecipient.address.zip}</p>
    <p>Click <a href="${outgoingPaymentLink}">here</a> to issue refund</p>`;
    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;
    params.ccList = managers;
    params.recipients = recipients.map(
      rec =>
        new MailAddress(rec.emailAddress, rec.firstName + ' ' + rec.lastName)
    );
    params.redirectionUrl = this.createRedirectionUrl(
      new FormIdentifier({
        formType: emailParameters.data.form.type,
        formIndex: emailParameters.data.form.formIndex,
        projectGuid: emailParameters.data.projectGuid
      }),
      params.doInjectToken
    );

    return this.sendEmail(params);
  }

  sendBoardStatusChangeEmail(
    project: Project,
    boardType: string,
    boardStatus: string,
    comments: string,
    formType: string,
    closureReason: any,
    manager: User,
    ccList?: User[]
  ): Observable<any> {
    const formName = getFormTypeName(formType);
    const subject = project.projectInfo.incentiveProgram.toUpperCase() == 'ITE' ? `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
      } has been ${boardStatus} by the ${boardType} for ${project.projectInfo.companyName}` : `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
      } has been ${boardStatus} by the ${boardType}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.BoardApprovedContractApplication
    );

    // params for additional email context
    params.boardDecision = new BoardSpecificFormDecisionDetails();
    params.boardDecision.boardType = boardType;
    params.boardDecision.decision = boardStatus;
    if (closureReason) {
      params.boardDecision.closureReason = closureReason;
    }
    params.projectStatus = project.projectStatusLog[0].status;
    params.form = new FormDetails();
    params.form.name = formName;
    params.form.statusChangeComments = comments;
    params.manager = new ManagerDetails();
    // todo: do we use current logged-in manager, or a specific manager?
    params.manager = {
      email: manager.emailAddress,
      name: manager.fullName(),
      phoneNumber: manager.phoneNumber ? manager.phoneNumber.toString() : 'N/A'
    };

    params.recipients = project.projectContacts.map(
      contact =>
        new MailAddress(
          contact.emailAddress,
          `${contact.firstName} ${contact.lastName}`
        )
    );

    params.ccList = ccList.map(
      contact =>
        new MailAddress(
          contact.emailAddress,
          `${contact.firstName} ${contact.lastName}`
        )
    );

    return this.sendEmail(params);
  }

  sendBoardApprovedContractChangeEmail(
    project: Project,
    boardType: string,
    comments: string,
    formType: string,
    manager: User
  ): Observable<any> {
    const formName = getFormTypeName(formType);
    const subject = `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
    } has been Board Approved by the ${boardType}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.BoardApprovedContractChange
    );

    // params for additional email context
    params.boardDecision = new BoardSpecificFormDecisionDetails();
    params.boardDecision.boardType = boardType;
    params.form = new FormDetails();
    params.form.name = formName;
    params.form.statusChangeComments = comments;
    // todo: do we use current logged-in manager, or a specific manager?
    params.manager = {
      email: manager.emailAddress,
      name: manager.fullName(),
      phoneNumber: manager.phoneNumber.toString()
    };

    return this.sendEmail(params);
  }

  sendBoardApprovedContractClosureEmail(
    project: Project,
    boardType: string,
    closureReason: string,
    comments: string,
    manager: User
  ): Observable<any> {
    const subject = project.projectInfo.incentiveProgram.toUpperCase() == 'ITE' ? `LED FastLane - Contract Closure for ${
      project.projectInfo.projectId
      } has been Board Approved by the ${boardType} for ${project.projectInfo.companyName}` : `LED FastLane - Contract Closure for ${
      project.projectInfo.projectId
      } has been Board Approved by the ${boardType}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.BoardApprovedContractClosure
    );

    // params for additional email context
    params.boardDecision = new BoardSpecificFormDecisionDetails();
    params.boardDecision.boardType = boardType;
    params.boardDecision.closureReason = closureReason;
    params.form = new FormDetails();
    params.form.statusChangeComments = comments;
    // todo: do we use current logged-in manager, or a specific manager?
    params.manager = {
      email: manager.emailAddress,
      name: manager.fullName(),
      phoneNumber: manager.phoneNumber.toString()
    };

    return this.sendEmail(params);
  }

  sendBoardNoticeEmail(
    boardAddress: Address,
    boardBuilding: string,
    boardDate: Date,
    formWrapper: ProjectFormWrapper,
    manager: User
  ): Observable<any> {
    const subject = `LED FastLane - ${boardDate.toLocaleDateString()} Board Notice, ${
      formWrapper.form.type
    } for ${formWrapper.projectInfo.projectId}`;
    const params = new EmailParameters(
      formWrapper.projectInfo.projectId,
      subject,
      EmailTemplate.BoardNotice
    );

    // params for additional email context
    params.boardMeetingDetails = new BoardMeetingDetails();
    params.boardMeetingDetails.building = boardBuilding;
    params.boardMeetingDetails.date = boardDate;
    params.boardMeetingDetails.address = boardAddress;
    params.form = new FormDetails();
    params.form.name = formWrapper.form.type;
    params.manager = {
      email: manager.emailAddress,
      name: manager.fullName(),
      phoneNumber: manager.phoneNumber ? manager.phoneNumber.toString() : 'N/A'
    };
    params.recipients = formWrapper.contacts.map(
      contact =>
        new MailAddress(
          contact.emailAddress,
          `${contact.firstName} ${contact.lastName}`
        )
    );

    return this.sendEmail(params);
  }

  sendBusinessSignatoryRefusedToSignEmail(
    project: Project,
    formName: string
  ): Observable<any> {
    const subject = `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
    } requires attention`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.SignatoryRefusedToSign
    );

    const payeeIndex = +this.router.routerState.snapshot.root.queryParams[
      'payee'
    ];

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;
    params.recipients = [
      <MailAddress>{
        emailAddress: project.projectContacts[payeeIndex].emailAddress,
        displayName: project.projectContacts[payeeIndex].fullName()
      }
    ];

    return this.sendEmail(params);
  }

  sendFormBusinessSignatureRequiredEmail(
    project: Project,
    formId: FormIdentifier,
    signeeEmail: string,
    signeeName: string,
    payeeIndex: number
  ): Observable<any> {
    const formName = getFormTypeName(formId.formType);
    const subject = project.projectInfo.incentiveProgram == 'ITE' ? `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
      } requires Signature for ${project.projectInfo.companyName}` : `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
      } requires Signature`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.BusinessSignatureRequired
    );

    // Construct email parameter preconditions
    const signee = new MailAddress(signeeEmail, signeeName);

    // params for additional email context
    params.doInjectToken = !_.contains(project.userIds, signee.emailAddress);
    params.form = new FormDetails();
    params.form.name = formName;
    params.recipients = [signee];
    params.redirectionUrl = this.createRedirectionUrl(
      formId,
      params.doInjectToken,
      payeeIndex
    );

    return this.sendEmail(params);
  }

  sendExternalReviewObjectionEmail(
    formExternalReviewStatusChange: FormExternalReviewStatusChange
  ): Observable<any> {
    const projId =
      formExternalReviewStatusChange.formExternalReviewSummary.projectId;
    const formTypeName = _.values(formTypes).find(
      form =>
        form.abbrev ===
        formExternalReviewStatusChange.formExternalReviewSummary.formType
    ).name;
    const externalReviewAgency = _.values(externalReviewAgencies).find(
      agency =>
        agency.abbrev ===
        formExternalReviewStatusChange.formExternalReviewSummary
          .externalReviewAgency
    );
    const subject = formExternalReviewStatusChange.formExternalReviewSummary.incentiveProgram.toUpperCase() == 'ITE' ? `LED FastLane - ${formTypeName} for ${projId} has Objections from ${
      externalReviewAgency.name} for ${formExternalReviewStatusChange.formExternalReviewSummary.projectCompany}` : `LED FastLane - ${formTypeName} for ${projId} has Objections from ${
      externalReviewAgency.name}`;
    const params = new EmailParameters(
      projId,
      subject,
      EmailTemplate.ExternalReviewObjection
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formTypeName;
    params.externalReviewAgency = externalReviewAgency.code;
    params.recipients = formExternalReviewStatusChange.formExternalReviewSummary.projectContacts.map(
      contact =>
        new MailAddress(
          contact.emailAddress,
          `${contact.firstName} ${contact.lastName}`
        )
    );
    if (formExternalReviewStatusChange.ccList.length > 0) {
      params.ccList = _.chain(formExternalReviewStatusChange.ccList)
        .map(
          user =>
            new MailAddress(
              user.emailAddress,
              user.firstName + ' ' + user.lastName
            )
        )
        .value();
    }
    params.redirectionUrl = this.loginLink;

    return this.sendEmail(params);
  }

  sendExternalReviewObjectionLiftedEmail(
    formExternalReviewStatusChange: FormExternalReviewStatusChange
  ): Observable<any> {
    const projId =
      formExternalReviewStatusChange.formExternalReviewSummary.projectId;
    const formTypeName = _.values(formTypes).find(
      form =>
        form.abbrev ===
        formExternalReviewStatusChange.formExternalReviewSummary.formType
    ).name;
    const externalReviewAgency = _.values(externalReviewAgencies).find(
      agency =>
        agency.abbrev ===
        formExternalReviewStatusChange.formExternalReviewSummary
          .externalReviewAgency
    );
    const subject = formExternalReviewStatusChange.formExternalReviewSummary.incentiveProgram.toUpperCase() == 'ITE' ? `LED FastLane - ${formTypeName} for ${projId} has been marked Objection Lifted by ${
      externalReviewAgency.name
      } for ${formExternalReviewStatusChange.formExternalReviewSummary.projectCompany}` : `LED FastLane - ${formTypeName} for ${projId} has been marked Objection Lifted by ${
      externalReviewAgency.name}`;
    const params = new EmailParameters(
      projId,
      subject,
      EmailTemplate.ExternalReviewObjectionLifted
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formTypeName;
    params.externalReviewAgency = externalReviewAgency.code;
    params.recipients = formExternalReviewStatusChange.formExternalReviewSummary.projectContacts.map(
      contact =>
        new MailAddress(
          contact.emailAddress,
          `${contact.firstName} ${contact.lastName}`
        )
    );
    if (formExternalReviewStatusChange.ccList.length > 0) {
      params.ccList = _.chain(formExternalReviewStatusChange.ccList)
        .map(
          user =>
            new MailAddress(
              user.emailAddress,
              user.firstName + ' ' + user.lastName
            )
        )
        .value();
    }
    params.redirectionUrl = this.loginLink;

    return this.sendEmail(params);
  }

  sendForgotPasswordEmail(
    project: Project,
    userEmailAddress: string
  ): Observable<any> {
    const subject = `LED FastLane - Forgot Password`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.ForgotPassword
    );

    // params for additional email context
    params.recipients = [new MailAddress(userEmailAddress, null)];
    // todo: we need a redirection url for the password reset page when we decide on an implementation for password resets
    // todo: the legacy system generates passwords that last for 3 days, but i am unsure if we are continuing that strategy

    return this.sendEmail(params);
  }

  sendFormStatusChangeEmailOld(
    project: Project,
    comments: string,
    formId: FormIdentifier,
    formStatus: string,
    ccList: User[],
    manager: User
  ): Observable<any> {
    const formName = getFormTypeName(formId.formType);
    const subject = `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
      } has been marked ${formStatus}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.FormStatusChange


    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.statusChangeComments = comments;
    params.form.name = formName;
    params.form.status = formStatus;
    if (ccList.length > 0) {
      params.ccList = _.chain(ccList)
        .map(user => new MailAddress(user.emailAddress, user.fullName()))
        .value();
    }
    params.manager = {
      email: manager.emailAddress,
      name: manager.fullName(),
      phoneNumber: manager.phoneNumber ? manager.phoneNumber.toString() : ''
    };
    params.recipients = project.projectContacts.map(
      contact => new MailAddress(contact.emailAddress, contact.fullName())
    );

    // Construct the redirection URL Tree and convert to a URL string
    const redirectionUrlTree = [
      userType.applicant.code,
      'projects',
      project.id
    ];
    if (
      !oneToOneFormTypes.includes(formId.formType) &&
      formId.formIndex !== undefined &&
      formId.formIndex != null
    ) {
      redirectionUrlTree.push(formId.formIndex.toString());
    }
    redirectionUrlTree.push(formId.formType);
    params.redirectionUrl = this.navigator.convertUrlTreeToString(
      redirectionUrlTree
    );

    return this.sendEmail(params);
  }

  sendNoticeOfAffidavitOfAnnualCertificationEmail(
    project: Project,
    manager: User
  ): Observable<any> {
    const subject = `LED FastLane - Affidavit of Annual Certification for ${
      project.projectInfo.projectId
    }`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.NoticeOfAffidavitOfAnnualCertification
    );

    // params for additional email context
    // todo: we need to cc the external review user for agency
    params.manager = {
      email: manager.emailAddress,
      name: manager.fullName(),
      phoneNumber: manager.phoneNumber.toString()
    };
    params.recipients = project.projectContacts.map(
      contact => new MailAddress(contact.emailAddress, contact.fullName())
    );

    return this.sendEmail(params);
  }

  sendNoticeOfEcrCertificationEmail(
    project: Project,
    manager: User
  ): Observable<any> {
    const subject = `LED FastLane - Employee Certification Report (ECR) Certification for ${
      project.projectInfo.projectId
    }`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.NoticeOfEcrCertification
    );

    // params for additional email context
    // todo: we need to cc the external review user for agency
    params.manager = {
      email: manager.emailAddress,
      name: manager.fullName(),
      phoneNumber: manager.phoneNumber.toString()
    };
    params.recipients = project.projectContacts.map(
      contact => new MailAddress(contact.emailAddress, contact.fullName())
    );

    return this.sendEmail(params);
  }

  sendFormPaymentRequiredEmail(
    project: Project,
    formId: FormIdentifier,
    payeeEmail: string,
    payeeName: string,
    payeeIndex: number,
    payeeUserId: string
  ): Observable<any> {
    const formName = getFormTypeName(formId.formType);
    const subject = `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
    } is ready for payment`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.PaymentRequired
    );

    const payee = new MailAddress(payeeEmail, payeeName);

    // params for additional email context
    params.doInjectToken = payeeUserId === undefined || payeeUserId == null;
    params.form = new FormDetails();
    params.form.name = formName;
    params.recipients = [payee];
    params.redirectionUrl = this.createRedirectionUrl(
      formId,
      params.doInjectToken,
      payeeIndex
    );

    return this.sendEmail(params);
  }

  sendPaymentRequiredEmailToAccountHolder(
    project: Project,
    formId: FormIdentifier
  ): Observable<any> {
    const formName = getFormTypeName(formId.formType);
    const subject = `LED FastLane - ${formName} for ${
      project.projectInfo.projectId
    } is ready for payment`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.PaymentRequired
    );

    const payeeIndex = +this.router.routerState.snapshot.root.queryParams[
      'payee'
    ];

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;
    params.recipients = [
      <MailAddress>{
        emailAddress: project.projectContacts[payeeIndex].emailAddress,
        displayName: project.projectContacts[payeeIndex].fullName()
      }
    ];

    params.redirectionUrl = this.createRedirectionUrl(formId);

    return this.sendEmail(params);
  }

  // todo: split email template in api for project access decision

  sendProjectAccessApprovedEmail(
    project: Project,
    projectId: string,
    incentiveProgramType: string,
    redirectionUrl: string,
    requestingUserEmailAddress: string,
    requestingUserFullName: string
  ): Observable<any> {
    const subject = project.projectInfo.incentiveProgram.toUpperCase() == 'ITE' ? `LED FastLane - Project Access Request for ${project.projectInfo.projectId} from ${project.projectInfo.companyName} - Approved` :
      `LED FastLane - Project Access Request for ${project.projectInfo.projectId} - Approved`;
    const params = new EmailParameters(
      projectId,
      subject,
      EmailTemplate.ProjectAccessApproved
    );

    // params for additional email context
    const manager = this.programManagerRecipients.find(
      recipient => recipient.incentiveProgram === incentiveProgramType
    );
    params.manager = {
      email: manager.mailAddress.emailAddress,
      name: manager.mailAddress.displayName,
      phoneNumber: manager.mailAddress.phoneNumber
        ? manager.mailAddress.phoneNumber.toString()
        : ''
    };
    params.recipients = [
      new MailAddress(requestingUserEmailAddress, requestingUserFullName)
    ];
    params.redirectionUrl = redirectionUrl;

    return this.sendEmail(params);
  }

  sendProjectAccessDeniedEmail(
    project: Project,
    projectId: string,
    incentiveProgramType: string,
    projectAccessDenialComment: string,
    requestingUserEmailAddress: string,
    requestingUserFullName: string
  ): Observable<any> {
    const subject = project.projectInfo.incentiveProgram.toUpperCase() == 'ITE' ? `LED FastLane - Project Access Request for ${project.projectInfo.projectId} from ${project.projectInfo.companyName} - Denied` :
      `LED Fastlane - Project Access Request for ${project.projectInfo.projectId} - Denied`;
    const params = new EmailParameters(
      projectId,
      subject,
      EmailTemplate.ProjectAccessDenied
    );

    // params for additional email context
    const manager = this.programManagerRecipients.find(
      recipient => recipient.incentiveProgram === incentiveProgramType
    );
    params.manager = {
      email: manager.mailAddress.emailAddress,
      name: manager.mailAddress.displayName,
      phoneNumber: manager.mailAddress.phoneNumber
        ? manager.mailAddress.phoneNumber.toString()
        : ''
    };
    params.projectAccessComment = projectAccessDenialComment;
    params.recipients = [
      new MailAddress(requestingUserEmailAddress, requestingUserFullName)
    ];

    return this.sendEmail(params);
  }

  sendProjectAccessRequestEmail(
    project: Project,
    isUserRequestingProjectAccessConsultant: boolean,
    requestComment: string,
    requestingUser: User
  ): Observable<any> {
    const subject = project.projectInfo.incentiveProgram.toUpperCase() == 'ITE' ? `LED FastLane - Project Access Request - ${
      project.projectInfo.projectId} for ${project.projectInfo.companyName}` :
      `LED FastLane - Project Access Request - ${project.projectInfo.projectId}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.ProjectAccessRequest
    );

    // params for additional email context
    params.companyName = project.projectInfo.companyName;
    params.program = project.projectInfo.incentiveProgram;
    params.projectAccessComment = requestComment;
    // this.setupPrimaryProgramManagerMailAddresses();
    const manager = this.programManagerRecipients.find(
      recipient =>
        recipient.incentiveProgram === project.projectInfo.incentiveProgram
    );
    params.recipients = [manager.mailAddress];

    params.userRequestingProjectAccess = new MailAddress(
      requestingUser.emailAddress,
      requestingUser.fullName()
    );
    params.userRequestingProjectAccessId = requestingUser.id;
    params.isUserRequestingProjectAccessConsultant = isUserRequestingProjectAccessConsultant;

    params.redirectionUrl = this.navigator.convertUrlTreeToString([
      'management',
      'projects',
      'assign-access'
    ]);

    return this.sendEmail(params);
  }

  sendProjectAttachmentsUploadedEmail(
    project: Project,
    documentTypes: string[]
  ): Observable<any> {
    const subject = `LED FastLane - Attachments Uploaded for ${
      project.projectInfo.projectId
    }`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.ProjectAttachmentsUploaded
    );

    // params for additional email context
    params.uploadedDocumentTypes = documentTypes;
    // todo: get the relavent LED managers' mail addresses for recipients
    params.recipients = [];

    return this.sendEmail(params);
  }

  sendProjectsReadyForExternalReviewEmail(
    projectId: string,
    project: Project,
    agencyName: string,
    numFormsAwaitingReview: number,
    parishes: string[],
    programs: string[],
    redirectionUrl: string
  ): Observable<any> {
    const subject = 'LED FastLane - Projects Ready for External Review';
    const params = new EmailParameters(
      projectId,
      subject,
      EmailTemplate.ProjectsReadyForExternalReview
    );

    // params for additional email context
    params.numFormsAwaitingReview = numFormsAwaitingReview;
    // Setting the recipients in the api to avoid round trip of external review users.
    params.recipients = [];
    params.parishes = parishes;
    params.incentivePrograms = programs;
    params.externalReviewAgency = agencyName;
    params.redirectionUrl = this.loginLink;

    return this.sendEmail(params);
  }

  sendPublishBoardAgendaEmail(
    boardMembers: User[],
    boardDate: Date
  ): Observable<any> {
    const subject = `LED FastLane - Board Agenda for ${boardDate.toLocaleDateString()} has been published`;
    const params = new EmailParameters(
      null,
      subject,
      EmailTemplate.PublishBoardAgenda
    );

    // params for additional email context
    params.boardMeetingDetails = new BoardMeetingDetails();
    params.boardMeetingDetails.date = boardDate;
    params.recipients = boardMembers.map(
      contact =>
        new MailAddress(
          contact.emailAddress,
          `${contact.firstName} ${contact.lastName}`
        )
    );
    params.redirectionUrl = window.location.origin + '/board';

    return this.sendEmail(params);
  }

  sendEventEmail<T extends ProjectFormWrapper | Project>(
    emailSendParameters: EmailSendParameters<T>,
    carbonCopyUsers: User[],
    eventName: string,
    actionType: string
  ) {
    // Ensure there is a handler for the situation
    if (!this.eventNameActionEmailer[eventName + actionType]) {
      return;
    }

    // Fetch all users from api using carbonCopyFetcher
    this.getCarbonCopyUsersWithEmailPreferences(
      carbonCopyUsers,
      emailSendParameters.data.projectInfo.location.parish,
      eventName,
      actionType,
      emailSendParameters.data.projectInfo
    ).subscribe((returnedcarbonCopyUsers: User[]) => {
      // Inside Observable
      let emailer = this.eventNameActionEmailer[eventName + actionType];
      emailer = emailer.bind(this);

      // assigining the carbon copy users from database to the email send params object ccList
      emailSendParameters.ccList = returnedcarbonCopyUsers;
    });
  }

  // This function creates object for review complete specific email
  sendFormStatusChangeEmail(
    formStatusChange: FormStatusChange
  ): Observable<any> {
    const comment =
      formStatusChange.projectFormWrapper.form.statuses[0].comment;
    const formStatus =
      formStatusChange.projectFormWrapper.form.statuses[0].status;
    const formName = getFormTypeName(
      formStatusChange.projectFormWrapper.form.type
    );
    let subject = `LED FastLane - ${formName} for ${
      formStatusChange.projectFormWrapper.projectInfo.projectId
    } has been marked ${formStatus} ${this.appendEntertainmentInfo(
      formStatusChange.projectFormWrapper.projectInfo
    )}`;

    if (formStatusChange.projectFormWrapper.projectInfo.incentiveProgram.toUpperCase() === 'ITE') {
      subject = `LED FastLane - ${formName} for ${
        formStatusChange.projectFormWrapper.projectInfo.projectId
      } has been marked ${formStatus} ${this.appendEntertainmentInfo(
        formStatusChange.projectFormWrapper.projectInfo
      )} for ${formStatusChange.projectFormWrapper.projectInfo.companyName}`
    } else if (formStatusChange.projectFormWrapper.projectInfo.incentiveProgram.toUpperCase() === 'STEP') {
      if (formStatus === 'On Hold') {
        subject = `LED FastLane - ${formName} for ${
          formStatusChange.projectFormWrapper.projectInfo.projectId
        } has been marked On-Hold`;
      }
    }


    const params = new EmailParameters(
      formStatusChange.projectFormWrapper.projectInfo.projectId,
      subject,
      EmailTemplate.FormStatusChange
    );

    // params for additional email context
    params.isCocFormCompliant = formStatusChange.projectFormWrapper.projectInfo.incentiveProgram.toUpperCase() === 'ITE' &&
      formStatusChange.projectFormWrapper.form.type === 'coc' &&
      formStatusChange.projectFormWrapper.form.hasOwnProperty('compliant')  &&
      formStatusChange.projectFormWrapper.form['compliant'] === true;

    params.isStepApplicationFormCompliant =
      formStatusChange.projectFormWrapper.projectInfo.incentiveProgram.toUpperCase() === 'STEP' &&
      formStatusChange.projectFormWrapper.form.type === formTypes.stepApplication.abbrev &&
      formStatusChange.projectFormWrapper.form.hasOwnProperty('checklistApproved') &&
      formStatusChange.projectFormWrapper.form['checklistApproved'] === true;
    params.stepApplicationFormAwardAmount = formStatusChange.projectFormWrapper.projectInfo.incentiveProgram.toUpperCase() === 'STEP' &&
      formStatusChange.projectFormWrapper.form.type === formTypes.stepApplication.abbrev &&
      formStatusChange.projectFormWrapper.form.hasOwnProperty('checklistApproved')  &&
      formStatusChange.projectFormWrapper.form['checklistApproved'] === true &&
      formStatusChange.projectFormWrapper.form['checklistAwardAmount'] &&
      formStatusChange.projectFormWrapper.form['checklistAwardAmount'] > 0
      ? formStatusChange.projectFormWrapper.form['checklistAwardAmount'] : 0;

    if (formStatusChange.projectFormWrapper.projectInfo.incentiveProgram.toUpperCase() === 'STEP') {
      params.stepId = formStatusChange.projectFormWrapper.projectInfo['stepId'];
    }


    params.form = new FormDetails();
    params.parish =
      formStatusChange.projectFormWrapper.projectInfo.location.parish;
    params.form.statusChangeComments = comment;
    params.form.name = formName;
    params.companyName = formStatusChange.projectFormWrapper.projectInfo.companyName;
    params.form.status = formStatus;
    params.legislationRule =
      formStatusChange.projectFormWrapper.projectInfo.legislation;
    params.program =
      formStatusChange.projectFormWrapper.projectInfo.incentiveProgram;
    if (formStatusChange.ccList.length > 0) {
      params.ccList = _.chain(formStatusChange.ccList)
        .map(
          user =>
            new MailAddress(user.emailAddress, user.firstName + user.lastName)
        )
        .value();
    }

    // get the primary program manager (Ask Kate because excel doesnt have managers as CC for review complete)
    const manager = this.programManagerRecipients.filter(
      m =>
        m.incentiveProgram ===
        formStatusChange.projectFormWrapper.projectInfo.incentiveProgram
    )[0];

    params.manager = {
      email: manager.mailAddress.emailAddress,
      name: manager.mailAddress.displayName,
      phoneNumber: manager.mailAddress.phoneNumber
        ? manager.mailAddress.phoneNumber.toString()
        : ''
    };
    params.recipients = formStatusChange.projectFormWrapper.contacts.map(
      contact =>
        new MailAddress(
          contact.emailAddress,
          contact.firstName + contact.lastName
        )
    );

    // Construct the redirection URL Tree and convert to a URL string
    const redirectionUrlTree = [
      userType.applicant.code,
      'projects',
      formStatusChange.projectFormWrapper.projectGuid
    ];
    if (
      !oneToOneFormTypes.includes(
        formStatusChange.projectFormWrapper.form.type
      ) &&
      formStatusChange.projectFormWrapper.form.formIndex !== undefined &&
      formStatusChange.projectFormWrapper.form.formIndex != null
    ) {
      redirectionUrlTree.push(
        formStatusChange.projectFormWrapper.form.formIndex.toString()
      );
    }
    redirectionUrlTree.push(formStatusChange.projectFormWrapper.form.type);
    params.redirectionUrl = this.navigator.convertUrlTreeToString(
      redirectionUrlTree
    );
    return this.sendEmail(params);
  }

  sendAuditorAssignedEmail(
    context: EventContext<AuditChange>,
    convertUrlTreeToStringsPerUserType: (
      rawUrlTree: string[],
      userTypes: UserType[],
      queryParams?: Params
    ) => {}
  ): Observable<any> {
    const form = context.data.auditForm;

    const currentFormStatus = form.statuses[0].status;

    const formName = getFormTypeName(form.type);

    const subject = `LED FastLane - ${formName} for ${
      context.data.projectName
    }-${
      context.data.projectId
    } has been marked ${currentFormStatus} ${this.appendEntertainmentInfo(
      context.data.projectInfoBase,
      true
    )}`;

    const params = new EmailParameters(
      context.data.projectId,
      subject,
      EmailTemplate.AuditorAssigned
    );
    params.auditingCompanyId = context.data.auditingCompanyId;

    params.body = `
    <p>
        ${context.data.auditingCompanyName} has been assigned to this project. We encourage you to make contact as early as possible
        in order to properly prepare documentation for their review. Once you are ready, you will need to upload the cost report and general ledger
        into FastLane. Once the cost report and general ledger are uploaded, the CPA will be notified to begin their review.
    </p>
    <p>Project ID: ${context.data.projectId}</p>
    <p>Entertainment ID: ${context.data.entertainmentId}</p>
    <p>Production Title: ${context.data.projectName}</p>
    ${context.data.authorizedRepresentative ? `
      <p>Authorized Representative Name: ${context.data.authorizedRepresentative.firstName} ${context.data.authorizedRepresentative.lastName}</p>
      <p>Authorized Representative Phone: ${context.data.authorizedRepresentative.phoneNumber}</p>
      <p>Authorized Representative Email: ${context.data.authorizedRepresentative.emailAddress}</p>
      <p>Authorized Representative Address: ${context.data.authorizedRepresentative.address.addressLine1}, ${context.data.authorizedRepresentative.address.city}, ${context.data.authorizedRepresentative.address.state}, ${context.data.authorizedRepresentative.address.zip}</p>
    ` : ''}
    `;


    params.form = new FormDetails();
    params.form.name = formName;

    //CC list is la.gov addresses
    if (context.data.ccList.length > 0) {
      params.ccList = _.chain(context.data.ccList)
        .map(
          user =>
            new MailAddress(user.emailAddress, user.firstName + user.lastName)
        )
        .value();
    }

    // get the primary program manager (Ask Kate because excel doesnt have managers as CC for review complete)
    const manager = this.programManagerRecipients.filter(
      m => m.incentiveProgram === context.data.incentiveProgram
    )[0];

    params.manager = {
      email: manager.mailAddress.emailAddress,
      name: manager.mailAddress.displayName,
      phoneNumber: manager.mailAddress.phoneNumber
        ? manager.mailAddress.phoneNumber.toString()
        : ''
    };

    // Contacts should be carbon copied
    params.ccList.push(
      ...context.data.contacts.map(contact => {
        return new MailAddress(
          contact.emailAddress,
          contact.firstName + contact.lastName
        );
      })
    );

    const urlTreeForContext = this.navigator.getUserAgnosticUrlTreeForFormId(<
      FormIdentifier
      >{
      formType: form.type,
      formIndex: form.formIndex,
      projectGuid: context.data.projectGuid
    });
    params.body += generateLinksForUsers(
      [userType['applicant'], userType['management'], userType['auditor']],
      urlTreeForContext,
      convertUrlTreeToStringsPerUserType
    );
    params.body += `<p>Once the cost report and general ledger have been uploaded, the CPA will be notified to begin the audit.</p>`

    /* LED 391 - April stated this email is all wrong, but wanted to save it for later just in case
    const subject = `LED FastLane - ${formName} for ${
      context.data.projectName
    }-${
      context.data.projectId
    } has been marked ${currentFormStatus} ${this.appendEntertainmentInfo(
      context.data.projectInfoBase,
      true
    )}`;
    const depositAmount =
      context.data.paymentAmount && context.data.paymentAmount > 0
        ? context.data.paymentAmount.toString()
        : 'N/A';
    //
    const params = new EmailParameters(
      context.data.projectId,
      subject,
      EmailTemplate.AuditorAssigned
    );

    // Set Auditing Company Id
    params.auditingCompanyId = context.data.auditingCompanyId;

    // Email Body
    // SEASON, ESTIMATED COST REPORT ARE NOT APPLICABLE TO DIGITAL
    params.body = `
    <p>
        Please proceed with your review for the following:
    </p>

    <p><b>${context.data.entertainmentId} - ${context.data.projectName}</b></p>

    <p>This notice authorizes up to <b>$ ${depositAmount}</b> for your accounting services.
    Should this amount prove insufficient to cover the full cost of completing this engagement,
     please provide a draft verification report, and summary of work performed to date with an estimated cost to completion,
      and await further written approval from LED to proceed. </p>

      <p>The project’s contact is copied, and you may reach out to them at your convenience.</p>
      <p>As always, we welcome your assistance with applying due diligence on behalf of the State for these requests for
      final certification.</p>`;

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;

    if (context.data.ccList.length > 0) {
      params.ccList = _.chain(context.data.ccList)
        .map(
          user =>
            new MailAddress(user.emailAddress, user.firstName + user.lastName)
        )
        .value();
    }

    // get the primary program manager (Ask Kate because excel doesnt have managers as CC for review complete)
    const manager = this.programManagerRecipients.filter(
      m => m.incentiveProgram === context.data.incentiveProgram
    )[0];

    params.manager = {
      email: manager.mailAddress.emailAddress,
      name: manager.mailAddress.displayName,
      phoneNumber: manager.mailAddress.phoneNumber
        ? manager.mailAddress.phoneNumber.toString()
        : ''
    };

    // Contacts should be carbon copied
    params.ccList.push(
      ...context.data.contacts.map(contact => {
        return new MailAddress(
          contact.emailAddress,
          contact.firstName + contact.lastName
        );
      })
    );
   */
    return this.sendEmail(params);
  }

  sendAwaitingAuditEmail(
    context: EventContext<AuditChange>,
    convertUrlTreeToStringsPerUserType: (
      rawUrlTree: string[],
      userTypes: UserType[],
      queryParams?: Params
    ) => {}
  ): Observable<Response> {
    const form = context.data.auditForm;

    const currentFormStatus = form.statuses[0].status;

    const formName = getFormTypeName(form.type);

    const subject = `LED FastLane - ${formName} for ${
      context.data.projectName
    }-${
      context.data.projectId
    } has been marked ${currentFormStatus} ${this.appendEntertainmentInfo(
      context.data.projectInfoBase,
      true
    )}`;

    //
    const params = new EmailParameters(
      context.data.projectId,
      subject,
      EmailTemplate.AwaitingAudit
    );

    // Set Auditing Company Id
    params.auditingCompanyId = context.data.auditingCompanyId;

    // Email Body
    // SEASON, ESTIMATED COST REPORT ARE NOT APPLICABLE TO DIGITAL
    params.body = `
    <p> Project ID: ${context.data.projectId}</p>
    <p> ${context.data.entertainmentIdType} ID: ${
      context.data.entertainmentId
    }</p>
    <p> Production Title: ${context.data.projectName}</p>`;

    // Verify if season is not null
    if (context.data.season) {
      params.body += `<p> Season: ${context.data.season}</p>`;
    }

    // Verify if costReportSubmissionDate is not null
    if (context.data.costReportSubmissionDate) {
      params.body += `<p> Estimated Cost Report Submission Date: ${
        context.data.costReportSubmissionDate
      }</p>`;
    }

    // Add authorize representative if not null
    if (context.data.authorizedRepresentative) {
      params.body += `<p> Authorized Representative Name: ${context.data.authorizedRepresentative.fullName()}</p>
      <p> Authorized Representative Phone: ${
        context.data.authorizedRepresentative.phoneNumber
      }</p>
      <p> Authorized Representative Email: ${
        context.data.authorizedRepresentative.emailAddress
      }</p>
      <p> Authorized Representative Address: ${
        context.data.authorizedRepresentative.address.addressLine1
      }${
        context.data.authorizedRepresentative.address.addressLine2
          ? ' ' + context.data.authorizedRepresentative.address.addressLine2
          : ''
      } ${context.data.authorizedRepresentative.address.city}, ${
        context.data.authorizedRepresentative.address.state
      } ${context.data.authorizedRepresentative.address.zip}</p>
      `;
    }

    // Add all links to all users
    const urlTreeForContext = this.navigator.getUserAgnosticUrlTreeForFormId(<
      FormIdentifier
    >{
      formType: form.type,
      formIndex: form.formIndex,
      projectGuid: context.data.projectGuid
    });
    params.body += generateLinksForUsers(
      [userType['applicant'], userType['management'], userType['auditor']],
      urlTreeForContext,
      convertUrlTreeToStringsPerUserType
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;

    if (context.data.ccList.length > 0) {
      params.ccList = _.chain(context.data.ccList)
        .map(
          user =>
            new MailAddress(user.emailAddress, user.firstName + user.lastName)
        )
        .value();
    }

    // get the primary program manager (Ask Kate because excel doesnt have managers as CC for review complete)
    const manager = this.programManagerRecipients.filter(
      m => m.incentiveProgram === context.data.incentiveProgram
    )[0];

    params.manager = {
      email: manager.mailAddress.emailAddress,
      name: manager.mailAddress.displayName,
      phoneNumber: manager.mailAddress.phoneNumber
        ? manager.mailAddress.phoneNumber.toString()
        : ''
    };

    // Contacts should be carbon copied
    params.ccList.push(
      ...context.data.contacts.map(contact => {
        return new MailAddress(
          contact.emailAddress,
          contact.firstName + contact.lastName
        );
      })
    );

    return this.sendEmail(params);
  }

  sendAuditReceivedEmail(
    context: EventContext<AuditChange>,
    convertUrlTreeToStringsPerUserType: (
      rawUrlTree: string[],
      userTypes: UserType[],
      queryParams?: Params
    ) => {}
  ): Observable<Response> {
    const form = context.data.auditForm;

    const momentDate = new moment(form.statuses[0].statusDate);
    const formName = getFormTypeName(form.type);
    const subject = `LED FastLane - ${formName} for ${
      context.data.projectId
    } has been submitted on ${momentDate.format('MM/DD/YYYY')} - ${
      context.data.projectName
    } ${this.appendEntertainmentInfo(
      context.data.projectInfoBase,
      true
    )}`;

    //
    const params = new EmailParameters(
      context.data.projectId,
      subject,
      EmailTemplate.AuditReceived
    );

    // Set Auditing Company Id
    params.auditingCompanyId = context.data.auditingCompanyId;

    // Email Body
    // SEASON, ESTIMATED COST REPORT ARE NOT APPLICABLE TO DIGITAL
    params.body = `
    <p> Project ID: ${context.data.projectId}</p>
    <p> ${context.data.entertainmentIdType} ID: ${
      context.data.entertainmentId
    }</p>
    <p> Production Title: ${context.data.projectName}</p>`;

    // Verify if season is not null
    if (context.data.season) {
      params.body += `<p> Season: ${context.data.season}</p>`;
    }

    // Verify if costReportSubmissionDate is not null
    if (context.data.costReportSubmissionDate) {
      params.body += `<p> Estimated Cost Report Submission Date: ${
        context.data.costReportSubmissionDate
      }</p>`;
    }

    // Add authorize representative if not null
    if (context.data.authorizedRepresentative) {
      params.body += `<p> Authorized Representative Name: ${context.data.authorizedRepresentative.fullName()}</p>
      <p> Authorized Representative Phone: ${
        context.data.authorizedRepresentative.phoneNumber
      }</p>
      <p> Authorized Representative Email: ${
        context.data.authorizedRepresentative.emailAddress
      }</p>
      <p> Authorized Representative Address: ${
        context.data.authorizedRepresentative.address.addressLine1
      }${
        context.data.authorizedRepresentative.address.addressLine2
          ? ' ' + context.data.authorizedRepresentative.address.addressLine2
          : ''
      } ${context.data.authorizedRepresentative.address.city}, ${
        context.data.authorizedRepresentative.address.state
      } ${context.data.authorizedRepresentative.address.zip}</p>
      `;
    }

    // Add all links to all users
    const urlTreeForContext = this.navigator.getUserAgnosticUrlTreeForFormId(<
      FormIdentifier
    >{
      formType: form.type,
      formIndex: form.formIndex,
      projectGuid: context.data.projectGuid
    });
    params.body += generateLinksForUsers(
      [userType['management'], userType['auditor']],
      urlTreeForContext,
      convertUrlTreeToStringsPerUserType
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;

    if (context.data.ccList.length > 0) {
      params.ccList = _.chain(context.data.ccList)
        .map(
          user =>
            new MailAddress(user.emailAddress, user.firstName + user.lastName)
        )
        .value();
    }

    // get the primary program manager (Ask Kate because excel doesnt have managers as CC for review complete)
    const manager = this.programManagerRecipients.filter(
      m => m.incentiveProgram === context.data.incentiveProgram
    )[0];

    params.manager = {
      email: manager.mailAddress.emailAddress,
      name: manager.mailAddress.displayName,
      phoneNumber: manager.mailAddress.phoneNumber
        ? manager.mailAddress.phoneNumber.toString()
        : ''
    };

    return this.sendEmail(params);
  }

  sendExternalReviewSendEmail(
    externalReviewSend: ExternalReviewSend
  ): Observable<any> {
    const that = this;
    const subject = `LED FastLane - Projects Ready for External Review`;
    const params = new EmailParameters(
      null,
      subject,
      EmailTemplate.ProjectsReadyForExternalReview
    );

    params.projectForms = externalReviewSend.projectFormWrappers;

    const parishes = _.uniq(
      externalReviewSend.projectFormWrappers.map(
        pfw => pfw.projectInfo.location.parish
      )
    );
    const incentivePrograms = _.uniq(
      externalReviewSend.projectFormWrappers.map(
        pfw => pfw.projectInfo.incentiveProgram
      )
    );

    // params for additional email context
    params.numFormsAwaitingReview =
      externalReviewSend.projectFormWrappers.length;
    // Setting the recipients in the api to avoid round trip of external review users.
    params.recipients = externalReviewSend.ccList.map(
      rec =>
        new MailAddress(rec.emailAddress, rec.firstName + ' ' + rec.lastName)
    );
    params.parishes = parishes;
    params.incentivePrograms = incentivePrograms;
    params.externalReviewAgency = externalReviewSend.agency;
    params.redirectionUrl = this.loginLink;
    params.dueDate = externalReviewSend.dueDate;

    return this.sendEmail(params);
  }

  sendSubmissionSuccessfulEmail(
    project: Project,
    ccList: User[],
    formName: string,
    submittedDate: Date
  ): Observable<any> {
    const incentiveProgram = project.projectInfo.incentiveProgram.toUpperCase();
    let subject = "";
    switch (incentiveProgram) {
      case 'ITE':
        subject = `LED FastLane - ${formName} for ${
          project.projectInfo.projectId
          } has been submitted on ${submittedDate.toLocaleDateString()} ${this.appendEntertainmentInfo(
            project.projectInfo
          )} for ${project.projectInfo.companyName}`;
        break;
      case 'DM':
      case 'FILM':
        if (formName == 'Expenditure Verification Report') {
          subject = `LED FastLane - ${formName} Deposit for ${
            project.projectInfo.projectId
            } has been submitted on ${submittedDate.toLocaleDateString()} ${this.appendEntertainmentInfo(
              project.projectInfo
            )}`;
        }
        else {
          subject = `LED FastLane - ${formName} for ${
            project.projectInfo.projectId
            } has been submitted on ${submittedDate.toLocaleDateString()} ${this.appendEntertainmentInfo(
              project.projectInfo
            )}`;
        }
        break;
      case 'STEP': {
        subject = `LED FastLane - ${formName} for ${
          project.projectInfo.projectId
        } has been Submitted`;
        break;
      }
      default: {
        subject = `LED FastLane - ${formName} for ${
          project.projectInfo.projectId
        } has been submitted on ${submittedDate.toLocaleDateString()} ${this.appendEntertainmentInfo(
          project.projectInfo
        )}`;
        break;
      }
    }
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.SubmissionSuccessful
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = formName;
    params.parish = project.projectInfo.location.parish;
    params.program = project.projectInfo.incentiveProgram;
    // todo: get program-related LED managers for recipients
    params.ccList = _.chain(ccList)
      .map(user => new MailAddress(user.emailAddress, user.fullName()))
      .value();

    // Get project primary contact
    params.recipients = _.chain(project.projectContacts)
      .map(contact => new MailAddress(contact.emailAddress, contact.fullName()))
      .value();

    return this.sendEmail(params);
  }

  sendCOCExtensionRequestNotificationEmail (
    project: Project
  ) {
    const subject = `LED FastLane - Certification of Compliance extension request for ${
      project.projectInfo.projectId
    } has been submitted`;

    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.COCExtensionRequested
    );

    params.form = new FormDetails();
    params.form.name = `Certification of Compliance`;
    params.parish = project.projectInfo.location.parish;
    params.program = project.projectInfo.incentiveProgram;

    const manager = this.programManagerRecipients.find(
      recipient => recipient.incentiveProgram === 'ITE'
    );

    params.recipients = [new MailAddress(
      manager.mailAddress.emailAddress,
      manager.mailAddress.displayName
    )];

    return this.sendEmail(params);
  }

  sendSignatureSentNotice(
    projectData: ProjectFormWrapper,
    formId: FormIdentifier
  ): Observable<any> {
    const documentType =
      formId.formType === formTypes.application.abbrev
        ? 'Contract'
        : formId.formType === formTypes.renewalApplication.abbrev
        ? 'Renewal Contract'
        : getFormTypeName(formId.formType);
    let subject = `${
      projectData.projectInfo.projectId
    } ${documentType} Sent for Signature`;
    console.log("sending for sig");
    if (projectData.projectInfo.incentiveProgram.toUpperCase() === 'ITE') {
      subject = `${
          projectData.projectInfo.projectId
        } ${documentType} Sent for Signature - ${projectData.projectInfo.companyName}`;
    } else if (projectData.projectInfo.incentiveProgram.toUpperCase() === 'STEP') {
      subject = `LED FastLane – The Application (STEP) Grant Award Agreement for ${projectData.projectInfo.projectId} has been sent for signature`;
    }

    const params = new EmailParameters(
      projectData.projectInfo.projectId,
      subject,
      EmailTemplate.EsignSentForSignature
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = documentType;

    // Get project primary contact
    params.recipients = _.chain(projectData.contacts)
      .map(
        contact =>
          new MailAddress(
            contact.emailAddress,
            contact.firstName + ' ' + contact.lastName
          )
      )
      .value();

    return this.sendEmail(params);
  }

  sendSignatureReSentNotice(
    projectAgreement: ProjectAgreement
  ): Observable<any> {
    // const documentType =
    //   projectAgreement.formId.formType === formTypes.application.abbrev
    //     ? 'Contract'
    //     : projectAgreement.formId.formType ===
    //       formTypes.renewalApplication.abbrev
    //     ? 'Renewal Contract'
    //     : getFormTypeName(projectAgreement.formId.formType);
    const documentType =
      projectAgreement.agreement.completedAgreementAttachment.documentType;
    const subject = `${
      projectAgreement.projectInfo.projectId
    } ${documentType} Sent for Signature`;

    const params = new EmailParameters(
      projectAgreement.projectInfo.projectId,
      subject,
      EmailTemplate.EsignSentForSignature
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = documentType;

    // Get project primary contact
    params.recipients = _.chain(projectAgreement.projectContacts)
      .map(
        contact =>
          new MailAddress(
            contact.emailAddress,
            contact.firstName + ' ' + contact.lastName
          )
      )
      .value();

    return this.sendEmail(params);
  }

  sendAcrCertificationEmail(
    acr: AnnualCertificationReport,
    project: Project,
    ccList: User[],
    manager: User
  ): Observable<any> {
    const subject = `LED FastLane - ACR for ${
      project.projectInfo.projectId
    } and Year (${
      acr.year
    }) has been submitted on ${acr.certifiedDate.toLocaleDateString()}`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.NoticeOfAcrCertification
    );

    // params for additional email context
    params.form = new FormDetails();
    params.form.name = 'acr';
    // add program-related LED managers and LDR user to the CC list.
    params.ccList = _.chain(ccList)
      .map(user => new MailAddress(user.emailAddress, user.fullName()))
      .value();

    params.manager = new ManagerDetails();
    params.manager = {
      email: manager.emailAddress,
      phoneNumber: manager.phoneNumber,
      name: manager.fullName()
    };
    // Set program property
    params.program = project.projectInfo.incentiveProgram;

    // Set Company Name
    params.companyName = project.projectInfo.companyName;

    // Set redirection url
    const redirectionUrlTree = [
      userType.applicant.code,
      'projects',
      project.id,
      'certification',
      'acr'
    ];
    params.redirectionUrl = this.navigator.convertUrlTreeToString(
      redirectionUrlTree
    );

    // Get project primary contact
    params.recipients = _.chain(project.projectContacts)
      .map(contact => new MailAddress(contact.emailAddress, contact.fullName()))
      .value();

    return this.sendEmail(params);
  }

  sendCertificationEmail(
    emailSendParams: EmailSendParameters<ProjectFormWrapper>
  ): Observable<any> {
    const that = this;
    // ecr certification email
    if (emailSendParams.data.form.type.toLowerCase() === formTypes.ecr.abbrev) {
      const ecr = <EmployeeCertificationReport>emailSendParams.data.form;

      const subject = `LED FastLane - ECR for ${
        emailSendParams.data.projectInfo.projectId
      } and Year (${ecr.year}) has been reviewed`;
      const params = new EmailParameters(
        emailSendParams.data.projectInfo.projectId,
        subject,
        EmailTemplate.NoticeOfEcrCertification
      );

      // params for additional email context
      params.form = new FormDetails();
      params.form.name = formTypes.ecr.name;
      params.form.statusChangeComments = ecr.comments;

      // added program-related LED managers to CC recipients.

      if (emailSendParams.ccList.length > 0) {
        params.ccList = _.chain(emailSendParams.ccList)
          .map(
            user =>
              new MailAddress(user.emailAddress, user.firstName + user.lastName)
          )
          .value();
      }

      // Set program property
      params.program = emailSendParams.data.projectInfo.incentiveProgram;

      // Set redirection url
      const redirectionUrlTree = [
        'applicant',
        'projects',
        emailSendParams.data.projectGuid,
        'certification',
        'ecr'
      ];
      params.redirectionUrl = this.navigator.convertUrlTreeToString(
        redirectionUrlTree
      );

      // Get project primary contact
      params.recipients = emailSendParams.data.contacts.map(
        contact =>
          new MailAddress(
            contact.emailAddress,
            contact.firstName + contact.lastName
          )
      );
      return this.sendEmail(params);
    } else {
      // acr certification email
      const acr = <AnnualCertificationReport>emailSendParams.data.form;

      const subject = `LED FastLane - ACR for ${
        emailSendParams.data.projectInfo.projectId
      } and Year (${acr.year}) has been reviewed`;
      const params = new EmailParameters(
        emailSendParams.data.projectInfo.projectId,
        subject,
        EmailTemplate.NoticeOfAcrCertification
      );

      // params for additional email context
      params.form = new FormDetails();
      params.form.name = formTypes.acr.name;
      params.form.statusChangeComments = acr.comments;
      // add program-related LED managers and LDR user to the CC list.
      if (emailSendParams.ccList.length > 0) {
        params.ccList = _.chain(emailSendParams.ccList)
          .map(
            user =>
              new MailAddress(user.emailAddress, user.firstName + user.lastName)
          )
          .value();
      }

      const incentiveProgramType = emailSendParams.data.projectInfo.incentiveProgram;


      if (!this.programManagerRecipients || this.programManagerRecipients.length === 0) {
        that.swal.error({
          title: 'Error!!',
          text: 'Certification Email Failed To Send - Program Manages Did Not Load. Please Refresh Page'
        });
        throw null;
      }

      const manager = this.programManagerRecipients.find(
        recipient => recipient.incentiveProgram === incentiveProgramType
      );

      if (!manager) {
        that.swal.error({
          title: 'Error!!',
          text: 'Certification Email Failed To Send - Program Manager Did Not Load. Please Refresh Page'
        });
        throw null;
      }
      if (manager && manager.mailAddress) {
        params.manager = new ManagerDetails();
        params.manager = {
          email: manager.mailAddress.emailAddress,
          phoneNumber: manager.mailAddress.phoneNumber,
          name: manager.mailAddress.displayName
        };
      }


      // Set program property
      params.program = emailSendParams.data.projectInfo.incentiveProgram;

      // Set Company Name
      params.companyName = emailSendParams.data.projectInfo.companyName;

      // Set redirection url
      const redirectionUrlTree = [
        'applicant',
        'projects',
        emailSendParams.data.projectGuid,
        'certification',
        'acr'
      ];
      params.redirectionUrl = this.navigator.convertUrlTreeToString(
        redirectionUrlTree
      );

      // Get project primary contact
      params.recipients = emailSendParams.data.contacts.map(
        contact =>
          new MailAddress(
            contact.emailAddress,
            contact.firstName + contact.lastName
          )
      );

      return this.sendEmail(params);
    }
  }

  // TODO Need to revisit this function once Cedeste Provides requirements to this email
  // sendECRCertificationEmail(
  //   ecr: EmployeeCertificationReport,
  //   project: Project,
  //   ccList: User[]
  // ): Observable<any> {
  //   const subject = `LED FastLane - ECR for ${
  //     project.projectInfo.projectId
  //     } and Year (${
  //     ecr.year
  //     }) has been submitted on ${ecr.certifiedDate.toLocaleDateString()}`;
  //   const params = new EmailParameters(
  //     project.projectInfo.projectId,
  //     subject,
  //     EmailTemplate.NoticeOfEcrCertification
  //   );

  //   // params for additional email context
  //   params.form = new FormDetails();
  //   params.form.name = formTypes.ecr.name;
  //   // added program-related LED managers to CC recipients.
  //   params.ccList = _.chain(ccList)
  //     .map(user => new MailAddress(user.emailAddress, user.fullName()))
  //     .value();

  //   // Set program property
  //   params.program = project.projectInfo.incentiveProgram;

  //   // Set redirection url
  //   const redirectionUrlTree = [
  //     'applicant',
  //     'projects',
  //     project.id,
  //     'certification',
  //     'ecr'
  //   ];
  //   params.redirectionUrl = this.navigator.convertUrlTreeToString(
  //     redirectionUrlTree
  //   );

  //   // Get project primary contact
  //   params.recipients = _.chain(project.projectContacts)
  //     .map(contact => new MailAddress(contact.emailAddress, contact.fullName()))
  //     .value();

  //   return this.sendEmail(params);
  // }

  // todo: it seems like this email should actually never get sent depending on how we build the registration page

  sendUserAccountAlreadyExistsEmail(
    project: Project,
    redirectionUrl: string
  ): Observable<any> {
    const subject = `LED FastLane - User Account Email already exists`;
    const params = new EmailParameters(
      project.projectInfo.projectId,
      subject,
      EmailTemplate.UserAccountAlreadyExists
    );

    // params for additional email context
    params.redirectionUrl = redirectionUrl;

    return this.sendEmail(params);
  }

  sendUserAccountCreatedEmail(
    newUser: User,
    redirectionUrl: string
  ): Observable<any> {
    const subject = `LED FastLane - Account Created`;
    const params = new EmailParameters(
      null,
      subject,
      EmailTemplate.UserAccountCreated
    );

    // params for additional email context
    params.recipients = [
      new MailAddress(
        newUser.emailAddress,
        `${newUser.firstName} ${newUser.lastName}`
      )
    ];
    params.redirectionUrl = redirectionUrl;
    // todo: template says to provide a temporary password, but i think our implementation will have users enter their own passwords

    return this.sendEmail(params);
  }

  sendUserAccountUpdatedEmail(
    newUser: User,
    redirectionUrl: string
  ): Observable<any> {
    const subject = `LED FastLane - Account Updated`;
    const params = new EmailParameters(
      null,
      subject,
      EmailTemplate.UserAccountCreated
    );

    // params for additional email context
    params.recipients = [
      new MailAddress(
        newUser.emailAddress,
        `${newUser.firstName} ${newUser.lastName}`
      )
    ];
    params.redirectionUrl = redirectionUrl;
    // todo: template says to provide a temporary password, but i think our implementation will have users enter their own passwords

    return this.sendEmail(params);
  }

  // This function creates object for review complete specific email
  scheduleReminderEmail(reminderInfo: Reminder): Observable<any> {
    const params = new EmailParameters(
      reminderInfo.projectFormWrapper.projectInfo.projectId,
      reminderInfo.subject,
      reminderInfo.emailTemplate
    );

    /*****************************************************************************
     *USE FORM INFORMATION TO BUILD SPECIFIC CONTEXT OF THAT FORM FOR FUTURE EMAIL*
     ******************************************************************************/
    // Email Reminder to be sent to all contacts of the project
    if (reminderInfo.projectFormWrapper) {
      params.recipients = reminderInfo.projectFormWrapper.contacts.map(
        contact =>
          new MailAddress(
            contact.emailAddress,
            contact.firstName + contact.lastName
          )
      );

      // Construct a form identifier for current form if applicable.
      const formId = <FormIdentifier>{
        formIndex: reminderInfo.projectFormWrapper.form.formIndex,
        formType: reminderInfo.projectFormWrapper.form.type,
        projectGuid: reminderInfo.projectFormWrapper.projectGuid
      };

      // Construct the redirection URL Tree and convert to a URL string
      params.redirectionUrl = this.createRedirectionUrl(formId);
    }

    return this.scheduleEmail(<ScheduledEmail>{
      emailParameters: params,
      scheduledDate: new Date(),
      created: new Date(),
      creator: this.userContextService.currentUser.id
    });
  }

  private createRedirectionUrl(
    formId: FormIdentifier,
    doInjectToken: boolean = false,
    payeeIndex: number = null
  ) {
    const urlSegments = [
      `${doInjectToken ? userType.guest.code : userType.applicant.code}`,
      'projects',
      `${formId.projectGuid}`
    ];

    if (!soloFormTypes.includes(formId.formType)) {
      urlSegments.push(formId.formIndex.toString());
    }

    urlSegments.push(formId.formType);

    const redirectionUrlTree = this.router.createUrlTree(urlSegments);

    if (doInjectToken) {
      redirectionUrlTree.queryParams['tok'] = '{{tok}}';

      if (payeeIndex != null) {
        redirectionUrlTree.queryParams['payee'] = payeeIndex.toString();
      }
    }

    return convertUrlTreeToString(
      redirectionUrlTree,
      this.router,
      this.location
    );
  }

  private emailErrorHandler(error: any | any): Promise<any> {
    return this.swal.error({
      title: 'Email Failed To Send',
      text: 'Please try again later.'
    });
  }

  private scheduleEmailErrorHandler(error: any | any): Promise<any> {
    return this.swal.error({
      title: 'Scheduling Error',
      text:
        'The application was unable to schedule a reminder email, please try again later.'
    });
  }

  private extractData<T>(res: T) {
    const response = res ? res : {};
    return response;
  }

  private sendEmail(params: EmailParameters): Observable<any> {
    // Set the email addresses of all testers that will receive emails
    // The associated environment property should have a non-empty array for all environments except Prod
    params.testers = environment.testEmailRecipientAddressess;

    return this.http
      .post(
        this.sendEmailUrl,
        params,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(catchError(this.emailErrorHandler));

  }

  private scheduleEmail(scheduledEmail: ScheduledEmail): Observable<any> {
    // Set the email addresses of all testers that will receive emails
    // The associated environment property should have a non-empty array for all environments except Prod
    scheduledEmail.emailParameters.testers =
      environment.testEmailRecipientAddressess;

    return this.http
      .post(
        this.scheduleEmailUrl,
        scheduledEmail,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(catchError(this.scheduleEmailErrorHandler));
  }

  scheduleReminders(formId: FormIdentifier): Observable<any> {
    return this.httpClient.post(
      this.scheduleRemindersUrl,
      formId,
      this.authService.getAuthOptionsForHttpClient('application/json')
    );
  }

  sendCPAPaymentEmail(
    CPAFirm: string,
    invoiceNumber: string,
    paymentAmount: number,
    projectId: string,
    projectName: string,
    incentiveProgram: string,
    reciepientList: User[],
    currentUser: User,
    programManagers: User[]
  ): Observable<Response> {
    const subject = `CPA Payment Request`;
    const params = new EmailParameters(
      projectId,
      subject,
      EmailTemplate.CPAPayment
    );

    // Add all links to all users
    const urlTreeForContext = ['payments', 'outgoing'];
    const managerUserType = userType['management'];
    const outgoingPaymentLink = this.navigator.convertUrlTreeToStringsPerUserType(
      urlTreeForContext,
      [managerUserType]
    )[managerUserType.name];
    // Email Body
    params.body = `
    <p>Pay ${CPAFirm} Invoice ${invoiceNumber} in the amount of $${paymentAmount} for the Verification Report for ${projectId} associated with ${projectName}</p>
    <p>Date of Request: ${new Date().toDateString()}</p>
    <p>Requested by: ${currentUser.firstName + ' ' + currentUser.lastName}</p>
    <p>Project Id: ${projectId}</p>
    <p>Project Name: ${projectName}</p>
    <p>CPA Firm: ${CPAFirm}</p>
    <p>Invoice Number:${invoiceNumber}</p>
    <p>Payment Amount: $${paymentAmount}</p>
    <p>Click <a href="${outgoingPaymentLink}">here</a> to view invoice attachment</p>`;

    if (reciepientList.length > 0) {
      params.recipients = _.chain(reciepientList)
        .map(managerrecipient => {
          managerrecipient.fullName = User.prototype.fullName;
          return new MailAddress(
            managerrecipient.emailAddress,
            managerrecipient.fullName()
          );
        })
        .value();
    }

    // get all program managers with the notification preferences (Ask Kate because excel doesnt have managers as CC for review complete)
    const managerEmails = programManagers.map(r => {
      r.fullName = User.prototype.fullName;
      return new MailAddress(r.emailAddress, r.fullName());
    });

    params.ccList.push(...managerEmails);

    return this.sendEmail(params);
  }

  sendCPAPaymentCancellationEmail(
    CPAFirm: string,
    invoiceNumber: string,
    dateRequested: Date,
    entId: string,
    paymentAmount: number,
    projectId: string,
    formId: FormIdentifier,
    projectName: string,
    incentiveProgram: string,
    comment: string,
    reciepientList: User[],
    ccs: User[]
  ): Observable<Response> {
    const subject = `CPA Payment Cancellation`;
    const params = new EmailParameters(
      projectId,
      subject,
      EmailTemplate.CPAPaymentCancellation
    );

    // Add all links to all users
    const urlTreeForContext = [
      'projects',
      formId.projectGuid,
      formId.formIndex.toString(),
      formId.formType
    ];
    // Email Body
    params.body = `
    <p>The payment for  CPA Invoice ${invoiceNumber} in the amount of $${-paymentAmount} for the Verification Report for ${projectId}, ${entId} associated with ${projectName} has been Cancelled
 </p>
 <p>Comments: ${comment}</p>
    <p>Date of Request: ${new Date(dateRequested).toDateString()}</p>
    <p>Date of Cancellation: ${new Date().toDateString()}</p>
    `;

    if (reciepientList.length > 0) {
      params.recipients = _.chain(reciepientList)
        .map(managerrecipient => {
          managerrecipient.fullName = User.prototype.fullName;
          return new MailAddress(
            managerrecipient.emailAddress,
            managerrecipient.fullName()
          );
        })
        .value();
    }

    // get all program managers with the notification preferences (Ask Kate because excel doesnt have managers as CC for review complete)
    const managerEmails = ccs.map(r => {
      r.fullName = User.prototype.fullName;
      return new MailAddress(r.emailAddress, r.fullName());
    });

    params.ccList.push(...managerEmails);

    return this.sendEmail(params);
  }

  /**
   * @summary Optionally returns a string containing Project Name and Entertainment Id
   */
  appendEntertainmentInfo(
    projectInfo: ProjectInfoBase,
    excludeProjName: Boolean = false
  ) {
    // Only return something that is not empty whenever the program type is
    // either FILM or DM
    if (
      [incentiveProgram.dm.code, incentiveProgram.film.code].includes(
        projectInfo.incentiveProgram
      )
    ) {
      const entId = ((projectInfo as unknown) as EntertainmentId).entId;
      const idPart = !!entId ? `(${entId})` : '';

      const extraInfo = `- ${
        excludeProjName ? '' : projectInfo.projectName
      } ${idPart}`;

      return extraInfo;
    }

    // Returns empty string for all other scenarios
    return '';
  }
  sendDMattachmentsUploadedEmail(
    projectId: string,
    reciepientList: User[],
    documentTypes: string[],
    projectGuid: string
  ): Observable<any> {

    const redirectionUrlTree = [
      userType.applicant.code,
      'projects',
      projectId
    ];

    const params = new EmailParameters(
      projectId,
      "Attachment has been uploaded to " + projectId,
      EmailTemplate.AddDMAttachment
    );

    if (reciepientList.length > 0) {
      params.recipients = _.chain(reciepientList)
        .map(managerrecipient => {
          managerrecipient.fullName = User.prototype.fullName;
          return new MailAddress(
            managerrecipient.emailAddress,
            managerrecipient.fullName()
          );
        })
        .value();
    }

    params.redirectionUrl = this.navigator.convertUrlTreeToString([
      'management',
      'projects',
      projectGuid,
      'summary'
    ]);

    params.uploadedDocumentTypes = documentTypes;
    return this.sendEmail(params);

  }
}


import { Days } from './days.model';
import { Expenditures } from './expenditures.model';
import { Jobs } from './jobs.model';
import { Principal } from './principal.model';
import { RelatedTransaction } from './related-transactions.model';
import { ProductionDetails } from './production-details.model';
import { incentiveProgram } from '../../../project/project.constants';
import { formTypes } from '../../../form/form.constants';
import { CreditType } from '../creditType.model';
import { getAllCreditTypes } from '../../../entertainment/credit.functions';
import { EstimatedDates } from './estimatedDates.model';
import { AuditDepositCreator } from '../../interfaces/audit-deposit-creator.interface';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import { EntertainmentForm } from '../entertainment-form.model';
export class Application extends EntertainmentForm
  implements AuditDepositCreator {
  // This is the computed formId when the application is first loaded. The state is preserved for subsequent changes.
  relatedAuditFormId: FormIdentifier;
  careerLearningType: string;
  isVisualEffectsinLA: boolean;
  isVisualEffectsQualifiedCompany: boolean;
  visualEffectsCompanyName: string;
  estimatedCreditTypes: CreditType[];
  estimatedDays: Days;
  expenditures: Expenditures;
  estimatedJobs: Jobs;
  hasRelatedPartyTransactions: string;
  logoRequirementType: string;
  otherLogoReqDescription: string;
  otherCareerLearningDescription: string;
  parentCompanyName: string;
  productionDetails: ProductionDetails;
  principals: Principal[];
  estimatedDates: EstimatedDates;
  relatedTransactions: RelatedTransaction[];
  totalCreditsApplied?: number;
  constructor() {
    super();
  }

  /**
   * @summary Get the Film application computed Audit Form Id
   */
  get auditFormId() {
    return this.relatedAuditFormId;
  }

  set auditFormId(value) {
    this.relatedAuditFormId = value;
  }

  /**
   * @summary Get the applicable LA Expenditure total for calculating the deposit.
   */
  get laExpenditure(): number {
    return this.expenditures.laExpenditure;
  }

  /**
   * @summary Set the applicable LA Expenditure total for calculating the deposit.
   */
  set laExpenditure(value: number) {
    this.expenditures.laExpenditure = value;
  }

  init(): Application {
    super.init();
    this.type = formTypes.application.abbrev;
    this.careerLearningType = this.careerLearningType || null;
    this.visualEffectsCompanyName = this.visualEffectsCompanyName || null;
    this.estimatedCreditTypes =
      this.estimatedCreditTypes ||
      getAllCreditTypes(this, incentiveProgram.film.code, this.receivedDate);
    this.estimatedDays = this.estimatedDays || new Days();
    this.expenditures = this.expenditures || new Expenditures();
    this.estimatedJobs = this.estimatedJobs || new Jobs();
    this.hasRelatedPartyTransactions = this.hasRelatedPartyTransactions || null;
    this.logoRequirementType = this.logoRequirementType || null;
    this.parentCompanyName = this.parentCompanyName || null;
    this.productionDetails = this.productionDetails || new ProductionDetails();
    this.principals = this.principals || [];
    this.estimatedDates = this.estimatedDates || new EstimatedDates();
    this.relatedTransactions = this.relatedTransactions || [];
    this.totalCreditsApplied =
      this.totalCreditsApplied >= 0 ? this.totalCreditsApplied : null;
    this.otherLogoReqDescription = this.otherLogoReqDescription || null;
    this.otherCareerLearningDescription =
      this.otherCareerLearningDescription || null;
    return this;
  }

  get qualifier() {
    if (this.productionDetails.season) {
      return `Season : ${this.productionDetails.season}`;
    }
    return null;
  }
}

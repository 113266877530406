import { AuthorizationToken } from '../models/authorization-token.model';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable, ApplicationRef, OnDestroy } from '@angular/core';
import { Observable, throwError, Subscription, asyncScheduler, of } from 'rxjs';
import { map, catchError, subscribeOn, mergeMap, filter } from 'rxjs/operators';
import { UserContextService } from '../../user/services/user-context.service';
import { User } from '../../user/models/user.model';
import { userType } from '../../user/user.constants';
import { AuthorizationService } from './authorization.service';
import { UserPermissions } from '../../user/models/user-permissions.model';
import { chain } from 'underscore';

const autoTokenExtendInterval = 3600000; // 1 hr intervals
const extendUrl = environment.apiUrl + '/authentication/token/extend';
const tokenUrl = environment.apiUrl + '/authentication/token';
const guidTokenUrl = environment.apiUrl + '/guest/token';

@Injectable()
export class AuthenticationService implements OnDestroy {
  // This will be set whenever the user reaches a url that requires authentication and possesses no access token
  public redirectUrl: string;
  public get isUserLoggedIn(): boolean {
    return this.token == null || this.token === undefined
      ? false
      : new Date(this.token.payload.expiration * 1000) > new Date();
  }
  public token: AuthorizationToken = null;
  private tokenExtendTimer: any = null;
  public additionalUserContextSubscription: Subscription;
  private subs: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private userContext: UserContextService,
    private authorizationService: AuthorizationService,
    private appRef: ApplicationRef
  ) {
    this.setupAutomaticTokenReissue();
  }

  // TODO: Create model for the return of the api and change <any> to the new type
  // TODO: Map the response value to return the new model
  public getTokenFromGuid(guid: string): Observable<any> {
    return this.http
      .get(`${guidTokenUrl}/${guid}`, this.getAuthOptions())
      .pipe(
        map((res: any) => {
          return new AuthorizationToken({ access_token: res });
        })
      )
      .pipe(catchError(this.handleError));
  }

  getAuthOptions(contentType?: string, contentEncoding?: string) {
    // no token -> no options
    if (!this.token) {
      return { headers: null };
    }

    // add authorization header with jwt token
    const headers = { ['Authorization']: this.getAuthHeaderValue() };
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    if (contentEncoding) {
      headers['Content-Encoding'] = contentEncoding;
    }

    return { headers: headers };
  }

  getAuthOptionsForHttpClient(contentType?: string, contentEncoding?: string) {
    // no token -> no options
    if (!this.token) {
      return null;
    }

    // add authorization header with jwt token
    const headers = { ['Authorization']: this.getAuthHeaderValue() };
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    if (contentEncoding) {
      headers['Content-Encoding'] = contentEncoding;
    }

    return { headers: headers };
  }

  getUserId(): string {
    return this.token.payload.username;
  }

  getUserGuid(): string {
    return this.token.payload.userGuid;
  }

  getUserRole(): string {
    return this.token.payload.role;
  }

  getUserEmail(): string {
    return this.token.payload.username;
  }

  login(username: string, password: string): Observable<boolean> {
    const that = this;
    // construct header and options
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = { headers: headers };

    // construct body -- body required to be url encoded because of content type
    const body = {
      username: username,
      password: password,
      grantType: 'password'
    };

    // call api
    return this.http.post(tokenUrl, body, options).pipe(
      subscribeOn(asyncScheduler),
      map((response: HttpResponse<AuthorizationToken>) => {
        // login successful if there's a jwt token in the response
        const token = response;
        if (token) {
          // set token property
          that.setToken(new AuthorizationToken(token));
          // start automatic token extend
          that.setupAutomaticTokenReissue();

          // Set user context
          this.setUserContext();

          if (!that.redirectUrl || that.redirectUrl == null) {
            // Set Redirect Url based on the user role
            const userTypeConst = chain(userType)
              .pairs()
              .map(pair => pair[1])
              .find(value => value.code === that.token.payload.role)
              .value();

            if (userTypeConst) {
              that.redirectUrl = `/${userTypeConst.route}`;
            }

            // if (that.token.payload.role === userType.applicant.code) {
            //   that.redirectUrl = '/applicant';
            // } else if (that.token.payload.role === userType.board.code) {
            //   that.redirectUrl = '/board';
            // } else if (that.token.payload.role === userType.external.code) {
            //   that.redirectUrl = '/external';
            // } else if (that.token.payload.role === userType.management.code) {
            //   that.redirectUrl = '/management';
            // } else if (that.token.payload.role === userType.assessor.code) {
            //   that.redirectUrl = '/assessor'; // routes have to created for assessor also the components
            // }
          }
          // successful login
          return true;
        }

        // failed login
        return false;
      })
    );
  }

  logout(): void {
    // clear timer if it has been set
    if (this.tokenExtendTimer) {
      clearInterval(this.tokenExtendTimer);
    }

    this.clearToken();

    // Reset user context to load the correct navigation menu for users that are not yet logged in
    this.userContext.resetContext();
  }

  /**
   * returns: true if succeeded in extending the token life, false if failed
   */
  reissueToken(): boolean {
    // return if no token to extend
    if (!this.token) {
      return false;
    }

    // return if current token is expired
    if (new Date().getUTCSeconds() > this.token.payload.expiration) {
      // clear token if expired
      this.clearToken();
      return false;
    }

    let didTokenExtend: boolean;

    // call api for new token
    this.http
      .get(extendUrl, this.getAuthOptions())
      .pipe(
        subscribeOn(asyncScheduler),
        map((response: AuthorizationToken) => {
          const newToken = response;
          if (newToken) {
            // update our token
            this.setToken(new AuthorizationToken(newToken));

            // successful token life extend
            return true;
          }

          // failed token life extend
          return false;
        })
      )
      .subscribe((value: boolean) => (didTokenExtend = value));

    return didTokenExtend;
  }

  userEmailUpdateToken(token: AuthorizationToken) {
    this.setToken(token);
    // Set user context
    this.setUserContext();
  }

  setupAutomaticTokenReissue(): void {
    const that = this;

    let requiresTokenReissue: boolean;

    // clear timer if it has been set
    if (this.tokenExtendTimer) {
      clearInterval(this.tokenExtendTimer);
    }

    // return if no token to extend
    const localToken = localStorage.getItem('token');
    if (!this.token) {
      requiresTokenReissue = true;

      if (!localToken) {
        return;
      }
    }

    // ensure token has value
    this.token = this.token || new AuthorizationToken(JSON.parse(localToken));

    // check if the user is guest and disable reissue of token
    if (this.token.payload.role === userType.guest.code) {
      return;
    }

    // Set user context
    this.setUserContext();

    // return and clear if current token is expired
    if (new Date().getUTCSeconds() > this.token.payload.expiration) {
      this.clearToken();
      return;
    }

    // reissue token if it was retrieved from local storage
    if (requiresTokenReissue) {
      this.reissueToken();
    }

    // set timer

    // Setup Automatic Token Reissue after the app has reached a stable point
    const sub = this.appRef.isStable
      .pipe(filter(isStable => isStable))
      .subscribe(() => {
        that.tokenExtendTimer = setInterval(
          () => that.reissueToken(),
          autoTokenExtendInterval
        );
      });
    this.subs.push(sub);
  }

  private clearToken(): void {
    this.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('guestToken');
  }

  public getAuthHeaderValue(): string {
    // no token -> no header
    if (!this.token) {
      return null;
    }

    return 'Bearer ' + this.token.access_token;
  }

  private handleError(error: HttpErrorResponse | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return throwError(errMsg);
  }

  setUserContext() {
    const email = this.getUserEmail();
    this.userContext.currentUser = new User();
    this.userContext.currentUser.emailAddress = email;
    this.userContext.currentUser.userType = this.getUserRole();
    this.userContext.currentUser.externalReviewAgency = this.getExternalAgency();
    this.userContext.currentUser.id = this.getUserGuid();

    // If user type is guest do not bother to get permissions as user does not exist
    // The permissionSubscription is set to ensure new codes subscribing or adding
    // new teardown logic will be executed as when the user type has permissions
    if (
      this.getUserRole() === userType.guest.code ||
      !!!this.userContext.currentUser.id
    ) {
      this.additionalUserContextSubscription = of(
        new UserPermissions()
      ).subscribe();
      return;
    }

    // User is not a guest so go get the permissions
    const that = this;
    const authOptions = this.getAuthOptions();
    const programsObservable = this.authorizationService.getUserPrograms(
      authOptions
    );
    const permissionsObservable = this.authorizationService.getUserPermissions(
      authOptions
    );

    this.additionalUserContextSubscription = programsObservable
      .pipe(
        mergeMap(programs => {
          if (programs) {
            that.userContext.currentUser.incentivePrograms = programs;
          }

          return permissionsObservable;
        })
      )
      .subscribe(
        permissions => {
          that.userContext.currentUser.permissions = permissions;
        },
        error => {
          // If user type is guest, proceed without redirection
          that.userContext.currentUser.permissions = new UserPermissions();
        }
      );
  }

  /**@summary This method will execute the call back code once the User Permissions are retrieved into UserContext.
   * @param fetchUserPermissionsCallBack
   */
  executeAfterUserPermissionsFetched(fetchUserPermissionsCallBack: () => void) {
    this.additionalUserContextSubscription.add(fetchUserPermissionsCallBack);
  }

  getExternalAgency(): string {
    return this.token.payload.subject;
  }

  public setToken(token: AuthorizationToken): void {
    if (token && token.payload) {
      this.token = token;
      localStorage.setItem('token', JSON.stringify(this.token));
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

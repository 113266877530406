import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { UserContextService } from '../../user/services/user-context.service';
import { getUserModule } from '../../user/user.functions';
import { FormIdentifier } from '../../project/models/form-identifier.model';
import { oneToManyFormTypes } from '../../form/form.constants';
import { getFormTypeName } from '../../form/form.functions';
import { userType, UserType } from '../../user/user.constants';

@Injectable()
export class NavService {
  constructor(
    public location: Location,
    public route: ActivatedRoute,
    public router: Router,
    public userContext: UserContextService
  ) {}

  back() {
    this.location.back();
  }

  /**
   * @summary Converts any given url to an absolute url which includes the location origin (hostname:port)
   * @param rawUrlTree Url tree to use
   * @param queryParams Any query parameters to use (Optional)
   */
  convertUrlTreeToString(rawUrlTree: string[], queryParams?: Params) {
    const urlTree = this.router.createUrlTree(rawUrlTree, {
      queryParams: queryParams
    });

    const urlString = this.router.serializeUrl(urlTree);
    return window.location.origin + this.location.prepareExternalUrl(urlString);
  }

  /**
   * @summary Converts any given url to an absolute url which includes the location origin (hostname:port).
   * It returns an object with a key for each user type with a url
   * @param rawUrlTree Url tree to use
   * @param userTypes The different user types for which to build urls
   * @param queryParams Any query parameters to use (Optional)
   */
  convertUrlTreeToStringsPerUserType(rawUrlTree: string[], userTypes: UserType[], queryParams?: Params) {
    // Empty URL object
    const constructedUrls = {};

    // Iterate through each userType provided
    const urlsObject = userTypes.forEach(u => {
      // Use spread to join together userType with rest of url
      const urlTree = this.router.createUrlTree([u.route, ...rawUrlTree], {
        queryParams: queryParams
      });

      const urlString = this.router.serializeUrl(urlTree);

      constructedUrls[u.name] = window.location.origin + this.location.prepareExternalUrl(urlString);
    });

    return constructedUrls;
  }

  /**
   * @summary Converts any given url tree to a base url string. This string does not contain any hostname or port information.
   * It is relative to the root /
   * @param rawUrlTree Url tree to use
   * @param queryParams Any query parameters to use (Optional)
   */
  convertUrlTreeToRootString(rawUrlTree: string[], queryParams?: Params) {
    const urlTree = this.router.createUrlTree(rawUrlTree, {
      queryParams: queryParams
    });

    const urlString = this.router.serializeUrl(urlTree);
    return this.location.prepareExternalUrl(urlString);
  }

  forward() {
    this.location.forward();
  }

  getFormTypeElementForDatatable(formId: FormIdentifier) {
    const url = this.getFormUrlForCurrentUserContext(formId);

    const formName = getFormTypeName(formId.formType);

    const el = `<a href="${url}" title="View Form" data-toggle="tooltip" data-position="top">${formName}</a>`; // +
    // `<a href="${url}" title="Open Form in New Tab" target="_blank" data-toggle="tooltip" data-placement="top">` +
    // `<i class="ml-2 fa fa-external-link"></i></a>`;

    return el;
  }

  getFormUrlForCurrentUserContext(
    formId: FormIdentifier,
    queryParams?: Params
  ) {
    const url = this.convertUrlTreeToString(
      oneToManyFormTypes.includes(formId.formType)
        ? [
            getUserModule(this.userContext.currentUser.userType),
            'projects',
            formId.projectGuid,
            `${formId.formIndex}`,
            formId.formType
          ]
        : [
            getUserModule(this.userContext.currentUser.userType),
            'projects',
            formId.projectGuid,
            formId.formType
          ],
      queryParams
    );

    return url;
  }

  getUserAgnosticUrlTreeForFormId(
    formId: FormIdentifier
  ) {

      const url = oneToManyFormTypes.includes(formId.formType)
        ? [
            'projects',
            formId.projectGuid,
            `${formId.formIndex}`,
            formId.formType
          ]
        : [
            'projects',
            formId.projectGuid,
            formId.formType
          ];

    return url;
  }

  /**
   * @summary Gets the Base URL to the given form. It includes the user type/module prefix such as /applicant or /management
   * @param formId The id of the form to generate a url for.
   * @param queryParams Any parameters to add to the url (Optional).
   */
  getFormUrlTreeForCurrentUserContext(
    formId: FormIdentifier,
    queryParams?: Params
  ) {
    const url = oneToManyFormTypes.includes(formId.formType)
      ? [
          getUserModule(this.userContext.currentUser.userType),
          'projects',
          formId.projectGuid,
          formId.formIndex.toString(),
          formId.formType
        ]
      : [
          getUserModule(this.userContext.currentUser.userType),
          'projects',
          formId.projectGuid,
          formId.formType
        ];

    return url;
  }

  navigateToUrlTree(url: any) {
    this.router.navigate(url);
  }

  getProjectIdElementForDatatable(projectId: string, projectObjectId: string) {
    const url = this.convertUrlTreeToString([
      getUserModule(this.userContext.currentUser.userType),
      'projects',
      projectObjectId,
      'summary'
    ]);

    const el = `<a href="${url}" title="View Project Summary" data-toggle="tooltip" data-placement="top">${projectId}</a>`; // +
    // `<a href="${url}" title="Open Project Summary in New Tab" target="_blank" data-toggle="tooltip" data-placement="top">` +
    // `<i class="ml-2 fa fa-external-link"></i></a>`;

    return el;
  }

  nav(path: string, option: 'relative' | 'absolute', queryParams?: any) {
    if (option === 'relative') {
      this.router.navigate([path], {
        relativeTo: this.route,
        queryParams: queryParams
      });
    } else {
      if (path) {
        this.router.navigate([path], { queryParams: queryParams });
      } else {
        return;
      }
    }
  }
  refresh() {
    const that = this;
    that.router.navigated = false;
    that.router.navigate([that.router.url]);
  }
  toHome() {
    this.nav(
      `/${getUserModule(this.userContext.currentUser.userType)}`,
      'absolute'
    );
  }

  toLogin() {
    this.nav('/login', 'absolute');
  }

  up() {
    this.nav('../', 'relative');
  }
}

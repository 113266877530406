import { FeeCalculations } from './models/fee-calculations.model';
import { FeeParameters } from './models/fee-parameters.model';
import { allEzAnticipatedBenefits } from '../incentive/incentive.constants';
import { legislationRuleNames, incentiveProgram } from '../project/project.constants';
import { formTypes } from '../form/form.constants';
import { BenefitParameters } from './models/benefit-parameters.model';
import { chain } from 'underscore';
import { isArray } from 'util';
function getFlatFeeCalcuations(projectId, amountPaid, flatFee) {
    // Nothing due if paid in full
    const amountDue = amountPaid >= flatFee
        ? 0 // No refunds!
        : flatFee - amountPaid; // Remaining amount due
    return new FeeCalculations({
        amountDue: amountDue,
        assessedFee: flatFee,
        amountPaid: amountPaid
    });
}
const ɵ0 = (projectId, application, amountPaid, params, estimatedBenefits) => {
    // Create the placeholder for all calculation variables
    const calculations = new FeeCalculations({
        feeParameters: params,
        estimatedBenefits: estimatedBenefits,
        amountPaid: amountPaid
    });
    // Get fee min and max
    const min = params.feeRange.min;
    const max = params.feeRange.max;
    // Calculate Assessed Fee
    let fee = calculations.estimatedBenefits * calculations.feeParameters.feeRate;
    // Adjust fee according to range
    fee = fee < min ? min : fee > max ? max : fee;
    // Commit Assessed Fee
    calculations.assessedFee = fee;
    // calculate amount paid and amount due
    calculations.amountPaid = amountPaid;
    calculations.amountDue =
        calculations.amountPaid > calculations.assessedFee
            ? 0
            : calculations.assessedFee - calculations.amountPaid;
    // Return all variables
    return calculations;
};
// !!!
// VERY IMPORTANT!! THE DATES MUST BE FROM MOST RECENT TO OLDEST ACROSS ALL ARRAYS IN THIS FILE
// ALL DATES IN THIS FILE MUST ADHERE TO THE 'yyyy-MM-dd' FORMAT
//
// ALL FORMTYPES AND INCENTIVE PROGRAMS SHOULD BE LOWERCASE
// TAKE EXTRA CAUTION WHEN EDITING THIS FILE
// !!!
export const feeFormulaSchedule = {
    dm: {
        application: [
            {
                feeFormula: ɵ0,
                effectiveDate: null
            }
        ],
        [formTypes.audit.abbrev]: [
            {
                feeFormula: (projectId, audit, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // apply fee rate
                    calculations.assessedFee = audit.fees[0].assessedFee;
                    // TODO: determine if the fees is refundable or not
                    calculations.isDepositAdjusted = audit.fees[0].isDepositAdjusted;
                    calculations.isRefundable = audit.fees[0].isRefundable;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        [formTypes.evr.abbrev]: [
            {
                feeFormula: (projectId, audit, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // apply fee rate
                    calculations.assessedFee = audit.fees[0].assessedFee;
                    // TODO: determine if the fees is refundable or not
                    calculations.isDepositAdjusted = audit.fees[0].isDepositAdjusted;
                    calculations.isRefundable = audit.fees[0].isRefundable;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ]
    },
    ez: {
        advance: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        afc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        application: [
            {
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    const totalNewJobs = application.estimatedJobs.new;
                    calculations.estimatedBenefits = estimatedBenefits;
                    // calculate Sales Tax Rebate
                    const capForAllJobs = params.capAmount
                        ? params.capAmount * totalNewJobs
                        : null;
                    const selectedBenefit = allEzAnticipatedBenefits.findIndex(function (el) {
                        return (el.name ===
                            application.salesTaxInvestmentTax.anticipatedBenefit.toString());
                    });
                    if (selectedBenefit === 0) {
                        calculations.estimatedRebates = 0;
                    }
                    else if (selectedBenefit === 1) {
                        const totalEstimatedRebate = (application.salesTaxInvestmentTax.estimatedStateRebateOrCredit ||
                            0) +
                            (application.salesTaxInvestmentTax.estimatedLocalRebate || 0);
                        if (capForAllJobs && totalEstimatedRebate >= capForAllJobs) {
                            calculations.estimatedRebates = capForAllJobs;
                        }
                        else {
                            calculations.estimatedRebates = totalEstimatedRebate;
                        }
                    }
                    else if (selectedBenefit === 2) {
                        if (capForAllJobs &&
                            application.salesTaxInvestmentTax.projectFacilityExpenseRebate >=
                                capForAllJobs) {
                            calculations.estimatedRebates = capForAllJobs;
                        }
                        else {
                            calculations.estimatedRebates =
                                application.salesTaxInvestmentTax.projectFacilityExpenseRebate;
                        }
                    }
                    else {
                        calculations.estimatedRebates = 0;
                    }
                    // apply fee rate
                    calculations.assessedFee =
                        (calculations.estimatedBenefits + calculations.estimatedRebates) *
                            params.feeRate;
                    // ensure assessed fee is within range
                    calculations.assessedFee =
                        calculations.assessedFee < params.feeRange.min
                            ? params.feeRange.min
                            : calculations.assessedFee > params.feeRange.max
                                ? params.feeRange.max
                                : calculations.assessedFee;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        bar: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, form.adjustmentAmount),
                effectiveDate: null
            }
        ],
        ccl: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccn: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        cao: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        cft: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ecr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        pcr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ext: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        req: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ]
    },
    film: {
        [formTypes.application.abbrev]: [
            {
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // apply fee rate
                    calculations.assessedFee =
                        application.totalCreditsApplied * params.feeRate;
                    // ensure assessed fee is within range
                    calculations.assessedFee =
                        calculations.assessedFee < params.feeRange.min
                            ? params.feeRange.min
                            : calculations.assessedFee > params.feeRange.max
                                ? params.feeRange.max
                                : calculations.assessedFee;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        [formTypes.asa.abbrev]: [
            {
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // apply fee rate
                    calculations.assessedFee =
                        application.totalCreditsApplied * params.feeRate;
                    // ensure assessed fee is within range
                    calculations.assessedFee =
                        calculations.assessedFee < params.feeRange.min
                            ? params.feeRange.min
                            : calculations.assessedFee > params.feeRange.max
                                ? params.feeRange.max
                                : calculations.assessedFee;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        [formTypes.initialCert.abbrev]: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        [formTypes.audit.abbrev]: [
            {
                feeFormula: (projectId, audit, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // apply fee rate
                    calculations.assessedFee = audit.fees[0].assessedFee;
                    // TODO: determine if the fees is refundable or not
                    calculations.isDepositAdjusted = audit.fees[0].isDepositAdjusted;
                    calculations.isRefundable = audit.fees[0].isRefundable;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        [formTypes.balanceAdjustmentRequest.abbrev]: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, form.adjustmentAmount),
                effectiveDate: null
            }
        ],
    },
    ite: {
        acr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        advance: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        afc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        ann: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        application: [
            {
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // set the estimated benefits
                    calculations.estimatedBenefits = estimatedBenefits;
                    // apply fee rate
                    calculations.assessedFee =
                        calculations.estimatedBenefits * params.feeRate;
                    // ensure assessed fee is within range
                    calculations.assessedFee =
                        calculations.assessedFee < params.feeRange.min
                            ? params.feeRange.min
                            : calculations.assessedFee > params.feeRange.max
                                ? params.feeRange.max
                                : calculations.assessedFee;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: new Date('2016-06-24')
            },
            {
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // set the estimated benefits
                    calculations.estimatedBenefits = estimatedBenefits;
                    // apply fee rate
                    calculations.assessedFee =
                        calculations.estimatedBenefits * params.feeRate;
                    // ensure assessed fee is within range
                    calculations.assessedFee =
                        calculations.assessedFee < params.feeRange.min
                            ? params.feeRange.min
                            : calculations.assessedFee > params.feeRange.max
                                ? params.feeRange.max
                                : calculations.assessedFee;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        bar: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, form.adjustmentAmount),
                effectiveDate: null
            }
        ],
        ccc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccl: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccn: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        cft: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        cpt: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        pcr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        rnw: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ext: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        req: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        coc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: null
            }
        ]
    },
    qj: {
        acr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        advance: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        afc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        application: [
            {
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // set the estimated benefits
                    calculations.estimatedBenefits = estimatedBenefits;
                    // calculate the rebates
                    calculations.estimatedRebates =
                        (application.salesTaxInvestmentTax.estimatedStateRebateOrCredit ||
                            0) +
                            (application.salesTaxInvestmentTax.estimatedLocalRebate || 0);
                    // apply fee rate
                    calculations.assessedFee =
                        (calculations.estimatedBenefits + calculations.estimatedRebates) *
                            params.feeRate;
                    // ensure assessed fee is within range
                    calculations.assessedFee =
                        calculations.assessedFee < params.feeRange.min
                            ? params.feeRange.min
                            : calculations.assessedFee > params.feeRange.max
                                ? params.feeRange.max
                                : calculations.assessedFee;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        bar: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, form.adjustmentAmount),
                effectiveDate: null
            }
        ],
        cao: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccl: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccn: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        cft: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ecr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        pcr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        rnw: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 50),
                effectiveDate: null
            }
        ],
        ext: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        req: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ]
    },
    rta: {
        advance: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        afc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 100),
                effectiveDate: null
            }
        ],
        application: [
            {
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    const calculations = new FeeCalculations({ feeParameters: params });
                    // set the esimated benefits
                    calculations.estimatedBenefits = estimatedBenefits;
                    // apply fee rate
                    calculations.assessedFee =
                        calculations.estimatedBenefits * params.feeRate;
                    // This is a exemption case for the RTA application when residential = yes, owner occupied = yes and rented or lease = no
                    if (application.rta.propertyUsage.isResidential &&
                        application.rta.propertyUsage.isOwnerOccupied &&
                        !application.rta.propertyUsage.isRented) {
                        // This is a special case according to Kate on 8/29/2018
                        params.feeRange.min = 0;
                    }
                    // ensure assessed fee is within range
                    calculations.assessedFee =
                        calculations.assessedFee < params.feeRange.min
                            ? params.feeRange.min
                            : calculations.assessedFee > params.feeRange.max
                                ? params.feeRange.max
                                : calculations.assessedFee;
                    // calculate amount paid and amount due
                    calculations.amountPaid = amountPaid;
                    calculations.amountDue =
                        calculations.amountPaid > calculations.assessedFee
                            ? 0
                            : calculations.assessedFee - calculations.amountPaid;
                    return calculations;
                },
                effectiveDate: null
            }
        ],
        bar: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, form.adjustmentAmount),
                effectiveDate: null
            }
        ],
        ccc: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ccn: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        cft: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        cpt: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ecr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        rnw: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        pcr: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 250),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        ext: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ],
        req: [
            {
                feeFormula: (projectId, form, amountPaid) => getFlatFeeCalcuations(projectId, amountPaid, 0),
                effectiveDate: null
            }
        ]
    },
    step: {
        stepapplication: [{
                feeFormula: (projectId, application, amountPaid, params, estimatedBenefits) => {
                    // Create the placeholder for all calculation variables
                    const calculations = new FeeCalculations({
                        feeParameters: params,
                        estimatedBenefits: estimatedBenefits,
                        amountPaid: amountPaid
                    });
                    // Commit Assessed Fee
                    calculations.assessedFee = 0;
                    // calculate amount paid and amount due
                    calculations.amountPaid = 0;
                    calculations.amountDue = 0;
                    // Return all variables
                    return calculations;
                },
                effectiveDate: null
            }]
    }
};
const ɵ1 = (projectId, application, params) => {
    // Get Expenditure Details from application
    const expenditures = application.estimatedExpenditures;
    const inState = expenditures.laborInStateTotal *
        (params.laborInState);
    const outOfState = expenditures.laborOutOfStateTotal * params.laborOutOfState;
    const nonLabor = expenditures.nonLaborTotal * params.nonLabor;
    return inState + outOfState + nonLabor;
}, ɵ2 = (projectId, application, params) => {
    const calculations = new FeeCalculations({
        benefitParameters: params
    });
    const totalNewJobs = application.estimatedJobs.new;
    if (application.ez.ezProjectDetails.isProjectLocatedInEnterpriseZone) {
        calculations.estimatedBenefits =
            totalNewJobs * params.jobTaxCredit1;
    }
    else {
        const nonReceivingJobs = totalNewJobs -
            application.ez.ezProjectDetails
                .countofEmployeesReceivingPublicAssistance;
        calculations.estimatedBenefits =
            application.ez.ezProjectDetails
                .countofEmployeesReceivingPublicAssistance *
                params.jobTaxCredit1 +
                nonReceivingJobs * params.jobTaxCredit2;
    }
    return calculations.estimatedBenefits;
}, ɵ3 = (projectId, application, params) => {
    const calculations = new FeeCalculations({
        benefitParameters: params
    });
    const totalNewJobs = application.estimatedJobs.new;
    calculations.estimatedBenefits = totalNewJobs * params.jobTaxCredit1;
    return calculations.estimatedBenefits;
};
export const benefitFormulaSchedule = {
    dm: {
        application: [
            {
                benefitFormula: ɵ1,
                effectiveDate: null,
                legislationRule: [
                    legislationRuleNames.dm.preJuly2015.name,
                    legislationRuleNames.dm.july2015.name,
                    legislationRuleNames.dm.july2018.name
                ]
            }
        ],
        audit: []
    },
    ez: {
        advance: [],
        afc: [],
        application: [
            {
                benefitFormula: ɵ2,
                effectiveDate: new Date('2016-04-01'),
                legislationRule: [legislationRuleNames.ez.postAct18_2016.name] // this is the latest rule
            },
            {
                benefitFormula: ɵ3,
                effectiveDate: null,
                legislationRule: [
                    legislationRuleNames.ez.postAct423_2014.name,
                    legislationRuleNames.ez.postAct423_2015.name,
                    legislationRuleNames.ez.preAct423.name
                ] // since for all the other rules the benefit calculation is same, we give null
            }
        ],
        ccn: [],
        cao: [],
        ccc: [],
        ecr: [],
        pcr: [],
        ext: [],
        req: []
    },
    film: {
        [formTypes.application.abbrev]: [],
        [formTypes.asa.abbrev]: [],
        [formTypes.initialCert.abbrev]: []
    },
    ite: {
        acr: [],
        advance: [],
        afc: [],
        application: [
            {
                benefitFormula: (projectId, application, params) => {
                    const calculations = new FeeCalculations({
                        benefitParameters: params
                    });
                    // calculate estimated benefit
                    const totalInvestment = application.estimatedInvestments.buildingAndMaterials +
                        application.estimatedInvestments.machineryAndEquipment +
                        application.estimatedInvestments.laborAndEngineering -
                        application.estimatedInvestments.restrictedAmount;
                    const annualExemption = totalInvestment *
                        params.assessmentPercentage *
                        application.millageRate; // new change as of 06/22/18
                    // capture the advalorem and annual advalorem
                    // application.ite.annualAdValorem = annualExemption;
                    calculations.estimatedBenefits =
                        +(annualExemption *
                            params.firstPhase *
                            params.firstPhaseRate).toFixed(2) +
                            +(annualExemption *
                                params.secondPhase *
                                params.secondPhaseRate).toFixed(2);
                    return calculations.estimatedBenefits;
                },
                effectiveDate: new Date('2018-04-25'),
                legislationRule: [legislationRuleNames.ite.postExeOrd_2018.name]
            },
            {
                benefitFormula: (projectId, application, params) => {
                    const calculations = new FeeCalculations({
                        benefitParameters: params
                    });
                    // calculate benefit rate
                    // params.benefitRate = calculations.feeParameters.benefitRate = 0.17145; // will change based on code table
                    // calculate estimated benefit
                    const totalInvestment = application.estimatedInvestments.buildingAndMaterials +
                        application.estimatedInvestments.machineryAndEquipment +
                        application.estimatedInvestments.laborAndEngineering -
                        application.estimatedInvestments.restrictedAmount;
                    const annualExemption = totalInvestment *
                        params.assessmentPercentage *
                        application.millageRate; // new change as of 06/22/18
                    // capture the advalorem and annual advalorem
                    // application.ite.annualAdValorem = annualExemption;
                    calculations.estimatedBenefits =
                        +(annualExemption *
                            params.firstPhase *
                            params.firstPhaseRate).toFixed(2) +
                            +(annualExemption *
                                params.secondPhase *
                                params.secondPhaseRate).toFixed(2);
                    return calculations.estimatedBenefits;
                },
                effectiveDate: new Date('2016-06-24'),
                legislationRule: [legislationRuleNames.ite.postExeOrd_2017.name]
            },
            {
                benefitFormula: (projectId, application, params) => {
                    const calculations = new FeeCalculations({
                        benefitParameters: params
                    });
                    // calculate benefit rate
                    // calculate estimated benefit
                    const totalInvestment = application.estimatedInvestments.buildingAndMaterials +
                        application.estimatedInvestments.machineryAndEquipment +
                        application.estimatedInvestments.laborAndEngineering -
                        application.estimatedInvestments.restrictedAmount;
                    // estimated benefit is same as total advalorem
                    calculations.estimatedBenefits =
                        totalInvestment *
                            params.assessmentPercentage *
                            application.millageRate *
                            10; // since its 10 year
                    return calculations.estimatedBenefits;
                },
                effectiveDate: null,
                legislationRule: [legislationRuleNames.ite.PreExeOrd.name]
            }
        ],
        ccn: [],
        ccc: [],
        cpt: [],
        cft: [],
        ccl: [],
        pcr: [],
        rnw: [],
        ext: [],
        req: []
    },
    qj: {
        acr: [],
        advance: [],
        afc: [],
        application: [
            {
                benefitFormula: (projectId, application, params) => {
                    const calculations = new FeeCalculations({
                        benefitParameters: params
                    });
                    // calculate estimated benefit
                    calculations.estimatedBenefits =
                        application.estimatedPayroll.new * params.benefitRate;
                    return calculations.estimatedBenefits;
                },
                effectiveDate: null,
                legislationRule: [
                    legislationRuleNames.qj.Act387.name,
                    legislationRuleNames.qj.PreAct387.name,
                    legislationRuleNames.qj.Act387_Act126Session.name,
                    legislationRuleNames.qj.Act126_Act386_2017Session.name
                ]
            }
        ],
        cao: [],
        ccn: [],
        ccl: [],
        ccc: [],
        cft: [],
        ecr: [],
        pcr: [],
        rnw: [],
        ext: [],
        req: []
    },
    rta: {
        advance: [],
        afc: [],
        application: [
            {
                benefitFormula: (projectId, application, params) => {
                    const calculations = new FeeCalculations({
                        benefitParameters: params
                    });
                    const totalInvestments = application.estimatedInvestments.buildingAndMaterials +
                        application.estimatedInvestments.laborAndEngineering +
                        application.estimatedInvestments.machineryAndEquipment;
                    // calculate benefit rate
                    const propertyUsage = application.rta.propertyUsage;
                    if (propertyUsage.isResidential &&
                        propertyUsage.isOwnerOccupied &&
                        !propertyUsage.isRented) {
                        calculations.benefitParameters.benefitRate = 0.1;
                    }
                    else {
                        calculations.benefitParameters.benefitRate = 0.15;
                    }
                    // calculate estimated benefit
                    calculations.estimatedBenefits =
                        totalInvestments *
                            calculations.benefitParameters.benefitRate *
                            application.millageRate *
                            params.yearsExempt;
                    return calculations.estimatedBenefits;
                },
                effectiveDate: null,
                legislationRule: [legislationRuleNames.rta.Const_7_21.name]
            }
        ],
        ccn: [],
        ccc: [],
        cpt: [],
        cft: [],
        ecr: [],
        rnw: [],
        pcr: [],
        ext: [],
        req: []
    },
    step: {
        application: []
    }
};
/**
 * @summary Establishes the fee parameters for any form given a date
 */
export const feeParameterSchedule = {
    dm: {
        [formTypes.application.abbrev]: [
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 0, min: 0 },
                    feeRate: 0.00
                }),
                effectiveDate: null
            }
        ]
    },
    ez: {
        [formTypes.application.abbrev]: [
            {
                feeParams: new FeeParameters({
                    capAmount: 100000,
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeParams: new FeeParameters({
                    capAmount: 100000,
                    feeRange: { max: 5000, min: 200 },
                    feeRate: 0.002
                }),
                effectiveDate: null
            }
        ]
    },
    film: {
        [formTypes.application.abbrev]: [
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 5000, min: 200 },
                    feeRate: 0.002
                }),
                effectiveDate: null
            }
        ],
        [formTypes.asa.abbrev]: [
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 5000, min: 200 },
                    feeRate: 0.002
                }),
                effectiveDate: null
            }
        ]
    },
    ite: {
        [formTypes.application.abbrev]: [
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2016-06-24')
            },
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 5000, min: 200 },
                    feeRate: 0.002
                }),
                effectiveDate: null
            }
        ]
    },
    qj: {
        [formTypes.application.abbrev]: [
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeParams: new FeeParameters({
                    feeRange: { max: 5000, min: 200 },
                    feeRate: 0.002
                }),
                effectiveDate: null
            }
        ]
    },
    rta: {
        [formTypes.application.abbrev]: [
            {
                feeParams: new FeeParameters({
                    yearsExempt: 5,
                    feeRange: { max: 15000, min: 500 },
                    feeRate: 0.005
                }),
                effectiveDate: new Date('2015-07-01')
            },
            {
                feeParams: new FeeParameters({
                    yearsExempt: 5,
                    feeRange: { max: 5000, min: 200 },
                    feeRate: 0.002
                }),
                effectiveDate: null
            }
        ]
    },
    step: {
        [formTypes.application.abbrev]: []
    }
};
export const benefitParameterSchedule = {
    dm: {
        application: [
            {
                benefitParams: new BenefitParameters({
                    laborInState: 0.25,
                    laborOutOfState: 0.18,
                    nonLabor: 0.18
                }),
                effectiveDate: new Date('2018-07-01'),
                legislationRule: [legislationRuleNames.dm.july2018.name]
            },
            {
                benefitParams: new BenefitParameters({
                    laborInState: 0.252,
                    laborOutOfState: 0.18,
                    nonLabor: 0.18
                }),
                effectiveDate: new Date('2015-07-01'),
                legislationRule: [legislationRuleNames.dm.july2015.name]
            },
            {
                benefitParams: new BenefitParameters({
                    laborInState: 0.35,
                    laborOutOfState: 0.25,
                    nonLabor: 0.25
                }),
                effectiveDate: null,
                legislationRule: [legislationRuleNames.dm.preJuly2015.name]
            }
        ]
    },
    ez: {
        application: [
            {
                benefitParams: new BenefitParameters({
                    jobTaxCredit1: 3500,
                    jobTaxCredit2: 1000
                }),
                effectiveDate: new Date('2016-04-01'),
                legislationRule: [legislationRuleNames.ez.postAct18_2016.name] // this is the latest rule
            },
            {
                benefitParams: new BenefitParameters({
                    jobTaxCredit1: 2500,
                    jobTaxCredit2: 0
                }),
                effectiveDate: null,
                legislationRule: [
                    legislationRuleNames.ez.postAct423_2014.name,
                    legislationRuleNames.ez.postAct423_2015.name,
                    legislationRuleNames.ez.preAct423.name
                ] // since for all the other rules the benefit calculation is same, we give null
            }
        ]
    },
    film: {},
    ite: {
        application: [
            {
                benefitParams: new BenefitParameters({
                    firstPhaseRate: 0.8,
                    secondPhaseRate: 0.8,
                    firstPhase: 5,
                    secondPhase: 5,
                    assessmentPercentage: 0.15
                }),
                effectiveDate: new Date('2018-04-25'),
                legislationRule: [legislationRuleNames.ite.postExeOrd_2018.name] // this is the latest rule
            },
            {
                benefitParams: new BenefitParameters({
                    firstPhaseRate: 1,
                    secondPhaseRate: 0.8,
                    firstPhase: 5,
                    secondPhase: 3,
                    assessmentPercentage: 0.15
                }),
                effectiveDate: new Date('2016-06-24'),
                legislationRule: [legislationRuleNames.ite.postExeOrd_2017.name]
            },
            {
                benefitParams: new BenefitParameters({
                    assessmentPercentage: 0.15
                }),
                effectiveDate: null,
                legislationRule: [legislationRuleNames.ite.PreExeOrd.name]
            }
        ]
    },
    qj: {
        application: [
            {
                benefitParams: new BenefitParameters({
                    benefitRate: 0.06
                }),
                effectiveDate: null,
                legislationRule: [
                    legislationRuleNames.qj.Act387.name,
                    legislationRuleNames.qj.PreAct387.name,
                    legislationRuleNames.qj.Act387_Act126Session.name,
                    legislationRuleNames.qj.Act126_Act386_2017Session.name
                ]
            }
        ]
    },
    rta: {
        application: [
            {
                benefitParams: new BenefitParameters({
                    yearsExempt: 5
                }),
                effectiveDate: null,
                legislationRule: [legislationRuleNames.rta.Const_7_21.name]
            }
        ]
    },
    step: {
        application: []
    }
};
// this is used for determining if we should collect fee formula parameters or not.
// flat fees don't need parameters, but non flat fees do
// this also will be beneficial if any forms ever go back and forth between calculated or flat fees which right now seems very unlikely
export const feeTypeSchedule = {
    dm: {
        application: [
            { isFlatFee: false, effectiveDate: new Date('2015-07-01') },
            { isFlatFee: false, effectiveDate: null }
        ],
        [formTypes.audit.abbrev]: [{ isFlatFee: false, effectiveDate: null }],
        [formTypes.evr.abbrev]: [{ isFlatFee: false, effectiveDate: null }]
    },
    ez: {
        advance: [{ isFlatFee: true, effectiveDate: null }],
        afc: [{ isFlatFee: true, effectiveDate: null }],
        application: [{ isFlatFee: false, effectiveDate: null }],
        bar: [{ isFlatFee: false, effectiveDate: null }],
        cao: [{ isFlatFee: true, effectiveDate: null }],
        ccc: [{ isFlatFee: true, effectiveDate: null }],
        ccl: [{ isFlatFee: true, effectiveDate: null }],
        ccn: [{ isFlatFee: true, effectiveDate: null }],
        cft: [{ isFlatFee: true, effectiveDate: null }],
        ecr: [{ isFlatFee: true, effectiveDate: null }],
        ext: [{ isFlatFee: true, effectiveDate: null }],
        pcr: [{ isFlatFee: true, effectiveDate: null }],
        req: [{ isFlatFee: true, effectiveDate: null }]
    },
    film: {
        [formTypes.application.abbrev]: [{ isFlatFee: false, effectiveDate: null }],
        [formTypes.asa.abbrev]: [{ isFlatFee: false, effectiveDate: null }],
        [formTypes.initialCert.abbrev]: [{ isFlatFee: true, effectiveDate: null }],
        [formTypes.audit.abbrev]: [{ isFlatFee: false, effectiveDate: null }],
        [formTypes.balanceAdjustmentRequest.abbrev]: [{ isFlatFee: false, effectiveDate: null }],
    },
    ite: {
        acr: [{ isFlatFee: true, effectiveDate: null }],
        advance: [{ isFlatFee: true, effectiveDate: null }],
        afc: [{ isFlatFee: true, effectiveDate: null }],
        ann: [{ isFlatFee: true, effectiveDate: null }],
        application: [{ isFlatFee: false, effectiveDate: null }],
        bar: [{ isFlatFee: false, effectiveDate: null }],
        ccc: [{ isFlatFee: true, effectiveDate: null }],
        ccn: [{ isFlatFee: true, effectiveDate: null }],
        cft: [{ isFlatFee: true, effectiveDate: null }],
        cpt: [{ isFlatFee: true, effectiveDate: null }],
        ccl: [{ isFlatFee: true, effectiveDate: null }],
        ext: [{ isFlatFee: true, effectiveDate: null }],
        pcr: [{ isFlatFee: true, effectiveDate: null }],
        req: [{ isFlatFee: true, effectiveDate: null }],
        rnw: [{ isFlatFee: true, effectiveDate: null }],
        coc: [{ isFlatFee: true, effectiveDate: null }]
    },
    qj: {
        acr: [{ isFlatFee: true, effectiveDate: null }],
        advance: [{ isFlatFee: true, effectiveDate: null }],
        afc: [{ isFlatFee: true, effectiveDate: null }],
        application: [{ isFlatFee: false, effectiveDate: null }],
        bar: [{ isFlatFee: false, effectiveDate: null }],
        cao: [{ isFlatFee: true, effectiveDate: null }],
        ccc: [{ isFlatFee: true, effectiveDate: null }],
        ccn: [{ isFlatFee: true, effectiveDate: null }],
        ccl: [{ isFlatFee: true, effectiveDate: null }],
        cft: [{ isFlatFee: true, effectiveDate: null }],
        ecr: [{ isFlatFee: true, effectiveDate: null }],
        ext: [{ isFlatFee: true, effectiveDate: null }],
        pcr: [{ isFlatFee: true, effectiveDate: null }],
        req: [{ isFlatFee: true, effectiveDate: null }],
        rnw: [{ isFlatFee: true, effectiveDate: null }]
    },
    rta: {
        advance: [{ isFlatFee: true, effectiveDate: null }],
        afc: [{ isFlatFee: true, effectiveDate: null }],
        application: [{ isFlatFee: false, effectiveDate: null }],
        bar: [{ isFlatFee: false, effectiveDate: null }],
        ccc: [{ isFlatFee: true, effectiveDate: null }],
        ccn: [{ isFlatFee: true, effectiveDate: null }],
        cft: [{ isFlatFee: true, effectiveDate: null }],
        cpt: [{ isFlatFee: true, effectiveDate: null }],
        ecr: [{ isFlatFee: true, effectiveDate: null }],
        ext: [{ isFlatFee: true, effectiveDate: null }],
        pcr: [{ isFlatFee: true, effectiveDate: null }],
        req: [{ isFlatFee: true, effectiveDate: null }],
        rnw: [{ isFlatFee: true, effectiveDate: null }]
    },
    step: {
        stepapplication: [],
    }
};
// This constant enum should be used whenever reference to a payment method needs to be used.
export const PaymentMethod = {
    CreditCard: 'Credit Card',
    ECheck: 'eCheck'
};
export var PayPointReturnCode;
(function (PayPointReturnCode) {
    PayPointReturnCode[PayPointReturnCode["Success"] = 2] = "Success";
    PayPointReturnCode[PayPointReturnCode["TechnicalDifficultyError"] = 4] = "TechnicalDifficultyError";
    PayPointReturnCode[PayPointReturnCode["Declined"] = 5] = "Declined";
    PayPointReturnCode[PayPointReturnCode["VerificationFailed"] = 6] = "VerificationFailed";
    PayPointReturnCode[PayPointReturnCode["CommunicationError"] = 7] = "CommunicationError";
    PayPointReturnCode[PayPointReturnCode["UnacceptedCardType"] = 13] = "UnacceptedCardType";
})(PayPointReturnCode || (PayPointReturnCode = {}));
export const feeTypePayment = [
    'ADVANCE',
    'AFC',
    'ACR',
    'APPLICATION',
    'ASA',
    'PCR',
    'ECR',
    'RNW',
    'CCL',
    'CCN',
    'CFT',
    'CPT',
    'CAO',
    'CCC',
    'EXT',
    'EVR'
];
export const feeTypes = {
    advance: {
        name: 'Advance',
        order: 0,
        lowerAbbrev: 'advance',
        upperAbbrev: 'ADVANCE'
    },
    afc: {
        name: 'AffidavitFinalCost',
        order: 1,
        lowerAbbrev: 'afc',
        upperAbbrev: 'AFC'
    },
    acr: {
        name: 'AnnualCertificationReport',
        order: 2,
        lowerAbbrev: 'acr',
        upperAbbrev: 'ACR'
    },
    acrite: {
        name: 'AnnualComplianceReport',
        order: 3,
        lowerAbbrev: 'acrite',
        upperAbbrev: 'ACRITE'
    },
    application: {
        name: 'Application',
        order: 4,
        lowerAbbrev: 'application',
        upperAbbrev: 'APPLICATION'
    },
    pcr: {
        name: 'ProjectCompletionReport',
        order: 5,
        lowerAbbrev: 'pcr',
        upperAbbrev: 'PCR'
    },
    ecr: {
        name: 'EmployeeCertificationReport',
        order: 6,
        lowerAbbrev: 'ecr',
        upperAbbrev: 'ECR'
    },
    rnw: {
        name: 'RenewalApplication',
        order: 7,
        lowerAbbrev: 'rnw',
        upperAbbrev: 'RNW'
    },
    ccl: {
        name: 'ContractChangeLocation',
        order: 8,
        lowerAbbrev: 'ccl',
        upperAbbrev: 'CCL'
    },
    ccn: {
        name: 'ContractChangeName',
        order: 9,
        lowerAbbrev: 'ccn',
        upperAbbrev: 'CCN'
    },
    cft: {
        name: 'ContractChangeFullTransfer',
        order: 10,
        lowerAbbrev: 'cft',
        upperAbbrev: 'CFT'
    },
    cpt: {
        name: 'ContractChangePartialTransfer',
        order: 11,
        lowerAbbrev: 'cpt',
        upperAbbrev: 'CPT'
    },
    cao: {
        name: 'ContractChangeOwnership',
        order: 12,
        lowerAbbrev: 'cao',
        upperAbbrev: 'CAO'
    },
    ccc: {
        name: 'ContractChangeClosure',
        order: 13,
        lowerAbbrev: 'ccc',
        upperAbbrev: 'CCC'
    },
    ext: {
        name: 'ProjectExtensionRequest',
        order: 14,
        lowerAbbrev: 'ext',
        upperAbbrev: 'EXT'
    },
    [formTypes.asa.abbrev]: {
        name: formTypes.asa.name,
        order: 15,
        lowerAbbrev: formTypes.asa.abbrev.toLowerCase(),
        upperAbbrev: formTypes.asa.abbrev.toUpperCase()
    },
    [formTypes.audit.abbrev]: {
        name: formTypes.audit.name,
        order: 16,
        lowerAbbrev: formTypes.audit.abbrev.toLowerCase(),
        upperAbbrev: formTypes.audit.abbrev.toUpperCase()
    },
    auditorPayment: {
        name: 'Auditor Payment',
        order: 17,
        lowerAbbrev: 'auditorpayment',
        upperAbbrev: 'AUDITOR PAYMENT'
    },
    evr: {
        name: 'Expenditure Verification Report',
        order: 18,
        lowerAbbrev: 'evr',
        upperAbbrev: 'EVR'
    }
};
/**
 * @summary Determines whether value provided is within the given range of numbers
 * @param range Range to use for comparison
 * @param value Value to use for comparison
 */
export function isValueWithinRange(range, value) {
    // Make sure range is not null
    if (!range || !isArray(range)) {
        return false;
    }
    // Make sure range is two values
    if (range.length === 2) {
        return range[0] <= value && range[1] >= value;
    }
    else {
        return range[0] <= value;
    }
}
const ɵ4 = pair => pair[1].code;
export const Programs = chain(incentiveProgram)
    .pairs()
    .map(ɵ4)
    .value();
/**
 * @param programType The program to calculate the deposit for
 * @param laExpenditure The amount of money that the production will spend in the state
 * @param legislationRule The legislation rule to help decide the right formula.
 * @summary Use this function to calculate the right assessed deposit amount.
 */
export function calculateAuditAssessedDeposit(programType, laExpenditure, legislationRule) {
    // Ensure laExpenditure is not null or undefined
    if (!laExpenditure) {
        return null;
    }
    // Get the deposit ranges for the legislation rule and program
    const depositRanges = getPopOverLegends[programType][legislationRule];
    // Find out the range to which the expenditure fits in
    try {
        const deposit = depositRanges.find(r => isValueWithinRange(r.range, laExpenditure));
        return deposit.deposit;
    }
    catch (e) {
        console.error(e);
    }
}
export const auditDepositCap = {
    [incentiveProgram.film.code]: {
        [legislationRuleNames.film.july2017.name]: 15000,
        [legislationRuleNames.film.july2015.name]: 15000,
        [legislationRuleNames.film.preJuly2015.name]: 0
    },
    [incentiveProgram.dm.code]: {
        [legislationRuleNames.dm.july2018.name]: 15000,
        [legislationRuleNames.dm.july2015.name]: 15000,
        [legislationRuleNames.dm.preJuly2015.name]: 0
    }
};
export const outgoingPaymentStatuses = {
    notPaid: {
        name: 'Not Paid'
    },
    paid: {
        name: 'Paid'
    },
    cancelled: {
        name: 'Cancelled'
    }
};
export const getPopOverLegends = {
    [incentiveProgram.film.code]: {
        [legislationRuleNames.film.july2017.name]: [
            { range: [0, 299999], deposit: 5000 },
            { range: [300000, 25000000], deposit: 7500 },
            { range: [25000001], deposit: 15000 }
        ],
        [legislationRuleNames.film.july2015.name]: [
            { range: [0, 299999], deposit: 5000 },
            { range: [300000, 25000000], deposit: 7500 },
            { range: [25000001], deposit: 15000 }
        ],
        [legislationRuleNames.film.preJuly2015.name]: [
            { range: [0, 299999], deposit: 0 },
            { range: [300000, 25000000], deposit: 0 },
            { range: [25000001], deposit: 0 }
        ]
    },
    [incentiveProgram.dm.code]: {
        [legislationRuleNames.dm.july2018.name]: [
            { range: [0, 1000000], deposit: 7500 },
            { range: [1000001], deposit: 15000 }
        ],
        [legislationRuleNames.dm.july2015.name]: [
            { range: [0, 1000000], deposit: 7500 },
            { range: [1000001], deposit: 15000 }
        ],
        [legislationRuleNames.dm.preJuly2015.name]: [
            { range: [0, 1000000], deposit: 0 },
            { range: [1000001], deposit: 0 }
        ]
    }
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };

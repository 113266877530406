export class PropertyUsage {
  currentUse: string;
  censusTract: string;
  districtType: string;
  grossSquareFootageAfterProject: number;
  grossSquareFootageBeforeProject: number;
  historicDistrictName: string;
  isEligibleForFederalTaxIncentives: boolean;
  isListedOnNationalRegister: boolean;
  isOwnerOccupied: boolean;
  isRented: boolean;
  isResidential: boolean;
  legalDescription: string;
  proposedUse: string;
  yearBuilt: number;
  yearListed: number;

  constructor() { }
}
import { incentiveProgram } from '../project/project.constants';
import { formTypes } from '../form/form.constants';
import { userType, managementPermissionLabels } from '../user/user.constants';
import { Application as FilmApplication } from '../entertainment/models/film/film-application.model';
import { Application as DigitalApplication } from '../entertainment/models/digital/application.model';
import { ProjectForm } from '../project/models/form.model';
import { Audit as FilmAudit } from '../entertainment/models/film/audit.model';
import { Application } from '../project/models/application.model';
import { CertificiateComplianceForm } from '../incentive/compliance/models/certificate-of-compliance.model';
import { Days } from '../entertainment/models/film/days.model';
import { StatusLog } from '../project/models/status-log.model';
import { StepApplication } from '../step/models/step/step-application.model';
declare var moment: any;

export const attachmentType = {
  annualCertification: 'Annual Certification (ACR)',
  annualCertificationAudit: 'Annual Certification Audit',
  annualReportSpreadsheetPreAct387: 'Annual Report Spreadsheet (Pre-Act 387)',
  annualReportSpreadsheetAct387: 'Annual Report Spreadsheet (Act 387)',
  annualReportSpreadsheetConditional:
    'Annual Report Spreadsheet (contract with advance notifications after ' +
    'July 1, 2015 and an annual certification due on or before June 30, 2018)',
  annualReportSpreadsheetAct386: 'Annual Report Spreadsheet (Act 386)',
  assessorStatement: `Assessor's Statement`,
  baselineSpreadsheet: 'Baseline Spreadsheet',
  boardAgenda: 'Board Agenda',
  boardAgendaSummary: 'Board Agenda Summary',
  breakdownOfPurchases: 'Breakdown of Purchases',
  buildingPermits: 'Building Permits',
  businessLocationsAndDates: 'Business Locations and Dates',
  certConstructionCompleted:
    'Certification Statement of Construction Completed',
  certifiedHistoricStructure: 'Certified Historic Structure',
  certOfPrimaryQualificationPreAct387:
    'Certification of Primary Qualification (Pre-Act 387)',
  certOfPrimaryQualificationAct387:
    'Certification of Primary Qualification (Act 387)',
  certOfPrimaryQualificationAct386:
    'Certification of Primary Qualification (Act 386)',
  corporateOrganizationalChart: 'Corporate Organizational Chart',
  correspondence: 'Correspondence',
  costReport: 'Cost Report',
  currentAssessedValueAndTaxesPaid: 'Current Assessed Value and Taxes Paid',
  descriptionOfNewJobs: 'Description of New Jobs',
  detailedJobDecsriptions: 'Detailed Job Descriptions',
  ecrWorksheet: 'ECR Worksheet',
  employeeCertificationAudit: 'Employee Certification Audit',
  es4: 'ES4',
  exhibitACea: 'Exhibit A - CEA',
  exhibitATaxLiabilities: 'Exhibit A - Proof of No Delinquent Tax Liabilities',
  exhibitBCivilLiabilities:
    'Exhibit B - Proof of No Delinquent Civil Liabilities',
  exhibitBSheriff: 'Exhibit B - Sheriff',
  exhibitBSchoolBoard: 'Exhibit B - School Board',
  exhibitBJury: 'Exhibit B - Parish/Police Jury',
  exhibitBMunicipality: 'Exhibit B - Municipality',
  exhibitCFelonyConviction:
    'Exhibit C - Company/Affiliates Proof of No Felony Conviction',
  headquarterServiceDescription: 'Headquarter Service Description',
  healthCareCertAct387: 'ACR-Health Care Certification Form (Act 387)',
  healthCareCertAct386: 'ACR-Health Care Certification Form (Act 386)',
  invoice: 'Invoice',
  initialBaselineCalculationWorksheet: 'Initial Baseline Calculation Worksheet',
  ldrNoObjectionLetter: 'LDR No Objection Letter',
  legalPropertyDescription: 'Legal Property Description',
  lgaBoardApprovalResolution: 'LGA Board Approval Resolution',
  lgaBoardDenialResolution: 'LGA Board Denial Resolution',
  lgaCertification: 'LGA Certification',
  lgaEndorsementResolution: 'LGA Endorsement Resolution',
  notarizedAffidavit: 'Notarized Affidavit',
  nonComplianceDocumentation: 'Non-Compliance Documentation',
  other: 'Other',
  ownerOccupiedMinimumRehabilitationCertification:
    'Owner Occupied - Minimum Rehabilitation Certification Statement',
  ownerOccupied2YearCompletionCertification:
    'Owner Occupied - 24 Month Completion Period Certification',
  owners: 'Owners',
  photosOfStructureBefore: 'Photos of Structure Before',
  plotMap: 'Plot Map',
  proofLaSecretaryOfState: 'Proof Louisiana Secretary of State',
  proofLaDeptOfRevenue: 'Proof Louisiana Department of Revenue',
  proofNumberNationwideEmployees:
    'Proof of Number of Nationwide Employees Including Affiliates',
  proofOfMillageRate: 'Proof of Millage Rate',
  proofOfOwnership: 'Proof of Ownership',
  preliminaryExpenditures: 'Preliminary Schedule of Expenditures',
  qualificationCertification: 'Qualification Certification',
  quarterlyEs4SutaReports: 'Quarterly wage reports (ES-4\'S/SUTA)',
  renderingOfStructureAfter: 'Rendering of Structure After',
  revisedCostReport: ' Revised Cost Report',
  resolution:
    'Resolution of Property Owners / Resolution of Board of Directors',
  salesTaxCertificate: 'Sales Tax Certificate',
  signedChangeAffiliatesOwners: 'Signed Change of Affiliates/Owners',
  signedChangeCompanyName: 'Signed Change of Company Name Only',
  signedChangeProjectPhysicalLocation:
    'Signed Change of Project Physical Location',
  signedContract: 'Signed Contract',
  signedDisclosureAuthorization: 'Signed Disclosure Authorization',
  signedFullTransfer: 'Signed Full Transfer of Ownership to New Company',
  signedPartialTransfer: 'Signed Partial Transfer of Ownership to New Company',
  signedRenewalContract: 'Signed Renewal Contract',
  taxInvoice: 'Tax Invoice',
  territoryServiced: 'Territory Serviced',
  detailedPreliminaryBudget: 'Detailed Preliminary Budget',
  detailedDistributionPlane: 'Detailed Distribution Plan',
  script: 'Script',
  notarizedStatement: 'Notarized statement',
  laSpendBudget: 'Louisiana Spend Budget',
  authourshipProof: 'Proof of Authorship',
  residencyDeclaration: 'Declaration of Residency',
  verificationReport: 'Verification Report',
  generalLedger: 'General Ledger',
  principalPhotography: 'Proof of Principal Photography',
  bibleRun: 'Bible Run',
  completeShootingSchedule: 'Complete Shooting Schedule',
  finalCrewList: 'Final Crew List',
  laEmploymentData: 'Louisiana Employment Data',
  productionStatement: 'State Certified Production Statement',
  synopsis: 'Synopsis',
  signedInitialCertLetter: 'Signed Initial Certification Letter',
  signedFinalCertLetter: 'Signed Final Certification Letter',
  estimateBudgetAltMarketingProposal:
    'Estimated Budget- Alternative Marketing Proposal',
  aboveLinePayments: 'Above Line Payments Louisiana Residents',
  laJobsBudgetBreakdown: 'Louisiana Resident Jobs- Budget Breakdown',
  signedRebateAffidavit: 'Signed Rebate Affidavit',
  intialApplicationQuestions: 'Initial Application Questions',
  emailCommunication: 'Email Communication',
  supportingDocumentation: 'Supporting Documentation',
  SOSRegistration: 'SOS Registration',
  finalScheduleExpenditures: 'Final Schedule of Expenditures',
  finalCertAttestation: 'Final Certification Attestation',
  rebateAffidavit: 'Rebate Affidavit',
  rebateRequest: 'Rebate Request',
  baselineCalculationWorksheet: 'Baseline Calculation Worksheet',
  annualComplianceReport: 'Annual Compliance Report',
  proofOfMillage: 'Proof of Millage',
  multiWorksite: 'Multi Worksite Reports',
  sbaForm1623: 'SBA Form 1623',
  selfRepresentation: 'SELF-REPRESENTATION',
  W9: 'W-9'
};

export const assessorAttachmentTypes = [
  attachmentType.breakdownOfPurchases,
  attachmentType.currentAssessedValueAndTaxesPaid,
  attachmentType.lgaCertification,
  attachmentType.legalPropertyDescription,
  attachmentType.resolution,
  attachmentType.buildingPermits,
  attachmentType.certifiedHistoricStructure,
  attachmentType.certConstructionCompleted,
  attachmentType.ownerOccupied2YearCompletionCertification,
  attachmentType.ownerOccupiedMinimumRehabilitationCertification,
  attachmentType.owners,
  attachmentType.plotMap,
  attachmentType.proofOfOwnership,
  attachmentType.renderingOfStructureAfter,
  attachmentType.photosOfStructureBefore,
  attachmentType.taxInvoice,
  attachmentType.proofOfMillageRate,
  attachmentType.signedContract,
  attachmentType.signedRenewalContract,
  attachmentType.exhibitBSheriff,
  attachmentType.exhibitBJury,
  attachmentType.exhibitBSchoolBoard,
  attachmentType.exhibitBMunicipality
];

export const defaultAttachmentTypes = [
  attachmentType.signedDisclosureAuthorization,
  attachmentType.other
];

const commonAttachmentTypeGroups = {
  ecr: [
    attachmentType.ecrWorksheet,
    attachmentType.employeeCertificationAudit,
    attachmentType.quarterlyEs4SutaReports,
    attachmentType.proofNumberNationwideEmployees,
    ...defaultAttachmentTypes
  ],
  laGovernmentProofs: [
    attachmentType.proofLaSecretaryOfState,
    attachmentType.proofLaDeptOfRevenue,
    ...defaultAttachmentTypes
  ],
  sharedProjectDocuments: [
    attachmentType.signedContract,
    attachmentType.signedChangeAffiliatesOwners,
    attachmentType.signedChangeCompanyName,
    attachmentType.signedChangeProjectPhysicalLocation,
    attachmentType.signedFullTransfer,
    attachmentType.signedPartialTransfer,
    attachmentType.proofLaDeptOfRevenue,
    attachmentType.proofLaSecretaryOfState,
    ...defaultAttachmentTypes
  ]
};

export const boardAttachmentTypes = [
  attachmentType.boardAgenda,
  attachmentType.boardAgendaSummary,
  attachmentType.other
];

export const formAttachmentTypes = {
  EZ: {
    advance: defaultAttachmentTypes,
    acr: [attachmentType.annualCertification, ...defaultAttachmentTypes],
    afc: defaultAttachmentTypes,
    application: [
      attachmentType.qualificationCertification,
      attachmentType.salesTaxCertificate,
      attachmentType.lgaEndorsementResolution,
      attachmentType.initialBaselineCalculationWorksheet,
      ...defaultAttachmentTypes
    ],
    bar: defaultAttachmentTypes,
    cao: defaultAttachmentTypes,
    ccc: defaultAttachmentTypes,
    ccn: commonAttachmentTypeGroups.laGovernmentProofs,
    cft: commonAttachmentTypeGroups.laGovernmentProofs,
    cpt: commonAttachmentTypeGroups.laGovernmentProofs,
    ecr: commonAttachmentTypeGroups.ecr,
    ext: defaultAttachmentTypes,
    pcr: defaultAttachmentTypes,
    req: defaultAttachmentTypes,
    rnw: defaultAttachmentTypes
  },
  ITE: {
    advance: defaultAttachmentTypes,
    afc: [attachmentType.breakdownOfPurchases, ...defaultAttachmentTypes],
    application: [
      attachmentType.breakdownOfPurchases,
      attachmentType.descriptionOfNewJobs,
      attachmentType.es4,
      attachmentType.exhibitACea,
      attachmentType.exhibitBSheriff,
      attachmentType.exhibitBSchoolBoard,
      attachmentType.exhibitBJury,
      attachmentType.exhibitBMunicipality,
      attachmentType.ldrNoObjectionLetter,
      attachmentType.notarizedAffidavit,
      attachmentType.proofOfMillageRate,
      attachmentType.baselineCalculationWorksheet,
      attachmentType.annualComplianceReport,
      attachmentType.proofOfMillage,
      ...defaultAttachmentTypes
    ],
    bar: defaultAttachmentTypes,
    cao: defaultAttachmentTypes,
    ccc: defaultAttachmentTypes,
    ccl: defaultAttachmentTypes,
    ccn: commonAttachmentTypeGroups.laGovernmentProofs,
    cft: commonAttachmentTypeGroups.laGovernmentProofs,
    cpt: commonAttachmentTypeGroups.laGovernmentProofs,
    ext: defaultAttachmentTypes,
    pcr: defaultAttachmentTypes,
    req: defaultAttachmentTypes,
    rnw: [
      attachmentType.assessorStatement,
      attachmentType.correspondence,
      attachmentType.exhibitATaxLiabilities,
      attachmentType.exhibitBCivilLiabilities,
      attachmentType.exhibitCFelonyConviction,
      attachmentType.notarizedAffidavit,
      attachmentType.proofOfMillageRate,
      attachmentType.signedDisclosureAuthorization
    ],
    coc: [
      attachmentType.baselineCalculationWorksheet,
      attachmentType.annualComplianceReport,
      attachmentType.quarterlyEs4SutaReports,
      attachmentType.multiWorksite,
      attachmentType.nonComplianceDocumentation
    ]
  },
  QJ: {
    acr: [
      attachmentType.annualCertification,
      attachmentType.annualCertificationAudit,
      attachmentType.annualReportSpreadsheetAct386,
      attachmentType.annualReportSpreadsheetPreAct387,
      attachmentType.annualReportSpreadsheetAct387,
      attachmentType.annualReportSpreadsheetConditional,
      attachmentType.baselineSpreadsheet,
      attachmentType.certOfPrimaryQualificationAct386,
      attachmentType.certOfPrimaryQualificationPreAct387,
      attachmentType.certOfPrimaryQualificationAct387,
      attachmentType.healthCareCertAct386,
      attachmentType.healthCareCertAct387,
      attachmentType.initialBaselineCalculationWorksheet,
      attachmentType.quarterlyEs4SutaReports,
      ...defaultAttachmentTypes
    ],
    advance: defaultAttachmentTypes,
    afc: defaultAttachmentTypes,
    application: [
      attachmentType.lgaEndorsementResolution,
      attachmentType.initialBaselineCalculationWorksheet,
      attachmentType.corporateOrganizationalChart,
      attachmentType.headquarterServiceDescription,
      attachmentType.businessLocationsAndDates,
      attachmentType.territoryServiced,
      ...defaultAttachmentTypes
    ],
    bar: defaultAttachmentTypes,
    cao: defaultAttachmentTypes,
    ccc: defaultAttachmentTypes,
    ccl: defaultAttachmentTypes,
    ccn: commonAttachmentTypeGroups.laGovernmentProofs,
    cft: commonAttachmentTypeGroups.laGovernmentProofs,
    cpt: commonAttachmentTypeGroups.laGovernmentProofs,
    ecr: commonAttachmentTypeGroups.ecr,
    ext: defaultAttachmentTypes,
    pcr: defaultAttachmentTypes,
    req: defaultAttachmentTypes,
    rnw: defaultAttachmentTypes
  },
  RTA: {
    advance: defaultAttachmentTypes,
    afc: defaultAttachmentTypes,
    application: [
      attachmentType.currentAssessedValueAndTaxesPaid, // 9
      attachmentType.lgaCertification,
      attachmentType.legalPropertyDescription, // 3
      attachmentType.resolution, // 1
      attachmentType.buildingPermits, // 5
      attachmentType.certifiedHistoricStructure, // 11
      attachmentType.certConstructionCompleted,
      attachmentType.ownerOccupiedMinimumRehabilitationCertification,
      attachmentType.ownerOccupied2YearCompletionCertification,
      attachmentType.owners, // 8
      attachmentType.plotMap, // 4
      attachmentType.proofOfOwnership, // 2
      attachmentType.renderingOfStructureAfter, // 7
      attachmentType.photosOfStructureBefore, // 6
      attachmentType.taxInvoice, // 10
      attachmentType.proofOfMillageRate, // 12
      ...defaultAttachmentTypes
    ],
    bar: defaultAttachmentTypes,
    cao: defaultAttachmentTypes,
    cft: commonAttachmentTypeGroups.laGovernmentProofs,
    cpt: commonAttachmentTypeGroups.laGovernmentProofs,
    ccn: commonAttachmentTypeGroups.laGovernmentProofs,
    ccc: defaultAttachmentTypes,
    ext: defaultAttachmentTypes,
    pcr: defaultAttachmentTypes,
    req: defaultAttachmentTypes,
    rnw: defaultAttachmentTypes
  },
  FILM: {
    [formTypes.application.abbrev]: [
      attachmentType.detailedPreliminaryBudget,
      attachmentType.detailedDistributionPlane,
      attachmentType.script,
      attachmentType.notarizedStatement,
      attachmentType.laSpendBudget,
      attachmentType.authourshipProof,
      attachmentType.residencyDeclaration,
      attachmentType.other
    ],
    [formTypes.asa.abbrev]: [
      attachmentType.detailedPreliminaryBudget,
      attachmentType.detailedDistributionPlane,
      attachmentType.script,
      attachmentType.notarizedStatement,
      attachmentType.laSpendBudget,
      attachmentType.authourshipProof,
      attachmentType.residencyDeclaration,
      attachmentType.other
    ],
    [formTypes.initialCert.abbrev]: [
      attachmentType.principalPhotography,
      attachmentType.other
    ],
    [formTypes.audit.abbrev]: [
      ...defaultAttachmentTypes,
      attachmentType.costReport,
      attachmentType.verificationReport,
      attachmentType.revisedCostReport,
      attachmentType.invoice,
      attachmentType.generalLedger
    ]
  },
  DM: {
    [formTypes.application.abbrev]: [
      attachmentType.detailedJobDecsriptions,
      attachmentType.preliminaryExpenditures,
      attachmentType.other
    ],
    [formTypes.asa.abbrev]: [],
    [formTypes.initialCert.abbrev]: [
      attachmentType.detailedJobDecsriptions,
      attachmentType.preliminaryExpenditures,
      attachmentType.other
    ],
    [formTypes.evr.abbrev]: [
      ...defaultAttachmentTypes,
      attachmentType.costReport,
      attachmentType.verificationReport,
      attachmentType.revisedCostReport,
      attachmentType.invoice,
      attachmentType.generalLedger
    ]
  },
  STEP: {
    [formTypes.stepApplication.abbrev]: [
      attachmentType.sbaForm1623,
      attachmentType.selfRepresentation,
      attachmentType.W9,
      attachmentType.other
    ]
  }
};

export const lgaAttachmentTypes = [
  attachmentType.lgaBoardApprovalResolution,
  attachmentType.lgaBoardDenialResolution
];

export const lgaAndAssessorHiddenAttachmentTypes = [
  attachmentType.annualCertification,
  attachmentType.annualCertificationAudit,
  attachmentType.annualReportSpreadsheetAct386,
  attachmentType.annualReportSpreadsheetAct387,
  attachmentType.annualReportSpreadsheetPreAct387,
  attachmentType.annualReportSpreadsheetConditional,
  attachmentType.ecrWorksheet,
  attachmentType.employeeCertificationAudit,
  attachmentType.proofNumberNationwideEmployees,
  attachmentType.quarterlyEs4SutaReports,
  attachmentType.baselineSpreadsheet,
  attachmentType.other
];
export const navigationData = {
  applicant: {
    headerImgAlt: 'FastLane Applicant',
    headerImgSrc: 'assets/images/Fastlane-Applicant-logo.svg',
    headerRouterLink: '/applicant',
    items: [
      {
        faIcon: 'fa fa-th-list',
        label: 'Project List',
        name: userType.applicant.code,
        routerLink: '/applicant/projects'
      },
      {
        faIcon: 'fa fa-plus-square',
        label: 'Add New Project',
        name: userType.applicant.code,
        routerLink: '/applicant/projects/create'
      },
      {
        faIcon: 'fa fa-user-circle',
        label: 'Request Project Access',
        name: userType.applicant.code,
        routerLink: '/applicant/projects/request-access'
      },
      {
        faIcon: 'fa fa-check-square-o',
        label: 'Document Checklist',
        name: userType.applicant.code,
        routerLink: '/applicant/document-checklist'
      },
      {
        faIcon: 'fa fa-question-circle',
        label: 'Help Center',
        name: userType.applicant.code,
        routerLink: '/applicant/help'
      }
    ]
  },
  assessor: {
    headerImgAlt: 'Fastlane Assessor',
    headerImgSrc: 'assets/images/FL_Assessor_logo.png',
    headerRouterLink: '/assessor',
    items: [
      {
        faIcon: 'fa fa-th-list',
        label: 'Project Search',
        name: userType.assessor.code,
        routerLink: '/assessor'
      },
      {
        faIcon: 'fa fa-question-circle',
        label: 'Help Center',
        name: userType.assessor.code,
        routerLink: '/assessor/help'
      },
      {
        faIcon: 'fa fa-sign-out',
        label: 'Log Out',
        name: userType.assessor.code,
        routerLink: '/login'
      }
    ]
  },
  auditor: {
    headerImgAlt: 'FastLane Auditor',
    headerImgSrc: 'assets/images/Fastlane-Applicant-logo.svg',
    headerRouterLink: '/auditor',
    items: [
      {
        faIcon: 'fa fa-th-list',
        label: 'Audit List',
        name: userType.auditor.code,
        routerLink: '/auditor/audits'
      }
    ]
  },
  board: {
    headerImgAlt: 'FastLane Board User',
    headerImgSrc: 'assets/images/FL_BR_logo.png'
  },
  externalReviewer: {
    headerImgAlt: 'External Reviewer',
    headerImgSrc: 'assets/images/FL_ER_logo.png',
    headerRouterLink: '/external',
    items: [
      {
        faIcon: 'fa fa-th-list',
        label: 'Project Search',
        name: 'external',
        routerLink: 'projects'
      },
      {
        faIcon: 'fa fa-th-list',
        label: 'External Review',
        name: 'external',
        routerLink: '/external'
      },
      {
        faIcon: 'fa fa-question-circle',
        label: 'Help Center',
        name: 'external',
        routerLink: '/external/help'
      },
      {
        faIcon: 'fa fa-sign-out',
        label: 'Log Out',
        name: 'external',
        routerLink: '/login'
      }
    ]
  },
  guest: {
    headerImgAlt: 'FastLane Guest User',
    headerImgSrc: 'assets/images/Fastlane-Applicant-logo.svg'
  },
  manager: {
    headerImgAlt: 'FastLane Management',
    headerImgSrc: 'assets/images/FL_MGMT_logo2.png',
    headerRouterLink: '/management',
    items: [
      {
        faIcon: 'fa fa-dashboard',
        label: 'Dashboard',
        name: 'biDashBoard',
        routerLink: '/management/dashboard'
      },
      {
        faIcon: 'fas fa-chart-line',
        label: managementPermissionLabels.entDashBoard,
        name: 'entDashBoard',
        routerLink: '/management/ent-dashboard'
      },
      {
        faIcon: 'fas fa-chart-bar',
        label: managementPermissionLabels.stepDashBoard,
        name: 'stepDashBoard',
        routerLink: '/management/step-dashboard'
      },
      {
        faIcon: 'fas fa-file-chart-line',
        label: managementPermissionLabels.filmCreditTracking,
        name: 'filmCreditTracking',
        routerLink: '/management/creditTracking'
      },
      {
        faIcon: 'fa fa-search',
        label: 'Search',
        name: 'search',
        submenu: {
          highlightRoute: '/management/search',
          id: 'searchMenu',
          items: [
            {
              label: 'Projects',
              routerLink: '/management/projects'
            },
            {
              label: 'Advance Notifications',
              routerLink: '/management/projects/search/advance/notifications'
            },
            {
              label: 'Board Approved Projects',
              routerLink: '/management/projects/search/program/details'
            },
            {
              label: 'Initial Certification',
              routerLink: '/management/projects/search/initialCertifications'
            }
          ]
        }
      },
      {
        faIcon: 'fa fa-bar-chart',
        label: 'Charts',
        name: 'viewCharts',
        submenu: {
          highlightRoute: '/management/charts',
          id: 'complianeMenu',
          items: [
            {
              label: 'Enterprise Zone',
              routerLink: '/management/charts/ez'
            },
            {
              label: 'Industrial Tax Exemption',
              routerLink: '/management/charts/ite'
            },
            {
              label: 'Quality Jobs',
              routerLink: '/management/charts/qj'
            },
            {
              label: 'Restoration Tax Abatement',
              routerLink: '/management/charts/rta'
            },
            {
              label: 'Film',
              routerLink: '/management/charts/film'
            },
            {
              label: 'Digital',
              routerLink: '/management/charts/digital'
            }
          ]
        }
      },
      // {
      //     faIcon: 'fa-folder',
      //     label: 'Reports',
      //     name: 'reports',
      //     routerLink: '/management/reports'
      // },
      {
        faIcon: 'fa fa-eye',
        label: 'Form Review',
        name: 'formReview',
        routerLink: '/management/forms'
      },
      // {
      //     faIcon: 'fa-certificate',
      //     label: 'Verification Reports',
      //     name: 'verificationReports',
      //     routerLink: '/management/verification'
      // },
      {
        faIcon: 'fa fa-arrow-circle-o-right',
        label: 'External Review',
        name: 'externalReview',
        submenu: {
          highlightRoute: '/management/external',
          id: 'externalReviewMenu',
          items: [
            {
              label: 'Send Forms to External Review',
              routerLink: '/management/external/send'
            },
            {
              label: 'Manage Forms in Review Process',
              routerLink: '/management/external/manage'
            }
          ]
        }
      },
      {
        faIcon: 'fa fa-gavel',
        label: 'Board',
        name: userType.board.code,
        submenu: {
          highlightRoute: '/management/search',
          id: 'boardMenu',
          items: [
            {
              label: 'Board Meetings',
              routerLink: '/management/board/meetings'
            },
            {
              label: 'Send Forms to Board Agenda',
              routerLink: '/management/board/send'
            },
            {
              label: 'Manage Board Agenda',
              routerLink: '/management/board/agenda'
            },
            {
              label: 'Manage Board Results',
              routerLink: '/management/board/results'
            }
          ]
        }
      },
      {
        faIcon: 'fa fa-pencil-square-o',
        label: 'Signatures',
        name: 'signatures',
        submenu: {
          highlightRoute: '/management/signatures',
          id: 'signaturesMenu',
          items: [
            {
              label: 'Send Forms for Signature',
              routerLink: '/management/signatures/send'
            },
            {
              label: 'Manage Forms in Process',
              routerLink: '/management/signatures/manage'
            }
          ]
        }
      },
      {
        faIcon: 'fa fa-money',
        label: 'Payments',
        name: 'payments',
        submenu: {
          highlightRoute: '/management/payments',
          id: 'paymentsMenu',
          items: [
            {
              label: 'Unclassified Payments',
              routerLink: '/management/payments/unclassified'
            },
            {
              label: 'Payments Search',
              routerLink: '/management/payments/search'
            },
            {
              label: 'Outgoing Payments',
              routerLink: '/management/payments/outgoing'
            }
          ]
        }
      },
      {
        faIcon: 'fa fa-cube',
        label: 'Project Access',
        name: 'projectAccess',
        routerLink: '/management/projects/assign-access'
      },
      {
        faIcon: 'fa fa-user-circle',
        label: 'User Accounts',
        name: 'userAccounts',
        submenu: {
          highlightRoute: '/management/accounts',
          id: 'userAccountsMenu',
          items: [
            {
              label: 'Manage General Users',
              routerLink: '/management/accounts/users'
            },
            {
              label: 'Manage Special Users',
              routerLink: '/management/accounts/vips'
            }
          ]
        }
      },
      {
        faIcon: 'fa fa-file',
        label: 'Public Reports',
        name: 'management',
        routerLink: '/management/reports'
      },
      {
        faIcon: 'fa fa-check-square-o',
        label: 'Document Checklist',
        name: 'management',
        routerLink: '/management/document-checklist'
      },
      {
        faIcon: 'fa fa-question-circle',
        label: 'Help Center',
        name: 'management',
        routerLink: '/management/help'
      },
      // {
      //     faIcon: 'fa-globe',
      //     label: 'System Settings',
      //     name: 'systemSettings',
      //     routerLink: '/management/settings'
      // },
      {
        faIcon: 'fa fa-wrench',
        label: managementPermissionLabels.lookupTables,
        name: 'lookupTables',
        routerLink: '/management/lookup'
      }
      // {
      //   faIcon: 'fa-percent',
      //   label: 'ITE Effective Tax Rates',
      //   name: 'iteTaxRates',
      //   routerLink: '/management/ite-tax-rates'
      // }
    ]
  },
  nullUser: {
    headerImgAlt: 'FastLane',
    headerImgSrc: 'assets/images/FL_logo_new.png',
    headerRouterLink: '',
    items: [
      {
        faIcon: 'fa fa-sign-in',
        label: 'Log In',
        name: 'nullUser',
        routerLink: '/login'
      },
      {
        faIcon: 'fa fa-user-circle-o',
        label: 'Register',
        name: 'nullUser',
        routerLink: '/register'
      },
      {
        faIcon: 'fa fa-file',
        label: 'Public Reports',
        name: 'nullUser',
        routerLink: '/public/reports'
      },
      {
        faIcon: 'fa fa-search',
        label: 'Public Search',
        name: 'nullUser',
        submenu: {
          highlightRoute: '/public/search/bi',
          id: 'searchMenu',
          items: [
            {
              label: 'Business Incentives Search',
              routerLink: '/public/search/bi'
            },
            {
              label: 'Entertainment Incentives Search',
              routerLink: '/public/search/ent'
            }
          ]
        }
      },
      {
        faIcon: 'fa fa-check-square-o',
        label: 'Document Checklist',
        name: userType.applicant.code,
        routerLink: '/document-checklist'
      },
      {
        faIcon: 'fa fa-question-circle',
        label: 'Help Center',
        name: userType.applicant.code,
        routerLink: '/help'
      }
    ]
  }
};

export const ngxDatePickerColorTheme = 'theme-default';

export const projectAttachmentTypes = {
  EZ: [
    ...commonAttachmentTypeGroups.sharedProjectDocuments,
    attachmentType.ecrWorksheet,
    attachmentType.quarterlyEs4SutaReports,
    attachmentType.proofNumberNationwideEmployees,
    attachmentType.qualificationCertification,
    attachmentType.salesTaxCertificate,
    attachmentType.lgaEndorsementResolution
  ],
  ITE: [
    ...commonAttachmentTypeGroups.sharedProjectDocuments,
    attachmentType.signedRenewalContract,
    attachmentType.breakdownOfPurchases,
    attachmentType.es4,
    attachmentType.notarizedAffidavit,
    attachmentType.exhibitACea,
    attachmentType.ldrNoObjectionLetter,
    attachmentType.exhibitBSheriff,
    attachmentType.exhibitBSchoolBoard,
    attachmentType.exhibitBJury,
    attachmentType.exhibitBMunicipality,
    attachmentType.descriptionOfNewJobs,
    attachmentType.exhibitATaxLiabilities,
    attachmentType.exhibitBCivilLiabilities,
    attachmentType.exhibitCFelonyConviction,
    attachmentType.baselineCalculationWorksheet,
    attachmentType.annualComplianceReport,
    attachmentType.quarterlyEs4SutaReports,
    attachmentType.multiWorksite,
    attachmentType.nonComplianceDocumentation
  ],
  RTA: [
    ...commonAttachmentTypeGroups.sharedProjectDocuments,
    attachmentType.signedRenewalContract,
    attachmentType.currentAssessedValueAndTaxesPaid,
    attachmentType.lgaCertification,
    attachmentType.legalPropertyDescription,
    attachmentType.resolution,
    attachmentType.buildingPermits,
    attachmentType.certifiedHistoricStructure,
    attachmentType.certConstructionCompleted,
    attachmentType.ownerOccupiedMinimumRehabilitationCertification,
    attachmentType.ownerOccupied2YearCompletionCertification,
    attachmentType.owners,
    attachmentType.plotMap,
    attachmentType.proofOfOwnership,
    attachmentType.renderingOfStructureAfter,
    attachmentType.photosOfStructureBefore,
    attachmentType.taxInvoice,
    attachmentType.proofOfMillageRate
  ],
  QJ: [
    ...commonAttachmentTypeGroups.sharedProjectDocuments,
    attachmentType.signedRenewalContract,
    attachmentType.baselineSpreadsheet,
    attachmentType.annualReportSpreadsheetAct386,
    attachmentType.annualReportSpreadsheetAct387,
    attachmentType.annualReportSpreadsheetConditional,
    attachmentType.annualReportSpreadsheetPreAct387,
    attachmentType.certOfPrimaryQualificationAct386,
    attachmentType.certOfPrimaryQualificationAct387,
    attachmentType.certOfPrimaryQualificationPreAct387,
    attachmentType.healthCareCertAct386,
    attachmentType.healthCareCertAct387,
    attachmentType.initialBaselineCalculationWorksheet,
    attachmentType.ecrWorksheet,
    attachmentType.quarterlyEs4SutaReports,
    attachmentType.proofNumberNationwideEmployees,
    attachmentType.lgaEndorsementResolution,
    attachmentType.corporateOrganizationalChart,
    attachmentType.headquarterServiceDescription,
    attachmentType.businessLocationsAndDates,
    attachmentType.territoryServiced
  ],
  FILM: [
    ...defaultAttachmentTypes,
    attachmentType.principalPhotography,
    attachmentType.bibleRun,
    attachmentType.completeShootingSchedule,
    attachmentType.finalCrewList,
    attachmentType.laEmploymentData,
    attachmentType.productionStatement,
    attachmentType.synopsis,
    attachmentType.signedInitialCertLetter,
    attachmentType.signedFinalCertLetter,
    attachmentType.estimateBudgetAltMarketingProposal,
    attachmentType.aboveLinePayments,
    attachmentType.laJobsBudgetBreakdown
  ],
  DM: [
    ...defaultAttachmentTypes,
    attachmentType.signedRebateAffidavit,
    attachmentType.intialApplicationQuestions,
    attachmentType.emailCommunication,
    attachmentType.supportingDocumentation,
    attachmentType.SOSRegistration,
    attachmentType.finalScheduleExpenditures,
    attachmentType.finalCertAttestation,
    attachmentType.rebateAffidavit,
    attachmentType.signedInitialCertLetter,
    attachmentType.signedFinalCertLetter,
    attachmentType.rebateRequest
  ],
  STEP: [
    attachmentType.sbaForm1623,
    attachmentType.selfRepresentation,
    attachmentType.W9,
    attachmentType.other
  ]
};

export const documentChecklist = [
  {
    incentiveProgram: incentiveProgram.qj.code,
    formType: formTypes.application.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '• Initial Baseline Calculation Worksheet',
            description: 'used to calculate Baseline Number of Employees',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/QJ_InitialBaselineWorksheet_Application.xlsx',
            isRequired: true
          }
        ]
      },
      {
        name: 'The following may also be required:',
        list: [
          {
            name: '',
            description:
              '• A corporate organizational chart showing the locations of all offices throughout the region and the United States',
            isRequired: false
          },
          {
            name: '',
            description:
              '• Information describing the nature of the services provided by this Louisiana headquarters to other business locations',
            isRequired: false
          },
          {
            name: '',
            description:
              '• The locations and dates the Louisiana headquarters began providing these services to the other locations',
            isRequired: false
          },
          {
            name: '',
            description:
              '• Indicate the territory this location actually services at the time of the advance notification',
            isRequired: false
          },
          {
            name: '',
            description:
              '• Local Endorsement Resolution - If you are seeking local sales tax rebates, Business Incentive Services ' +
              'must receive the endorsement resolution from the local governing authority prior to the application receiving ' +
              'approval from the Board of Commerce and Industry',
            isRequired: false
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.qj.code,
    formType: formTypes.acr.abbrev,
    sections: [
      {
        name: 'Complete and attach the appropriate forms for your contract:',
        list: [
          {
            name: '• Annual Certification (ACR)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/ACR_Doc_Rev_01_22.pdf',
            isRequired: false
          },
          {
            name: '• Baseline & Annual Certification Spreadsheet (Pre-Act 387)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/QJ_ACR_Pre-Act_387_Rev_12_21.xlsx',
            isRequired: false
          },
          {
            name: '• Baseline & Annual Certification Spreadsheet (Act 387)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/QJ_ACR_Act_387_Rev_12_21.xlsx',
            isRequired: false
          },
          {
            name: '• Baseline & Annual Certification Spreadsheet (Act 386)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/QJ_ACR_Act_386_Rev_12_21.xlsx',
            isRequired: false
          },
          {
            name: '• Certification of Primary Qualification (Pre-Act 387)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/cert_primary_qualification_pre-act_387.pdf',
            isRequired: false
          },
          {
            name: '• Certification of Primary Qualification (Act 387)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/cert_primary_qualification_act_387.pdf',
            isRequired: false
          },
          {
            name: '• Certification of Primary Qualification (Act 386)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/CertificationofPrimaryQualificationAct386.pdf',
            isRequired: false
          },
          {
            name: '• ACR-Health Care Certification Form (Act 387)',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/ACR-HealthCare-Act387.pdf',
            isRequired: false
          },
          {
            name: '• Initial Baseline Calculation Worksheet',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/QJ/QJ_Initial_Baseline_Calculation_Worksheet.pdf',
            isRequired: false
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.qj.code,
    formType: formTypes.ecr.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '• ECR Worksheet',
            description: 'used to calculate Baseline Number of Employees',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PostAct423_Protected.xlsx',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Copies of all quarterly ES-4s/SUTA reports for the four months prior to your contract start date.',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Supporting documentation for Number of Nationwide Employees Including Affiliates.',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.qj.code,
    formType: formTypes.changeCompanyName.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description: '•	Secretary of State Verification',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.qj.code,
    formType: formTypes.fullTransferOwnership.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Secretary of State',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Department of Revenue',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.qj.code,
    formType: formTypes.partialTransferOwnership.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Secretary of State',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Department of Revenue',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ite.code,
    formType: formTypes.application.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '• Breakdown of Purchases',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/ITE/ITEBreakdownofPurchases.xlsx',
            isRequired: true
          },
          {
            name: '• Proof of millage rate from Parish Assessor',
            description:
              '- the form must be completed and signed by the Parish Assessors office.',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/ITE/ProofofMillageLocationVerificationForm.pdf',
            isRequired: true
          },
          {
            name: '•	Notarized Affidavit',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/ITE/ITEPContractAffidavit-Revised-08-15-2022.pdf',
            isRequired: true
          },
          {
            name:
              '•	Industrial Tax Exemption Program Baseline Employment Worksheet',

            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/ITE/ITEEmploymentBaselineCalculationWorksheet(Fillable).pdf',
            isRequired: true
          }
        ]
      },
      {
        name:
          'The following will also be required prior to Board of Commerce & Industry consideration:',
        list: [
          {
            name: '',
            description: '•	Exhibit A - to be drafted by LED.',
            isRequired: false
          },
          {
            name: '',
            description:
              '• Exhibit B (for 2016 ITE rules only) - approval resolutions from Parish/Police Jury, Sheriff, School Board, and Municipality (if applicable)',
            isRequired: false
          }
        ]
      },
      {
        name:
          'The following will also be required prior to Board of Commerce & Industry consideration:',
        list: [
          {
            name: '',
            description: '•	ES4 (SUTA)',
            isRequired: false
          },
          {
            name: '',
            description:
              '• Exhibit 2 Certifications of Compliance - Provided in Exhibit A',
            isRequired: false
          },
          {
            name: '',
            description: '• ITE Annual Compliance Report ',
            templateLink: '',
            isRequired: false
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ite.code,
    formType: formTypes.renewalApplication.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '•',
            description: 'Proof of millage rate from Parish Assessor',

            isRequired: true
          },
          {
            name: '•	Notarized Affidavit',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/ITE/ITEPContractAffidavit-Revised-08-15-2022.pdf',
            isRequired: true
          }
        ]
      },
      {
        name: 'The following may be required:',
        list: [
          {
            name: '',
            description: '•	Exhibit A - Proof of No Delinquent Tax Liabilities',
            templateLink: '',
            isRequired: false
          },
          {
            name: '',
            description:
              '•	Exhibit B - Proof of No Delinquent Civil Liabilities',
            templateLink: '',
            isRequired: false
          },
          {
            name: '',
            description:
              '•	Exhibit C - Company/Affiliates Proof of No Felony Conviction',
            templateLink: '',
            isRequired: false
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ite.code,
    formType: formTypes.changeCompanyName.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description: '•	Secretary of State Verification',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ite.code,
    formType: formTypes.fullTransferOwnership.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Secretary of State',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Department of Revenue',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ite.code,
    formType: formTypes.partialTransferOwnership.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Secretary of State',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Department of Revenue',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ite.code,
    formType: formTypes.coc.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '•	ITE Annual Compliance Report',
            description: '',
            isRequired: true,
            templateLink: 'https://storage.googleapis.com/fastlane-public-files/ITE/ITEAnnualComplianceReport(ITE%20ACR).xlsx'
          },
          {
            name: '•	',
            description: 'Quarterly wage reports (ES-4\'S/SUTA)',
            isRequired: true
          },{
            name: '•	ITE Employment Baseline Calculation Worksheet (only required first year reporting)',
            description: '',
            isRequired: true,
            templateLink: 'https://storage.googleapis.com/fastlane-public-files/ITE/ITEEmploymentBaselineCalculationWorksheet(Fillable).pdf'
          }
        ]
      },
      {
        name: 'The following may be required:',
        list: [

          {
            name: '',
            description: '•	Multi Worksite Reports',
            templateLink: '',
            isRequired: false
          },
          {
            name: '',
            description: '•	Non-Compliance Documentation',
            templateLink: '',
            isRequired: false
          }
        ]
      }
    ],
    getRequiredAttachmentTypes: ( form: ProjectForm): string[] => {
      const cocForm = <CertificiateComplianceForm>form;

      // Get all attachments applicable to this form.
      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.ite.code][
        formTypes.coc.abbrev
        ]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(
        attachment => (attachment !== attachmentType.multiWorksite && attachment !== attachmentType.nonComplianceDocumentation && attachment !== attachmentType.baselineCalculationWorksheet)
      );

      // check if this is within the first reporting year OTHERWISE remove the required multiworksite report

      return requiredAttachments;
    }
  },
  {
    incentiveProgram: incentiveProgram.rta.code,
    formType: formTypes.application.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Resolution of the Property Owners / Resolution of Board of Directors with original signatures stating that ' +
              'whomever files this documentation has the authority to do',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	Proof of Ownership: Act of sale or option to acquire the property.',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	Legal property description (suitable for insertion into the exemption contract)',

            isRequired: true
          },
          {
            name: '',
            description: '•	Plot Map',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	A copy of the building permit issued for the project',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	Picture of the structure before beginning the project',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	A rendering of the structure as it will appear after completion of the project',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	Names and addresses of all owners (if a corporation, the principal stockholders of the corporation)',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	Current assessed value of the structure only (improvements) and the taxes paid on the structure only ',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	A copy of the tax invoice for the year prior to beginning of the project from the parish assessor ',

            isRequired: true
          },
          {
            name: '',
            description:
              '•	Certification from the local governing authority that the structure is in a Downtown Development District, ' +
              'a Historic District, or an Economic Development District specifically designated as such for this program.',

            isRequired: true
          },
          {
            name: '',
            description: '•	Proof of Millage Rate from parish assessor ',

            isRequired: true
          }
        ]
      },
      {
        name:
          'The following additional attachments may be needed to process the application - For "Owner-Occupied Residences"',
        list: [
          {
            name: '• Minimum Rehabilitation Certification Statement',
            description:
              'A statement certifying that the minimum rehabilitation cost incurred to the owner-occupied residence project ' +
              'will be equal to or greater than twenty-five percent of the assessed valuation of the improvements located on ' +
              'the property prior to the commencement of the expansion, restoration, improvement, or development.',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/RTA/RTA_MinimumRehabCertStatement.pdf',
            isRequired: false
          },
          {
            name: '• 24-Month Completion Period Certification',
            description:
              '•	A statement certifying that the owner-occupied residence project will be completed within a twenty-four month period. ',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/RTA/RTA_24MonthCompletionCertStatement.pdf',
            isRequired: false
          }
        ]
      },
      {
        name:
          'For applications where construction has been completed prior to filing the application',
        list: [
          {
            name: '',
            description:
              '•	Submit a written certification on the applicants company letterhead or personal letterhead, referencing the RTA ' +
              'application number and stating that: "...taxes have not been paid on improvements for which an exemption is being ' +
              'applied for under the Restoration Tax Abatement Program pursuant to R.S. 47:4315, paragraph (A)(4)."R.S. 47:4315, ' +
              'paragraph (A) (4) of the programs enabling statutes states in part: "... The Board shall not consider an application ' +
              'for exemption on any project if ad Valorem taxes have been paid on the basis of an assessed valuation which reflects ' +
              'the improvements made by the project."',

            isRequired: false
          }
        ]
      }
    ],
    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const rtaApplication = <Application>form;
       // Get all attachments applicable to this form.
      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.rta.code][
          formTypes.application.abbrev
        ]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(
        attachment => (attachment !== attachmentType.signedDisclosureAuthorization
          && attachment !== attachmentType.certifiedHistoricStructure && attachment !== attachmentType.certConstructionCompleted && attachment !== attachmentType.other)
      );

      if ( !rtaApplication.rta.propertyUsage.isOwnerOccupied) {
        requiredAttachments = requiredAttachments.filter(
          attachment => (attachment !== attachmentType.ownerOccupied2YearCompletionCertification
          && attachment !== attachmentType.ownerOccupiedMinimumRehabilitationCertification)
        );
      }


      return requiredAttachments;
    }
  },
  {
    incentiveProgram: incentiveProgram.rta.code,
    formType: formTypes.changeCompanyName.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description: '•	Secretary of State Verification',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.rta.code,
    formType: formTypes.fullTransferOwnership.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Secretary of State',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Department of Revenue',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.rta.code,
    formType: formTypes.partialTransferOwnership.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Secretary of State',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Proof that ownership has been changed with the Louisiana Department of Revenue',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ez.code,
    formType: formTypes.application.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '•	Baseline Calculation Worksheet ',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/EZ/EZ-BaselineCalculationWorksheet.docx',
            isRequired: true
          },
          {
            name: '•	Qualification Certification ',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/EZ/EZ-QualificationCertification.pdf',
            isRequired: true
          },
          {
            name: '•',
            description:
              'Be aware that if you are seeking local sales tax rebates, Business Incentive Services must receive the endorsement ' +
              'resolution from the local governing authority prior to the application receiving approval from the Board of Commerce ' +
              'and Industry. If you received a local endorsement resolution, please upload a copy of the resolution. ',

            isRequired: true
          }
        ]
      },
      {
        name: 'The following may also be required:',
        list: [
          {
            name: '',
            description: '•	Local Endorsement Resolution',
            isRequired: false
          },
          {
            name: '',
            description: '•	Sales Tax Registration Certificate',
            isRequired: false
          },
          {
            name: '',
            description:
              '•	If the business is not a ‘C’ Corporation, provide a copy of the business structure',
            isRequired: false
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ez.code,
    formType: formTypes.ecr.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '• ECR Worksheet (Pre Act 423)',
            description: 'used to calculate Baseline Number of Employees',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PreAct423_Protected.xlsx',
            isRequired: false
          },
          {
            name: '• ECR Worksheet (Post Act 423)',
            description: 'used to calculate Baseline Number of Employees',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PostAct423_Protected.xlsx',
            isRequired: false
          },
          {
            name: '• ECR Worksheet (Post Act 18)',
            description: 'used to calculate Baseline Number of Employees',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/EZ/ECR_Worksheets_PostAct18_Protected.xlsx',
            isRequired: false
          },
          {
            name: '',
            description:
              '•	Copies of all quarterly ES-4s/SUTA reports for the four months prior to your contract start date.',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Supporting documentation for Number of Nationwide Employees Including Affiliates.',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.ez.code,
    formType: formTypes.changeCompanyName.abbrev,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description: '•	Secretary of State Verification',
            templateLink: '',
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    incentiveProgram: incentiveProgram.film.code,
    formType: formTypes.application.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Detailed Preliminary Budget (including above & below the line hires)	 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description: '•	Detailed Distribution Plan	 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description: '•	Script (3-5 pages)		 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '• Scanned Notarized statement ',
            description:
              'in accordance with the provisions of LA RS 47:6007 (D)(2)(e)		 ',
            templateLink:
              'https://storage.cloud.google.com/fastlane-public-files/FILM/Notarized-Statement-creditor-claims.docx',
            isRequired: true
          },
          {
            name: '',
            description: '• Louisiana Spend Budget		 ',
            templateLink: '',
            isRequired: true
          }
        ]
      },
      {
        name: 'The following may also be required:',
        list: [
          {
            name: '',
            description:
              '•	Declaration of Louisiana residency for LA screenwriter',
            isRequired: false
          },
          {
            name: '',
            description: '•	Proof of authorship for LA screenwriter',
            isRequired: false
          },
          {
            name: '',
            description:
              // tslint:disable-next-line:max-line-length
              '•	If LA spend includes Finance fees, insurance fees, or goods from a procurement company, documentation describing these expenditures may be required.',
            isRequired: false
          }
        ]
      }
    ],

    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const filmApplication = <FilmApplication>form;
      // Get all attachments applicable to this form.
      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.film.code][
          formTypes.application.abbrev
        ]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(
        attachment => attachment !== attachmentType.other
      );
      // If isScreenPlayCreatedByResident then manage list of requiredAttachments.
      if (!filmApplication.productionDetails.isScreenPlayCreatedByResident) {
        requiredAttachments = requiredAttachments.filter(
          attachment => attachment !== attachmentType.authourshipProof
        );
      }
      // If wasTaxReturnFiledPreviousYear then manage list of requiredAttachments.
      if (!filmApplication.productionDetails.wasTaxReturnFiledPreviousYear) {
        requiredAttachments = requiredAttachments.filter(
          attachment => attachment !== attachmentType.residencyDeclaration
        );
      }
      return requiredAttachments;
    }
  },
  {
    incentiveProgram: incentiveProgram.film.code,
    formType: formTypes.asa.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Detailed Preliminary Budget (including above & below the line hires)	 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description: '•	Detailed Distribution Plan	 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description: '•	Script (3-5 pages)		 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '• Scanned Notarized statement ',
            description:
              'in accordance with the provisions of LA RS 47:6007 (D)(2)(e)		 ',
            templateLink:
              'https://storage.cloud.google.com/fastlane-public-files/FILM/Notarized-Statement-creditor-claims.docx',
            isRequired: true
          },
          {
            name: '',
            description: '• Louisiana Spend Budget		 ',
            templateLink: '',
            isRequired: true
          }
        ]
      },
      {
        name: 'The following may also be required:',
        list: [
          {
            name: '',
            description:
              '•	Declaration of Louisiana residency for LA screenwriter',
            isRequired: false
          },
          {
            name: '',
            description: '•	Proof of authorship for LA screenwriter',
            isRequired: false
          },
          {
            name: '',
            description:
              '•	If LA spend includes Finance fees, insurance fees, or goods from a procurement company, documentation describing these expenditures may be required.',
            isRequired: false
          }
        ]
      }
    ],
    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const filmApplication = <FilmApplication>form;
      // Get all attachments applicable to this form.
      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.film.code][formTypes.asa.abbrev]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(
        attachment => attachment !== attachmentType.other
      );
      // If isScreenPlayCreatedByResident then manage list of requiredAttachments.
      if (!filmApplication.productionDetails.isScreenPlayCreatedByResident) {
        requiredAttachments = requiredAttachments.filter(
          attachment => attachment !== attachmentType.authourshipProof
        );
      }
      // If wasTaxReturnFiledPreviousYear then manage list of requiredAttachments.
      if (!filmApplication.productionDetails.wasTaxReturnFiledPreviousYear) {
        requiredAttachments = requiredAttachments.filter(
          attachment => attachment !== attachmentType.residencyDeclaration
        );
      }
      return requiredAttachments;
    }
  },
  {
    incentiveProgram: incentiveProgram.film.code,
    formType: formTypes.audit.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The applicant should upload the following documents:',
        list: [
          {
            name: '',
            description: '•	Cost Report	 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description: '•	General Ledger	 ',
            templateLink: '',
            isRequired: true
          }
        ]
      },
      {
        name: 'The applicant may upload the following if auditor requested:',
        list: [
          {
            name: '',
            description: '•	Revised Cost Report 	 ',
            templateLink: '',
            isRequired: false
          },
          {
            name: '',
            description: '•	Other 	 ',
            templateLink: '',
            isRequired: false
          }
        ]
      },
      {
        name: 'The auditor will upload the following: ',
        list: [
          {
            name: '',
            description: '•	Verification Report',
            isRequired: true
          },
          {
            name: '',
            description: '•	Invoice',
            isRequired: true
          }
        ]
      }
    ],
    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const filmAudit = <FilmAudit>form;
      // Get all attachments applicable to this form.
      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.film.code][
          formTypes.audit.abbrev
        ]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(attachment =>
        [attachmentType.costReport, attachmentType.generalLedger].includes(attachment)
      );
      return requiredAttachments;
    }
  },
  {
    incentiveProgram: incentiveProgram.film.code,
    formType: formTypes.audit.abbrev,
    userType: userType.auditor.code,
    sections: [],
    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const filmAudit = <FilmAudit>form;
      // Get all attachments applicable to this form.
      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.film.code][
          formTypes.audit.abbrev
        ]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(attachment =>
        [attachmentType.verificationReport, attachmentType.invoice].includes(
          attachment
        )
      );
      return requiredAttachments;
    }
  },
  {
    incentiveProgram: incentiveProgram.dm.code,
    formType: formTypes.application.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '',
            description:
              '•	Preliminary schedule of expenditures	 ',
            templateLink: '',
            isRequired: true
          }
          //{
          //  name: '',
          //  description:
          //    '•	Verification Deposit	 ',
          //  templateLink: '',
          //  isRequired: true
          //}
        ]
      }
    ],

    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const dmApplication = <DigitalApplication>form;
      // Get all attachments applicable to this form.
      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.dm.code][
        formTypes.application.abbrev
        ]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(
        attachment => attachment !== attachmentType.other && attachment !== attachmentType.detailedJobDecsriptions
      );
      return requiredAttachments;
    }
  },
  {
    incentiveProgram: incentiveProgram.dm.code,
    formType: formTypes.evr.abbrev,
    userType: userType.auditor.code,
    sections: [
      {
        name: 'The following attachments are required for Auditors:',
        list: [
          {
            name: '',
            description:
              '•	Verification Report	 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Invoice	 ',
            templateLink: '',
            isRequired: true
          }
        ]
      },

      {
        name: 'The following attachments are required for Applicants:',
        list: [
          {
            name: '',
            description:
              '•	Cost Report	 ',
            templateLink: '',
            isRequired: true
          },
        ]
      },
    ],

    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const dmApplication = <DigitalApplication>form;
      // Get all attachments applicable to this form.

      let requiredAttachments = [
        ...formAttachmentTypes[incentiveProgram.dm.code][
          formTypes.evr.abbrev
          ]
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(attachment =>

        [attachmentType.verificationReport, attachmentType.invoice].includes(
          attachment
        )
      );
      return requiredAttachments;
    }
  },

  {
    incentiveProgram: incentiveProgram.dm.code,
    formType: formTypes.evr.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The following attachments are required for Auditors:',
        list: [
          {
            name: '',
            description:
              '•	Verification Report	 ',
            templateLink: '',
            isRequired: true
          },
          {
            name: '',
            description:
              '•	Invoice	 ',
            templateLink: '',
            isRequired: true
          }
        ]
      },

      {
        name: 'The following attachments are required for Applicants:',
        list: [
          {
            name: '',
            description:
              '•	Cost Report	 ',
            templateLink: '',
            isRequired: true
          },
        ]
      },
    ],

    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      const dmApplication = <DigitalApplication>form;
      // Get all attachments applicable to this form.

      let requiredAttachments = [
        attachmentType.costReport
      ];
      // Remove optional attachment types.
      requiredAttachments = requiredAttachments.filter(attachment =>

        [attachmentType.costReport].includes(
          attachment
        )
      );
      return requiredAttachments;
    }


  },
  {
    incentiveProgram: incentiveProgram.step.code,
    formType: formTypes.stepApplication.abbrev,
    userType: userType.applicant.code,
    sections: [
      {
        name: 'The following attachments are required:',
        list: [
          {
            name: '• SBA Form 1623: ',
            description: 'Certification Regarding Debarment, Suspension, and Other Responsibility Matters Primary Covered Transactions',
            templateLink:
              'https://www.sba.gov/document/sba-form-1623-certification-regarding-debarment-suspension-other-responsibility-matters-primary-covered',
            isRequired: true
          },
          {
            name: '• SELF-REPRESENTATION AS AN ‘ELIGIBLE SMALL BUSINESS CONCERN‘',
            description: '',
            templateLink:
              'https://storage.googleapis.com/fastlane-public-files/STEP/SBA_Self-Representation.pdf',
            isRequired: true
          },
          {
            name: '• W-9',
            description: '',
            templateLink:
              'https://www.irs.gov/forms-pubs/about-form-w-9',
            isRequired: true
          },
        ]
      }
    ],

    getRequiredAttachmentTypes: (form: ProjectForm): string[] => {
      // Get all attachments applicable to this form.
      let requiredAttachments = [
        attachmentType.sbaForm1623,
        attachmentType.selfRepresentation,
        attachmentType.W9
      ];
      return requiredAttachments;
    }
  },
];

export const managerTypes = {
  primaryProgramManager: {
    displayName: 'Primary Program Manager',
    name: 'PrimaryProgramManager'
  },
  primaryAgreementReviewer: {
    displayName: 'Agreement Reviewer',
    name: 'PrimaryAgreementReviewer'
  }
};

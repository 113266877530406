import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../security/services/authentication.service';
import { SwalService } from './swal.service';
import { SweetAlertOptions } from 'sweetalert2';
import { FormIdentifier } from '../../project/models/form-identifier.model';
import { Observable } from 'rxjs';
import { getFormTypeName } from '../../form/form.functions';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Attachment } from '../../project/models/attachment.model';
import { map, catchError } from 'rxjs/operators';
import {ITEFormTypes} from '../../project/project.constants';
import {formTypes} from '../../form/form.constants';

@Injectable()
export class DownloadService {
  private readonly getSnapshotUrl = `${
    environment.apiUrl
  }/formsnapshot/{projectGuid}/{formType}/{formIndex}`;

  private readonly postBoardAgendaCombinedSnapshotsUrl = `${
    environment.apiUrl
  }/report/getAgendaCombinedSnapshot/{boardMeetingId}`;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private swalService: SwalService
  ) {}

  openBoardAgendaFormCombinedSnapshot(
    boardMeetingId: string,
    formIds: FormIdentifier[]
  ) {
    const that = this;

    const url = `${this.postBoardAgendaCombinedSnapshotsUrl}`.replace(
      '{boardMeetingId}',
      boardMeetingId
    );

    const options = this.authService.getAuthOptions();
    const optionsarrayBuffer = Object.assign(options, {
      responseType: 'arraybuffer'
    });

    const downloadObservable = this.http.post(url, formIds, optionsarrayBuffer);

    return new Observable<{}>(subscriber => {
      downloadObservable.subscribe(
        (res: any) => {
          const fileName = `BoardAgendaFormDetails_${new Date().toLocaleDateString()}.pdf`;
          const blob = new Blob([res], { type: 'application/pdf' });
          const objUrl = window.URL.createObjectURL(blob);
          that.openFileObject(objUrl, fileName);
          subscriber.next();
          subscriber.complete();
        },
        error => {
          console.log(error);
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  openFormSnapshot(formId: FormIdentifier, projectId: string, excludePersonalIdentifyingInformation: boolean = false) {

    const userType = this.authService.getUserRole();

    let formType = formId.formType.toString();
    if (userType === 'applicant') {
      switch (formId.formType) {
        case formTypes.coc.abbrev:
          formType = formId.formType.toString() + 'Applicant';
          break;
        case formTypes.stepApplication.abbrev:
          formType = formId.formType.toString() + 'Applicant';
          break;
      }
    }
    else if (userType === 'guest') {
      switch (formId.formType) {
        case formTypes.stepApplication.abbrev:
          formType = formId.formType.toString() + 'Guest';
          break;
      }
    }

    let url = `${this.getSnapshotUrl}`
      .replace('{projectGuid}', formId.projectGuid)
      .replace('{formType}', formType)
      .replace(
        '/{formIndex}',
        formId.formIndex !== undefined &&
          formId.formIndex != null &&
          formId.formIndex >= 0
          ? '/' + formId.formIndex.toString()
          : ''
      );

    const options = this.authService.getAuthOptions();
    const optionsarrayBuffer = Object.assign(options, {
      responseType: 'arraybuffer'
    });

    const downloadObservable = this.http.get(url, optionsarrayBuffer);

    const fileName = `${projectId}_${getFormTypeName(
      formId.formType
    )}_${new Date().toLocaleDateString()}.pdf`;

    return this.openFile(fileName, downloadObservable);
  }

  openFile(fileName: string, downloadObservable: Observable<any>) {
    const that = this;
    return new Observable<{}>(subscriber => {
      downloadObservable.subscribe(
        (res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const objUrl = window.URL.createObjectURL(blob);
          that.openFileObject(objUrl, fileName);
          subscriber.next();
          subscriber.complete();
        },
        error => {
          console.log(error);
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  retrieveFileFromApi(fileName: string, fileLink: string) {
    const that = this;

    // Url which will return the byte array of each file
    const url = `${
      environment.apiUrl
    }/file/download?filename=${encodeURIComponent(
      fileName
    )}&fileLink=${encodeURIComponent(fileLink)}`;

    // Get authentication options and inform web api that gzip files are accepted.
    const authOptions = this.authService.getAuthOptions(null, 'gzip');

    // This adds the Response content type to the options variable
    const allOptions = Object.assign(authOptions, {
      responseType: 'arraybuffer'
    });

    // Fetch file from web api - Application/octo-stream
    this.http.get(url, allOptions).subscribe(
      (response: any) => {
        const file = new File([response], fileName);
        const objectUrl = <any>window.URL.createObjectURL(file);
        that.openFileObject(objectUrl, fileName);
      },
      error =>
        that.swalService.error(<SweetAlertOptions>{
          title: 'Unable to open file',
          text: 'An unknown error ocurred when opening the file'
        })
    );
  }

  retrievePublicReportFromApi(fileName: string) {
    const that = this;

    // Url which will return the byte array of each file
    const url = `${environment.apiUrl}/report/download/${encodeURIComponent(
      fileName
    )}`;

    // No authOptions because user is not logged in
    const authOptions = { headers: new HttpHeaders() };

    // This adds the Response content type to the options variable
    const allOptions = Object.assign(authOptions, {
      responseType: 'arraybuffer'
    });

    // Fetch file from web api - Application/octo-stream
    this.http.get(url, allOptions).subscribe(
      (response: any) => {
        fileName = fileName + '.csv';
        const file = new File([response], fileName);
        const objectUrl = <any>window.URL.createObjectURL(file);
        that.openFileObject(objectUrl, fileName);
      },
      error =>
        that.swalService.error(<SweetAlertOptions>{
          title: 'Unable to open file',
          text: 'An unknown error ocurred when opening the file'
        })
    );
  }

  private openFileObject(objUrl, fileName) {
    // Create a new anchor element that will have a download attribute for the browser to know the name and extension
    // to use while saving.
    const aElement = document.createElement('a');
    const currentDocument = document;

    // Add the new element to the current page to simulate the click event on behalf of user
    currentDocument.body.appendChild(aElement);
    aElement.className = 'd-none';
    aElement.href = objUrl;
    aElement.download = fileName;

    // Click it on behalf of user
    aElement.click();

    // Revoke Object URL and remove from previous context
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
      $(aElement).remove();
    }, 100);
  }
}

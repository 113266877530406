import { Injectable, OnDestroy } from '@angular/core';
import { Project } from '../models/project.model';
import { Payment } from '../../fiscal/models/payment.model';
import { Contact } from '../models/project-contact.model';
import { BusinessLegalStructure } from '../models/business-legal-structure.model';
import { legistlationRules, incentiveProgram } from '../project.constants';
import { ResolvedProject } from './project-data.service';
import { UserContextService } from '../../user/services/user-context.service';
import { ProjectDate } from '../models/project-date.model';
import { ProjectBase } from '../../project/models/project-base.model';
import { Project as FilmProject } from '../../entertainment/models/film/project.model';
import { Project as DigitalProject } from '../../entertainment/models/digital/project.model';
import { Project as StepProject } from '../../step/models/step/project.model';
import { CastProject } from '../project.functions';
import { Credit as FilmCredit } from '../../entertainment/models/film/credit.model';
import { Credit as DigitalCredit } from '../../entertainment/models/digital/credit.model';

@Injectable({ providedIn: 'root' })
export class ProjectDetailService implements OnDestroy {
  isCreatingNewContact: boolean;

  //#region Polymorphic Project Properties

  // This is the base project property which every other program extends. It contains all common properties across all
  // programs
  projectBase: ProjectBase = null;

  // Properties for casting to and from Project Base to BI Project
  public get project(): Project {
    return this.projectBase as Project;
  }
  public set project(value: Project) {
    this.projectBase = value;
  }


  // Properties for casting to and from Project Base to Film Project
  public get stepProject(): StepProject {
    return this.projectBase as StepProject;
  }
  public set stepProject(value: StepProject) {
    this.projectBase = value;
  }

  // Properties for casting to and from Project Base to Film Project
  public get filmProject(): FilmProject {
    return this.projectBase as FilmProject;
  }
  public set filmProject(value: FilmProject) {
    this.projectBase = value;
  }

  public get digitalProject(): DigitalProject {
    return this.projectBase as DigitalProject;
  }
  public set digitalProject(value: DigitalProject) {
    this.projectBase = value;
  }

  //#endregion
  currentIncentiveProgram: string;
  filmCredits: FilmCredit[];
  digitalCredits: DigitalCredit[];
  payments: Payment[];
  selectedContact: Contact = null;
  isCreatingNewBusinessStructure: boolean;
  selectedBusinessStructure: BusinessLegalStructure = null;
  isRedirect: boolean;
  contactOnFormInit = false;
  contactEmail = '';
  projectDates: ProjectDate[] = [];
  relatedProjects: ProjectBase[];
  relatedProjectsIds: string[] = [];

  constructor(private userContextService: UserContextService) {}

  isUserBusinessSignatory() {
    const signerEmail = this.project.businessSignatory
      ? this.project.businessSignatory.emailAddress.toLowerCase()
      : null;
    const userEmail = this.userContextService.currentUser.emailAddress.toLowerCase();
    return signerEmail === userEmail;
  }

  ngOnDestroy() {}

  setResolvedProject(resolvedProject: ResolvedProject) {
    this.currentIncentiveProgram =
      resolvedProject.project.projectInfo.incentiveProgram;

    // Initialize according to the program and assign to projectBase.
    // When consuming by each program specific component, the right property from this service
    // should be used such as getFilmProject(), project() for BI, etc....
    this.project = CastProject(
      resolvedProject.project,
      this.currentIncentiveProgram
    );
    // Set payments from database
    this.payments = Object.assign(
      new Array<Payment>(),
      resolvedProject.payments
    );

    // Set resolved credits for this projects
    if (this.currentIncentiveProgram === incentiveProgram.film.code) {
      this.filmCredits = Object.assign(
        new Array<FilmCredit>(),
        resolvedProject.credits
      );
    }
    if (this.currentIncentiveProgram === incentiveProgram.dm.code) {
      this.digitalCredits = Object.assign(
        new Array<DigitalCredit>(),
        resolvedProject.credits
      );
    }

    // Set project dates from the database
    this.projectDates = resolvedProject.projectDates || [];
  }

  setProjectHash(hash: string) {
    // Ensure project is loaded or exists
    if (this.project) {
      this.project.projectHash = hash;
    }
  }

  getLegislationRule(
    currentIncentiveProgram: string,
    receivedDate: Date
  ): string {
    const specificLegislationRule = legistlationRules[currentIncentiveProgram];
    for (let i = 0; i < specificLegislationRule.length; i++) {
      if (
        !specificLegislationRule[i].effectiveDate ||
        receivedDate.getTime() >
          specificLegislationRule[i].effectiveDate.getTime()
      ) {
        return specificLegislationRule[i].ruleName;
      }
    }
  }
}

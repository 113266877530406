import { Environment } from './environment-model';
import { MailAddress } from '../app/email/models/mail-address.model';
import { versionHash } from '../runtime';

const devsGroupAddress: MailAddress = {
  emailAddress: 'led.businessanalyst@la.gov', //here
  displayName: 'Tri-Core Dev'
};

export const environment: Environment = {
  apiUrl: 'https://api.fastlaneng-prealpha.tri-core.net/api',
  enableServiceWorker: true,
  isProduction: false,
  emailFromAddress: {
    emailAddress: 'TriCoreDevTeam@sandbox280fe36815cf427d828c8bcaa28286d4.mailgun.org',
    displayName: 'Pre-Alpha Tester'
  },
  testEmailRecipientAddressess: [devsGroupAddress],
  paypointUrl: 'https://uat.thepayplace.com/la/economic/fastlane/default.aspx?',
  cloudPublicFilesPrefix:
    'https://storage.googleapis.com/fastlane-public-files/',
  versionHash: versionHash,
  shouldBypassPaypoint: true,
  orbiPayClientKey: ''
};

import { incentiveProgram } from '../../../project/project.constants';
import { formTypes } from '../../../form/form.constants';
import { getAllCreditTypes } from '../../../entertainment/credit.functions';
import { FormIdentifier } from '../../../project/models/form-identifier.model';
import {StepForm} from '../step-form.model';

export class StepApplication extends StepForm {

  // Company Description/Background
  primaryActivity: string;
  primaryActivityDescription?: string;
  primaryAffiliation: string[];
  primaryAffiliationDescription?: string;
  naicsCode: string;
  yearEst?: number;
  totalEmployees?: number;
  forProfit?: boolean;
  estGrossSalesLastYear: string;
  existingExportStrategy?: boolean;
  estAnnualExportPercentage: string;
  exportLocation: string;
  exportLocationInterest: string;
  businessType: string[];

  // Product/Service Information
  productDescription: string;
  salesChannelsDescription: string;
  isProductCertified?: boolean;
  productCertifiedDescription?: string;
  isForeignRepresentation?: boolean;
  foreignRepresentationDescription?: string;
  isExportUSOrigin?: boolean;
  usOriginContact: string;
  otherOrgs: string[];
  otherOrgsDescription?: string;
  optInSBA?: boolean;

  // Planned Use of Step Funds
  tradeShowsAndMissions: boolean;
  tradeShowsAndMissionsDescription?: string;
  globalMarketEvents: boolean;
  globalMarketEventsDescription?: string;
  usCommercialServices: boolean;
  usCommercialServicesDescription?: string;
  exportWorkshops: boolean;
  exportWorkshopsDescription?: string;
  ecommAndWebGlobalization: boolean;
  ecommAndWebGlobalizationDescription?: string;
  designMarketingMedia: boolean;
  designMarketingMediaDescription?: string;
  complianceTestingAndShipping: boolean;
  complianceTestingAndShippingDescription?: string;
  otherExpenseOrActivity: boolean;
  otherExpenseOrActivityDescription?: string;
  heardAboutDescription: string;

  constructor(formIndex: number = null) {
    super(formIndex);
    this.init();
  }

  init(): StepApplication {
    super.init();
    this.type = formTypes.stepApplication.abbrev;
    return this;
  }

  get qualifier() {
    return null;
  }
}

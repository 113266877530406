import { userType } from '../user/user.constants';
import { incentiveProgram } from '../project/project.constants';

export const ManagerialPermission = {
  biDashBoard: {
    code: 'biDashBoard',
    name: 'BI Dashboard'
  },
  entDashBoard: {
    code: 'entDashBoard',
    name: 'Entertainment Dashboard'
  },
  stepDashBoard: {
    code: 'stepDashBoard',
    name: 'STEP Dashboard'
  },
  search: {
    code: 'search',
    name: 'Search'
  },
  projectSummaryReadWrite: {
    code: 'projectSummaryReadWrite',
    name: 'Project Summary View'
  },
  formReview: {
    code: 'formReview',
    name: 'Form Review'
  },
  externalReview: {
    code: 'externalReview',
    name: 'External Review'
  },
  board: {
    code: 'board',
    name: 'Board'
  },
  signatures: {
    code: 'signatures',
    name: 'Signatures'
  },
  payments: {
    code: 'payments',
    name: 'Payments'
  },
  projectAccess: {
    code: 'projectAccess',
    name: 'Project Access'
  },
  userAccounts: {
    code: 'userAccounts',
    name: 'User Accounts'
  },
  createNewUser: {
    code: 'createNewUser',
    name: 'Create New User'
  }
};

export interface Permission {
  display: string;
  value: string;
}

// Had to change from key [key: string]: Permission so that userTypeToPermissionsMap works
export interface Permissions {
  canPerformActions: Permission;
  canViewConfidentialProjects: Permission;
  canPerformEzActions: Permission;
  canPerformQjActions: Permission;
  canPerformRtaActions: Permission;
  canPerformIteActions: Permission;
  canPerformFilmActions: Permission;
  canPerformDigitalActions: Permission;
  canPerformStepActions: Permission;
}

export interface UserTypeToPermission {
  [key: string]: Permission[];
}

export const permissions: Permissions = {
  canPerformActions: <Permission>{
    display: 'Can Perform Actions',
    value: 'canPerformActions'
  },
  canViewConfidentialProjects: <Permission>{
    display: 'Can View Confidential Projects',
    value: 'canViewConfidentialProjects'
  },
  canPerformEzActions: <Permission> {
    display: 'EZ Actions',
    value: 'canPerformEzActions'
  },
  canPerformQjActions: <Permission> {
    display: 'QJ Actions',
    value: 'canPerformQjActions'
  },
  canPerformRtaActions: <Permission> {
    display: 'RTA Actions',
    value: 'canPerformRtaActions'
  },
  canPerformIteActions: <Permission> {
    display: 'ITE Actions',
    value: 'canPerformIteActions'
  },
  canPerformFilmActions: <Permission> {
    display: 'Film Actions',
    value: 'canPerformFilmActions'
  },
  canPerformDigitalActions: <Permission> {
    display: 'Digital Actions',
    value: 'canPerformDigitalActions'
  },
  canPerformStepActions: <Permission> {
    display: 'STEP Actions',
    value: 'canPerformStepActions'
  },
};

/**
 *
 */
export const programActionPermissions = {
  [incentiveProgram.ez.code]: permissions.canPerformEzActions.value,
  [incentiveProgram.qj.code]: permissions.canPerformQjActions.value,
  [incentiveProgram.rta.code]: permissions.canPerformRtaActions.value,
  [incentiveProgram.ite.code]: permissions.canPerformIteActions.value,
  [incentiveProgram.film.code]: permissions.canPerformFilmActions.value,
  [incentiveProgram.dm.code]: permissions.canPerformDigitalActions.value,
  [incentiveProgram.step.code]: permissions.canPerformStepActions.value,

}

export const userTypeToPermissionsMap: UserTypeToPermission = {
  [userType.management.code]: [
    permissions.canPerformEzActions,
    permissions.canPerformQjActions,
    permissions.canPerformRtaActions,
    permissions.canPerformIteActions,
    permissions.canPerformFilmActions,
    permissions.canPerformDigitalActions,
    permissions.canPerformStepActions
  ],
  [userType.applicant.code]: [],
  [userType.assessor.code]: [
    permissions.canPerformActions,
    permissions.canViewConfidentialProjects
  ],
  [userType.external.code]: [
    permissions.canPerformActions,
    permissions.canViewConfidentialProjects
  ],
  [userType.board.code]: []
};

<form class="no-borders" novalidate [formGroup]="filmApplicationForm">
  <div class="mb-4">
    <div class="card">
      <div class="card-header"><b>ADDITIONAL COMPANY INFORMATION</b></div>
      <div class="card-body px-5 form-group mb-0">
        <div class="row d-flex align-items-center py-2 px-5">
          <label class="form-control-label col-12 col-md-2">
            Parent Company Name:
          </label>
          <input
            type="text"
            class="form-control col-12 col-md-4"
            data-toggle="tooltip"
            data-placement="top"
            formControlName="parentCompanyName"
          />
          <div class="d-none d-md-block col-md-6"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <div class="card">
      <div class="card-header"><b>PRODUCTION DETAILS</b></div>
      <div class="card-body px-5 form-group mb-0">
        <fl-production-details
          [productionDetails]="application.productionDetails"
        ></fl-production-details>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <div class="card">
      <div class="card-header"><b>LA VISUAL EFFECTS COMPANY</b></div>
      <div class="card-body px-5 form-group mb-0">
        <div class="row d-flex align-items-center py-2 px-5">
          <label class="form-control-label col-12 col-md-6">
            Is visual effects occurring in Louisiana?
          </label>

          <div class="col-12 col-md-3">
            <label class="form-check-inline">
              <input
                type="radio"
                name="isVisualEffectsinLA"
                [value]="true"
                (change)="radioChanged(true)"
                id="inlineCheckbox1"
                formControlName="isVisualEffectsinLA"
              />Yes
            </label>
            <label class="form-check-inline">
              <input
                type="radio"
                name="isVisualEffectsinLA"
                [value]="false"
                (change)="radioChanged(false)"
                id="inlineCheckbox2"
                formControlName="isVisualEffectsinLA"
              />No
            </label>
          </div>
        </div>
        <ng-container *ngIf="application.isVisualEffectsinLA">
          <div class="row d-flex align-items-center py-2 px-5">
            <label class="form-control-label col-12 col-md-6">
              Is the LA Visual Effects Company a qualified entertainment
              company?
            </label>

            <div class="col-12 col-md-3">
              <label class="form-check-inline">
                <input
                  type="radio"
                  name="isVisualEffectsQualifiedCompany"
                  [value]="true"
                  id="inlineCheckbox1"
                  formControlName="isVisualEffectsQualifiedCompany"
                />Yes
              </label>
              <label class="form-check-inline">
                <input
                  type="radio"
                  name="isVisualEffectsQualifiedCompany"
                  [value]="false"
                  id="inlineCheckbox2"
                  formControlName="isVisualEffectsQualifiedCompany"
                />No
              </label>
            </div>
          </div>
          <div class="row d-flex align-items-center py-2 px-5">
            <label class="form-control-label col-12 col-md-2">
              LA Visual Effects Company Name
            </label>
            <input
              type="text"
              class="form-control col-12 col-md-4"
              formControlName="visualEffectsCompanyName"
            />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <div class="card">
      <div class="card-body px-5 form-group mb-0">
        <div class="row mt-4 d-flex align-items-stretch">
          <div class="col-6">
            <fl-film-expenditures
              [expenditures]="application.expenditures"
              [isVfxinLa]="application.isVisualEffectsinLA"
            ></fl-film-expenditures>
          </div>
          <div class="col-6">
            <fl-jobs [jobs]="application.estimatedJobs" [form]="form"></fl-jobs>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <div class="card">
      <div class="card-body px-5 form-group mb-0">
        <div class="row mt-4 d-flex align-items-stretch">
          <div class="col-6">
            <fl-principal-photography-days
              [days]="application.estimatedDays"
            ></fl-principal-photography-days>
          </div>

          <div class="col-6">
            <fl-dates [estimatedDates]="application.estimatedDates"></fl-dates>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <div class="card">
      <div class="card-body px-5 form-group mb-0">
        <div class="row mt-4 d-flex align-items-stretch">
          <div class="col-6">
            <fl-principals
              [principals]="application.principals"
            ></fl-principals>
          </div>

          <div class="col-6">
            <fl-related-transactions
              [relatedTransactions]="application.relatedTransactions"
            ></fl-related-transactions>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <div class="card">
      <div class="card-header"><b>LOGO REQUIREMENTS</b></div>
      <div class="card-body px-5 form-group mb-0">
        <div class="row d-flex align-items-center py-2 px-5">
          <label class="form-control-label col-12 col-md-6">
            The production will use the following logo requirements as defined
            in LA:RS 47.6007:
          </label>
          <select
            class="form-control col-12 col-md-6"
            formControlName="logoRequirementType"
            (ngModelChange)="changeLogoType($event)"
            required
          >
            <option *ngFor="let req of logoRequirements" [ngValue]="req">{{
              req
            }}</option>
          </select>
          <div *ngIf="isOtherLogoReq" class="row py-1 w-100 mx-0">
            <label class="form-control-label col-12 col-md-2 ">
              Describe Other:
            </label>

            <textarea
              class="form-control"
              id="exampleTextarea"
              rows="2"
              formControlName="otherLogoReqDescription"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="careerBasedLearningContainer" class="mb-4">
    <div class="card">
      <div class="card-header">
        <b>CAREER BASED LEARNING</b>
        <i
          data-title="CAREER BASED LEARNING OPTIONS"
          data-trigger="hover"
          data-container="#careerBasedLearningContainer"
          data-html="true"
          [attr.data-content]="careerInfoLegend.innerHTML"
          data-toggle="popover"
          data-placement="top"
          data-boundary="window"
          [attr.data-template]="careerInfoTemplate.innerHTML"
          class="ml-2 fa fa-info-circle"
          aria-hidden="true"
        ></i>
      </div>
      <div class="card-body px-5 form-group mb-0">
        <div class="row d-flex align-items-center py-2 px-5">
          <label class="form-control-label col-12 col-md-6">
            The production will participate in the following career-based
            learning and training program:
          </label>
          <select
            class="form-control col-12 col-md-6"
            formControlName="careerLearningType"
            (ngModelChange)="changeCareerLearningType($event)"
            required
          >
            <option
              *ngFor="let ct of careerLearningTypes"
              [ngValue]="ct.value"
              >{{ ct.value }}</option
            >
          </select>
          <div *ngIf="isOtherCareerLearning" class="row py-1 w-100 mx-0">
            <label class="form-control-label col-12 col-md-2 ">
              Describe Other:
            </label>

            <textarea
              class="form-control"
              id="exampleTextarea"
              rows="2"
              formControlName="otherCareerLearningDescription"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-4" *ngIf="!hideCredits">
    <div class="card">
      <div class="card-header"><b>ESTIMATED CREDITS</b></div>
      <div class="card-body px-5 form-group mb-0">
        <div class="col-12 d-flex align-items-center py-2">
          <fl-film-credit-management
            class="col-12 w-100"
            [creditTypes]="application.estimatedCreditTypes"
            [expenditures]="application.expenditures"
            [form]="form"
          ></fl-film-credit-management>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-4" *ngIf="!hideDeposit">
          <fl-audit-deposit
            [form]="form"
            class="w-100"
            [attr.data-valid]=""
          ></fl-audit-deposit>
  </div>

  <!-- CONTENT OF THE CAREER INFORMATION POPOVER -->
  <div #careerInfoLegend id="careerInfoLegend" class="d-none">
    <div class="d-flex flex-wrap mx-0 ">
      <b>Paid Internships -</b>A minimum of 3 paid internship positions provided
      to students enrolled in an accredited high school, community college,
      university or qualified community-based program, for a minimum of 75 hours
      per student and a total of 225 hours. <b>Classroom Workshop -</b>A minimum
      of 8 hours of classroom workshop provided to students enrolled in an
      accredited high school, community college, university or qualified
      community-based program. <b>Studio Employment -</b>A minimum of 8 hours of
      studio employment and professional skills tour provided to students
      enrolled in high school, community college, university or qualified
      community-based program. <b>Continued Education -</b>A minimum of 8 hours
      of continuing education for educators or faculty to observe the set
      operations, post production and other specialized departments.
      <b>Financial Contribution -</b>Financial contribution or donation to a
      specific local educational agency or higher education institution
      specializing in arts, media and entertainment career-oriented program.
      Financial contributions calculated at 0.25 percent of the estimated tax
      credit reservation. <b>Other -</b>Other method of participation approved
      by the office.
    </div>
  </div>

  <!-- TEMPLATE TO USE FOR DISPLAYING THE LEGEND -->
  <div #careerInfoTemplate class="d-none">
    <div style="z-index: 50000" class="popover" role="tooltip">
      <div class="arrow"></div>
      <h3 class="popover-header"></h3>
      <div class="popover-body"></div>
    </div>
  </div>
</form>

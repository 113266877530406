import { BusinessIncentiveProjectInfo } from './business-incentive-project-info.model';
import { EstimatedInvestments } from './estimated-investments.model';
import { FeeCalculations } from '../../fiscal/models/fee-calculations.model';
import { ProjectForm } from './form.model';
import { ContractInformation } from './contract-information.model';
import { SupplementalQuestions } from './supplemental-questions.model';
import { GrossPayroll } from './gross-payroll.model';
import { BusinessLegalStructure } from './business-legal-structure.model';
import { SalesTaxInvestmentTax } from './sales-tax-investment-tax.model';
import { Signature } from './signature.model';
import { ExternalReview } from './external-review.model';
import { EstimatedJobs } from './estimated-jobs.model';
import { EstimatedPayroll } from './estimated-payroll.model';
import { IndustrialTaxExemptionApplication } from './industrial-tax-exemption-application.model';
import { RestorationTaxAbatementApplication } from './restoration-tax-abatement-application.model';
import { QualityJobsApplication } from './quality-jobs-application.model';
import { EnterpriseZoneApplication } from './enterprise-zone-application.model';
import { EzSchedule } from './ez-schedule.model';
import { EzProjectDetails } from './ez-project-details.model';
import { PropertyUsage } from './property-usage.model';
import { PropertyTax } from './property-tax.model';
import { formTypes } from 'app/form/form.constants';

export class Application extends ProjectForm {
  affiliates: BusinessLegalStructure[];
  contractInformation: ContractInformation;
  estimatedInvestments: EstimatedInvestments;
  estimatedJobs: EstimatedJobs;
  estimatedPayroll: EstimatedPayroll;
  grossPayroll: GrossPayroll[];
  isApplicationCertified: boolean;
  millageRate: number;
  projectDetails: BusinessIncentiveProjectInfo;
  salesTaxInvestmentTax: SalesTaxInvestmentTax;
  supplementalQuestions: SupplementalQuestions;
  ez: EnterpriseZoneApplication;
  qj: QualityJobsApplication;
  rta: RestorationTaxAbatementApplication;
  ite: IndustrialTaxExemptionApplication;

  constructor() {
    super();
  }

  init(): Application {
    super.init();
    this.receivedDate = this.receivedDate ? new Date(this.receivedDate) : null;

    this.projectDetails = new BusinessIncentiveProjectInfo(this.projectDetails);
    this.millageRate = this.millageRate > 0 ? this.millageRate : null;
    this.supplementalQuestions =
      this.supplementalQuestions || new SupplementalQuestions();
    this.grossPayroll = this.grossPayroll || new Array<GrossPayroll>();
    this.estimatedInvestments =
      this.estimatedInvestments || new EstimatedInvestments();
    this.estimatedJobs = this.estimatedJobs || new EstimatedJobs();
    this.estimatedPayroll = this.estimatedPayroll || new EstimatedPayroll();
    this.salesTaxInvestmentTax = new SalesTaxInvestmentTax(
      this.salesTaxInvestmentTax
    );
    this.affiliates = this.affiliates || new Array<BusinessLegalStructure>();
    this.fees = this.fees || new Array<FeeCalculations>();
    this.payments = this.payments || new Array<string>();
    this.signature = this.signature || new Signature();
    this.contractInformation = new ContractInformation(
      this.contractInformation
    );
    this.externalReview = this.externalReview || new ExternalReview();
    this.ez = this.ez || new EnterpriseZoneApplication();
    this.ez.ezSchedule = this.ez.ezSchedule || new EzSchedule();
    this.ez.ezProjectDetails =
      this.ez.ezProjectDetails || new EzProjectDetails();
    this.qj = new QualityJobsApplication(this.qj);
    this.ite = new IndustrialTaxExemptionApplication(this.ite);
    this.rta = this.rta || new RestorationTaxAbatementApplication();
    this.rta.propertyUsage = this.rta.propertyUsage || new PropertyUsage();
    this.rta.propertyTax = this.rta.propertyTax || new PropertyTax();
    this.ite.propertyTax = this.ite.propertyTax || new PropertyTax();

    this.type = formTypes.application.abbrev;
    return this;
  }

  get qualifier() {
    return '';
  }
}

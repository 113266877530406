import { StatusLog } from './status-log.model';
import { FeeCalculations } from '../../fiscal/models/fee-calculations.model';
import { Signature } from './signature.model';
import { Legislator } from './legislator.model';
import { formStatuses } from '../../form/form.constants';
import { BoardStatus } from './board-status.model';
import { ExternalReview } from './external-review.model';
import { FormStatus, FormType } from '../../form/form.constants';
import { getFormsTypesRequiringBusinessSignature } from '../../form/form.functions';
import { last } from 'underscore';
import { Qualifier } from '../../form/interfaces/qualifier.interface';
import {ProjectInfoBase} from './project-info-base.model';

export abstract class ProjectForm implements Qualifier {
  boardStatuses: BoardStatus[];
  externalReview: ExternalReview = new ExternalReview();
  fees: FeeCalculations[];
  formIndex: number;
  legislation: string;
  payments: string[];
  receivedDate: Date;
  signature: Signature;
  statuses: StatusLog[];
  type: FormType;
  numberOfYearsPenalized: number;
  legislators: Legislator;
  projectAgreementId: string;
  advanceTotalInvestment: number;
  advanceFirstYearExemption: number;

  get currentFormStatus() {
    return this.statuses && this.statuses.length > 0
      ? <FormStatus>this.statuses[0].status
      : <FormStatus>formStatuses.pending.name;
  }

  get isBusinessSignatureRequired() {
    const formsRequiringSignature = getFormsTypesRequiringBusinessSignature();
    return formsRequiringSignature.includes(this.type);
  }

  get isPaymentRequired() {
    return !this.fees || !this.fees[0] || this.fees[0].amountDue > 0;
  }

  constructor(formIndex: number = null, form?: any) {
    form = form || {};
    if (formIndex >= 0) {
      this.formIndex = formIndex;
    }

    form = form || {};
    this.boardStatuses = form.boardStatuses || [];
    this.externalReview = form.externalReview || new ExternalReview();
    this.fees = form.fees || [];
    this.formIndex =
      formIndex >= 0 ? formIndex : form.formIndex >= 0 ? form.formIndex : null;
    this.legislation = form.legislation || null;
    this.payments = form.payments || [];
    this.receivedDate = form.receivedDate ? new Date(form.receivedDate) : null;
    this.signature = form.signature || null;
    this.statuses = form.statuses || [
      <StatusLog>{ statusDate: new Date(), status: formStatuses.pending.name }
    ];
    this.init();
  }

  addReceivedDate(): ProjectForm {
    if (this.receivedDate == null || this.receivedDate === undefined) {
      this.receivedDate = new Date();
    }

    return this;
  }

  // todo: Change status type of string to FormStatus
  changeStatus(
    status: string,
    comment: string = null,
    creator: string = null
  ): ProjectForm {
    if (this.statuses.length === 0 || this.statuses[0].status !== status) {
      // Only change status if different from the current status
      const newStatus = <StatusLog>{ status: status, statusDate: new Date() };

      // If creator
      if (creator) {
        newStatus.creator = creator;
      }

      // If comment provided
      if (comment) {
        newStatus.comment = comment;
      }

      this.statuses.unshift(newStatus);
    }

    // The following block should only get triggered for the first form save
    const formCreatorUserId = last(this.statuses).creator;
    if (!formCreatorUserId) {
      this.statuses[this.statuses.length - 1].creator = creator;
    }

    return this;
  }

  init() {
    this.fees = this.fees ? this.fees.map(fee => new FeeCalculations(fee)) : [];

    this.signature = this.signature
      ? Object.assign(new Signature(), this.signature).init()
      : new Signature();

    if (this.receivedDate) {
      this.receivedDate = new Date(this.receivedDate);
    }

    this.statuses = this.statuses || [
      <StatusLog>{ statusDate: new Date(), status: formStatuses.pending.name }
    ];
    this.statuses.forEach((status, index, array) => {
      array[index] = <StatusLog>{
        status: status.status,
        statusDate: new Date(status.statusDate),
        comment: status.comment || null,
        creator: status.creator || null
      };
    });
    this.receivedDate = this.receivedDate ? new Date(this.receivedDate) : null;
  }

  updateFees(currentFeeCalculations: FeeCalculations): ProjectForm {
    // Check if fee calculation object is empty and then populate the array
    if (this.fees.length === 0) {
      this.fees.unshift(currentFeeCalculations);
    } else {
      // check if the fee calculations changed and then populate the array
      if (
        JSON.stringify(this.fees[0]) !== JSON.stringify(currentFeeCalculations)
      ) {
        this.fees.unshift(currentFeeCalculations);
      }
    }

    return this;
  }

  abstract get qualifier(): string;
}

import { OnDestroy } from '@angular/core';
import { legistlationRules, incentiveProgram } from '../project.constants';
import { UserContextService } from '../../user/services/user-context.service';
import { CastProject } from '../project.functions';
import * as i0 from "@angular/core";
import * as i1 from "../../user/services/user-context.service";
export class ProjectDetailService {
    constructor(userContextService) {
        this.userContextService = userContextService;
        //#region Polymorphic Project Properties
        // This is the base project property which every other program extends. It contains all common properties across all
        // programs
        this.projectBase = null;
        this.selectedContact = null;
        this.selectedBusinessStructure = null;
        this.contactOnFormInit = false;
        this.contactEmail = '';
        this.projectDates = [];
        this.relatedProjectsIds = [];
    }
    // Properties for casting to and from Project Base to BI Project
    get project() {
        return this.projectBase;
    }
    set project(value) {
        this.projectBase = value;
    }
    // Properties for casting to and from Project Base to Film Project
    get stepProject() {
        return this.projectBase;
    }
    set stepProject(value) {
        this.projectBase = value;
    }
    // Properties for casting to and from Project Base to Film Project
    get filmProject() {
        return this.projectBase;
    }
    set filmProject(value) {
        this.projectBase = value;
    }
    get digitalProject() {
        return this.projectBase;
    }
    set digitalProject(value) {
        this.projectBase = value;
    }
    isUserBusinessSignatory() {
        const signerEmail = this.project.businessSignatory
            ? this.project.businessSignatory.emailAddress.toLowerCase()
            : null;
        const userEmail = this.userContextService.currentUser.emailAddress.toLowerCase();
        return signerEmail === userEmail;
    }
    ngOnDestroy() { }
    setResolvedProject(resolvedProject) {
        this.currentIncentiveProgram =
            resolvedProject.project.projectInfo.incentiveProgram;
        // Initialize according to the program and assign to projectBase.
        // When consuming by each program specific component, the right property from this service
        // should be used such as getFilmProject(), project() for BI, etc....
        this.project = CastProject(resolvedProject.project, this.currentIncentiveProgram);
        // Set payments from database
        this.payments = Object.assign(new Array(), resolvedProject.payments);
        // Set resolved credits for this projects
        if (this.currentIncentiveProgram === incentiveProgram.film.code) {
            this.filmCredits = Object.assign(new Array(), resolvedProject.credits);
        }
        if (this.currentIncentiveProgram === incentiveProgram.dm.code) {
            this.digitalCredits = Object.assign(new Array(), resolvedProject.credits);
        }
        // Set project dates from the database
        this.projectDates = resolvedProject.projectDates || [];
    }
    setProjectHash(hash) {
        // Ensure project is loaded or exists
        if (this.project) {
            this.project.projectHash = hash;
        }
    }
    getLegislationRule(currentIncentiveProgram, receivedDate) {
        const specificLegislationRule = legistlationRules[currentIncentiveProgram];
        for (let i = 0; i < specificLegislationRule.length; i++) {
            if (!specificLegislationRule[i].effectiveDate ||
                receivedDate.getTime() >
                    specificLegislationRule[i].effectiveDate.getTime()) {
                return specificLegislationRule[i].ruleName;
            }
        }
    }
}
ProjectDetailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectDetailService_Factory() { return new ProjectDetailService(i0.ɵɵinject(i1.UserContextService)); }, token: ProjectDetailService, providedIn: "root" });

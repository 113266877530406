import { incentiveProgram } from '../project/project.constants';
import { formTypes } from '../form/form.constants';

declare var $: any;
declare var moment: any;

export function getColumnDefinitions(program: string) {}

export const managementColumnDefinitions = {
  [incentiveProgram.film.code]: {
    [formTypes.initialCert.abbrev]: [
      {
        title: 'Project ID',
        data: 'projectInfo.projectId',
        defaultContent: '',
        type: 'html'
      },
      {
        title: 'Film ID',
        data: 'projectInfo.filmId',
        defaultContent: ''
      },
      {
        title: 'Production Name',
        data: 'projectInfo.projectName',
        defaultContent: ''
      },
      {
        title: 'Production Type',
        data: 'form.productionDetails.productionType',
        defaultContent: ''
      },
      {
        title: 'Estimated LA Expenditure',
        data: 'form.expenditures.laExpenditure',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Estimated LA Payroll',
        data: 'form.expenditures.residentPayroll',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Total Reservation Amount',
        data: 'form.totalCreditsReserved',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Application Received Date',
        name: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        data: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Initial Cert Issuance Date',
        name: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        data: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Eligible Expenditure Start Date',
        name: 'form.initialCertCriticalDates.eligibleExpenditureStartDate',
        data: 'form.initialCertCriticalDates.eligibleExpenditureStartDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Eligible Expenditure End Date',
        name: 'form.initialCertCriticalDates.eligibleExpenditureEndDate',
        data: 'form.initialCertCriticalDates.eligibleExpenditureEndDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Total Estimated Jobs',
        data: 'form.estimatedJobs.totalJobs',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 0, ''),
        type: 'num'
      }
    ]
  },
  [incentiveProgram.dm.code]: {
    [formTypes.initialCert.abbrev]: [
      {
        title: 'Project ID',
        data: 'projectInfo.projectId',
        defaultContent: '',
        type: 'html'
      },
      {
        title: 'DM ID',
        data: 'projectInfo.digitalId',
        defaultContent: ''
      },
      {
        title: 'Project Name',
        data: 'projectInfo.projectName',
        defaultContent: ''
      },
      {
        title: 'Project Type',
        data: 'form.projectDetails.projectType',
        defaultContent: ''
      },
      {
        title: 'Application Received Date',
        name: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        data: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Initial Cert Issuance Date',
        name: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        data: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Estimated Labor Total',
        data: 'form.estimatedExpenditures.totalEligibleLabor',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Estimated Non Labor Total',
        data: 'form.estimatedExpenditures.totalEligibleNonLabor',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Estimated Expenditure Total',
        data: 'form.estimatedExpenditures.totalEligibleExpenditure',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Total Estimated Jobs',
        data: 'form.estimatedExpenditures.totalEstimatedJobs',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 0, ''),
        type: 'num'
      },
      {
        title: 'Total Employees',
        data: 'form.estimatedExpenditures.totalEmployees',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 0, ''),
        type: 'num'
      }
    ]
  }
};

export const publicColumnDefinitions = {
  [incentiveProgram.film.code]: {
    [formTypes.initialCert.abbrev]: [
      {
        title: 'Project ID',
        data: 'projectInfo.projectId',
        defaultContent: '',
        type: 'html'
      },
      {
        title: 'Film ID',
        data: 'projectInfo.filmId',
        defaultContent: ''
      },
      {
        title: 'Production Name',
        data: 'projectInfo.projectName',
        defaultContent: ''
      },
      {
        title: 'Production Type',
        data: 'form.productionDetails.productionType',
        defaultContent: ''
      },
      {
        title: 'Estimated Total Budget',
        data: 'form.expenditures.totalBudget',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Estimated LA Expenditure',
        data: 'form.expenditures.laExpenditure',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Estimated LA Payroll',
        data: 'form.expenditures.residentPayroll',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      // {
      //   title: 'Total Reservation Amount',
      //   data: 'form.totalCreditsReserved',
      //   defaultContent: '',
      //   render: $.fn.dataTable.render.number(',', '.', 2, '$'),
      //   type: 'num-fmt'
      // },
      {
        title: 'Application Received Date',
        name: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        data: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Initial Cert Issuance Date',
        name: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        data: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        defaultContent: '',
        render: formatDate
      }
      // ,
      // {
      //   title: 'Eligible Expenditure Start Date',
      //   name: 'form.initialCertCriticalDates.eligibleExpenditureStartDate',
      //   data: 'form.initialCertCriticalDates.eligibleExpenditureStartDate',
      //   defaultContent: '',
      //   render: formatDate
      // },
      // {
      //   title: 'Eligible Expenditure End Date',
      //   name: 'form.initialCertCriticalDates.eligibleExpenditureEndDate',
      //   data: 'form.initialCertCriticalDates.eligibleExpenditureEndDate',
      //   defaultContent: '',
      //   render: formatDate
      // },
      // {
      //   title: 'Total Estimated Jobs',
      //   data: 'form.estimatedJobs.totalJobs',
      //   defaultContent: '',
      //   render: $.fn.dataTable.render.number(',', '.', 0, ''),
      //   type: 'num'
      // }
    ]
  },
  [incentiveProgram.dm.code]: {
    [formTypes.initialCert.abbrev]: [
      {
        title: 'Project ID',
        data: 'projectInfo.projectId',
        defaultContent: '',
        type: 'html'
      },
      {
        title: 'DM ID',
        data: 'projectInfo.digitalId',
        defaultContent: ''
      },
      {
        title: 'Project Name',
        data: 'projectInfo.projectName',
        defaultContent: ''
      },
      {
        title: 'Company Name',
        data: 'projectInfo.companyName',
        defaultContent: ''
      },
      {
        title: 'Project Type',
        data: 'form.projectDetails.projectType',
        defaultContent: ''
      },
      {
        title: 'Application Received Date',
        name: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        data: 'form.initialCertCriticalDates.relatedApplicationReceivedDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Initial Cert Issuance Date',
        name: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        data: 'form.initialCertCriticalDates.initialCertIssuanceDate',
        defaultContent: '',
        render: formatDate
      },
      {
        title: 'Estimated Labor Total',
        data: 'form.estimatedExpenditures.laborTotal',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Estimated Non Labor Total',
        data: 'form.estimatedExpenditures.nonLaborTotal',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      },
      {
        title: 'Estimated Expenditure Total',
        data: 'form.estimatedExpenditures.expenditureTotal',
        defaultContent: '',
        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
        type: 'num-fmt'
      }
      // ,
      // {
      //   title: 'Total Estimated Jobs',
      //   data: 'form.estimatedExpenditures.totalEstimatedJobs',
      //   defaultContent: '',
      //   render: $.fn.dataTable.render.number(',', '.', 0, ''),
      //   type: 'num'
      // },
      // {
      //   title: 'Total Employees',
      //   data: 'form.estimatedExpenditures.totalEmployees',
      //   defaultContent: '',
      //   render: $.fn.dataTable.render.number(',', '.', 0, ''),
      //   type: 'num'
      // }
    ]
  }
};

export function formatDate(data, type, row, meta) {
  if (!data) {
    return null;
  }
  return moment(data).format('MM/DD/YYYY');
}

export interface DataTableColumnDefinition {
  title: string;
  data?: string;
  defaultContent?: string;
  render?: any;
}

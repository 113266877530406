import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EligibilityQuestions } from '../../../models/digital/eligibility-questions.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormValidationService } from '../../../../form/services/validation.service';
import { FormShareService } from '../../../../form/services/share.service';
import { formTypes } from '../../../../form/form.constants';

@Component({
  selector: 'fl-eligibility-questions',
  templateUrl: './eligibility-questions.component.html',
  styleUrls: ['./eligibility-questions.component.scss']
})
export class EligibilityQuestionsComponent implements OnInit, OnDestroy {
  @Input() eligibilityQuestions: EligibilityQuestions;
  @Input() form: FormGroup;
  @Input() readOnly: boolean;
  private subscriptions: Subscription[] = new Array<Subscription>();
  public eligibilityQuestionsFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private validationService: FormValidationService,
    private formShareService: FormShareService
  ) {}
  // #region "Custom Methods"
  /**
   * @summary Clear validation depending on formTypes
   */
  disableValidation() {
    if (
      this.formShareService.form.type === formTypes.initialCert.abbrev ||
      this.formShareService.form.type === formTypes.audit.abbrev
    ) {
      for (const i in this.eligibilityQuestionsFormGroup.controls) {
        if (
          <Object>this.eligibilityQuestionsFormGroup.controls.hasOwnProperty(i)
        ) {
          this.eligibilityQuestionsFormGroup.get(i).clearValidators();
          this.eligibilityQuestionsFormGroup.get(i).updateValueAndValidity();
        }
      }
    }
  }
  // #endregion
  // #region "System Methods"
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }
  ngOnInit() {
    const that = this;
    this.eligibilityQuestionsFormGroup = this.formBuilder.group({
      isProjectForInstitutionalPrivateInternal: ['', [Validators.required]],
      isProjectForStateAgency: ['', [Validators.required]],
      isProjectFinancedByFundingGrantsInState: ['', [Validators.required]],
      isProjectCommericallyAvailable: ['', [Validators.required]],
      isProjectSubContracted: ['', [Validators.required]],
      isExpenditureInState: ['', [Validators.required]],
      isEquipmentPurchaseInState: ['', [Validators.required]],
      isEmployeesPerformingServicesInState: ['', [Validators.required]],
      isDigitalMediaRelatedToDevelopmentOfProject: ['', [Validators.required]],
      isCompanySubContractorInvestorWithLEDPrograms: [
        '',
        [Validators.required]
      ],
      companySubContractorInvestorWithLEDProgramsDetails: [''],
      isMultipleProjectBeingDeveloped: ['', [Validators.required]],
      isExpenditureRelatesToExistingProduct: ['', [Validators.required]],
      isAnticipateRelatedPartyTransactions: ['', [Validators.required]]
    });

    // Insert the value into the input
    this.eligibilityQuestionsFormGroup.patchValue(this.eligibilityQuestions);
    // Subscribe to form model changes
    const formModelSubscription = this.eligibilityQuestionsFormGroup.valueChanges.subscribe(
      () => {
        // Clear textbox if user answer, "no"
        if (
          that.eligibilityQuestionsFormGroup.get(
            'isCompanySubContractorInvestorWithLEDPrograms'
          ).value === false
        ) {
          that.eligibilityQuestionsFormGroup
            .get('companySubContractorInvestorWithLEDProgramsDetails')
            .setValue(null, { emitEvent: false });
        }

        // Invalid the fields if the user answer these question, "yes"
        // #1. isProjectForInstitutionalPrivateInternal
        if (
          that.eligibilityQuestionsFormGroup.get(
            'isProjectForInstitutionalPrivateInternal'
          ).value === true
        ) {
          that.eligibilityQuestionsFormGroup
            .get('isProjectForInstitutionalPrivateInternal')
            .setErrors({ invalid: true });
        }
        // #2. isProjectForStateAgency
        if (
          that.eligibilityQuestionsFormGroup.get('isProjectForStateAgency')
            .value === true
        ) {
          that.eligibilityQuestionsFormGroup
            .get('isProjectForStateAgency')
            .setErrors({ invalid: true });
        }
        // #3. isProjectFinancedByFundingGrantsInState
        if (
          that.eligibilityQuestionsFormGroup.get(
            'isProjectFinancedByFundingGrantsInState'
          ).value === true
        ) {
          that.eligibilityQuestionsFormGroup
            .get('isProjectFinancedByFundingGrantsInState')
            .setErrors({ invalid: true });
        }
        // #4. isProjectCommericallyAvailable
        if (
          that.eligibilityQuestionsFormGroup.get(
            'isProjectCommericallyAvailable'
          ).value === true
        ) {
          that.eligibilityQuestionsFormGroup
            .get('isProjectCommericallyAvailable')
            .setErrors({ invalid: true });
        }
        // #5. isProjectSubContracted
        if (
          that.eligibilityQuestionsFormGroup.get('isProjectSubContracted')
            .value === true
        ) {
          that.eligibilityQuestionsFormGroup
            .get('isProjectSubContracted')
            .setErrors({ invalid: true });
        }

        // Merge control values to object.
        Object.assign(
          that.eligibilityQuestions,
          that.eligibilityQuestionsFormGroup.value
        );

      }
    );
    this.subscriptions.push(formModelSubscription);
    // Add FormGroup to validation Service
    this.validationService.form.setControl(
      'eligibilityQuestions',
      this.eligibilityQuestionsFormGroup
    );
    this.disableValidation();
  }
  // #endregion
}

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControlOptions
} from '@angular/forms';
import { ProjectDetailService } from '../../project/services/project-shared.service';
import {
  calculateAuditAssessedDeposit,
  auditDepositCap,
  outgoingPaymentStatuses,
  getPopOverLegends
} from '../../fiscal/fiscal.constants';
import { AuditDepositCreator } from '../interfaces/audit-deposit-creator.interface';
import { FormIdentifier } from '../../project/models/form-identifier.model';
import { formTypes, formStatus, formStatuses } from '../../form/form.constants';
import { FormValidationService } from '../../form/services/validation.service';
import { UserContextService } from '../../user/services/user-context.service';
import { EntertainmentProject } from '../interfaces/project.interface';
import * as _ from 'underscore';
import { feeTypes } from '../../fiscal/fiscal.constants';
import { Subscription } from 'rxjs';
import { PublishSubscribeService } from '../../fastlane-common/services/publish-subscribe.service';
import {
  events,
  publishSubscribeEventStrings
} from '../../fastlane-common/event/event.constants';
import { FeeCalculations } from '../../fiscal/models/fee-calculations.model';
import { FormActionService } from '../../form/services/action.service';
import { FormAction } from '../../form/form.constants';
import { NavService } from '../../fastlane-common/services/nav.service';
import { FormShareService } from '../../form/services/share.service';
import { StatusLog } from '../../project/models/status-log.model';
import { ExpenditurePerCreditType } from '../credit.constants';
import {
  expenditureActions,
  auditDepositTransactionActions
} from '../../project/project.constants';
import { ProjectFormWrapper } from '../../project/models/project-form-wrapper.model';
import { ProjectInfoBase } from '../../project/models/project-info-base.model';
import { ProjectForm } from '../../project/models/form.model';
import { EventContext } from '../../fastlane-common/event/interfaces/event-context.interface';
import { OutgoingPayment } from '../../fiscal/models/outgoing-payment.model';
import { PaymentProject } from '../../fiscal/models/payment.project.model';
import { SwalService } from '../../fastlane-common/services/swal.service';
import { FormDataService } from '../../form/services/data.service';
import { Evr } from '../models/digital/expenditure-verification.model';
import { Audit } from '../models/film/audit.model';

declare var $: any;

@Component({
  selector: 'fl-audit-deposit',
  templateUrl: './audit-deposit.component.html',
  styleUrls: ['./audit-deposit.component.scss']
})
export class AuditDepositComponent implements OnInit, OnDestroy {
  //#region Properties
  legislation: string;
  isAudit =
    (this.formService.formId.formType === formTypes.audit.abbrev || this.formService.formId.formType === formTypes.evr.abbrev) ? true : false;
  isApplication =
    this.formService.formId.formType === formTypes.application.abbrev ? true : false;
  // Form used for validations
  depositForm: FormGroup = this.fb.group({
    laExpenditure: new FormControl({ value: 0, disabled: true }),
    // tslint:disable-next-line:max-line-length
    assessedDeposit: new FormControl(
      Validators.max(
        auditDepositCap[
          this.projectSharedService.project.projectInfo.incentiveProgram
        ][this.legislation]
      )
    ),
    amountPaid: new FormControl(0),
    depositAdjustment: new FormControl(0, <AbstractControlOptions>{
      updateOn: 'blur'
    }),
    auditCost: new FormControl(0, { updateOn: 'blur' }),
    amountDue: new FormControl(
      0,
      !this.isAudit ? Validators.max(0) : Validators.nullValidator
    )
  });
  isDM = this.projectSharedService.project.projectInfo.incentiveProgram == "DM";
  // tslint:disable-next-line:max-line-length
  assessedDepositCap =
    auditDepositCap[
      this.projectSharedService.project.projectInfo.incentiveProgram
    ][this.projectSharedService.project.projectInfo.legislation];

  popOverLegend =
    getPopOverLegends[
      this.projectSharedService.project.projectInfo.incentiveProgram
    ][this.projectSharedService.project.projectInfo.legislation];

  // A form that implements Expenditures Interface
  @Input() form: AuditDepositCreator;
  @Input() requiredActions: FormAction[] = [];

  private subs: Subscription[] = [];

  get amountDue() {
    return this.depositForm.get('amountDue').value;
  }
  //#endregion

  //#region  LifeCycleHooks
  constructor(
    private fb: FormBuilder,
    private projectSharedService: ProjectDetailService,
    private validationService: FormValidationService,
    private userContextServ: UserContextService,
    private pubSubServ: PublishSubscribeService,
    private formActionService: FormActionService,
    public navService: NavService,
    private formService: FormShareService,
    private cdr: ChangeDetectorRef,
    private swalService: SwalService,
    private dataService: FormDataService
  ) {}

  ngOnDestroy(): void {
    // Do not leave any open subscriptions
    this.subs.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    const that = this;
    // Enable Legend Popover
    this.enableLegendPopover();

    // Generate a new matching auditFormId if not already set.
    if (!this.form.auditFormId) {
      this.form.auditFormId = this.getAuditFormIdentifier();
    }

    if (!(this.isManager() || this.isAudit)) {
      this.validationService.form.addControl('depositForm', this.depositForm);
    }

    // Populate form details
    this.populateFeeDetailsFromAudit();

    // refresh the deposit details
    that.updateDepositDetails(that.form);

    // Monitor changes to validation form and refresh the deposit details
    let sub = this.pubSubServ.handle<ExpenditurePerCreditType>(
      events.expenditureChanged.code,
      expenditureActions.laExpenditure,
      amount => that.updateDepositDetails(that.form)
    );
    this.subs.push(sub);

    // Monitor changes to deposit adjustment
    sub = this.depositForm
      .get('depositAdjustment')
      .valueChanges.subscribe(value => that.updateDepositDetails(that.form));
    this.subs.push(sub);

    // Monitor changes to audit cost
    sub = this.depositForm.get('auditCost').valueChanges.subscribe(value => {
      that.updateDepositDetails(that.form);
    });
    this.subs.push(sub);
    this.subs.push(
      that.pubSubServ
        .on(publishSubscribeEventStrings.amountDueChanged)
        .subscribe(() => {
          that.updateDepositDetails(that.form);
        })
    );
    this.cdr.detectChanges();
  }

  //#endregion

  //#region EventHandlers

  navigateToAudit() {
    const that = this;

    // Make sure the source of the audit is set to application. This is only available from within application
    // as user intends to navigate to pay deposit
    this.setAuditSourceFormId();

    this.saveProject().then(() => {
      this.navService.navigateToUrlTree(
        this.navService.getFormUrlTreeForCurrentUserContext(
          this.getAuditFormIdentifier()
        )
      );
    });
  }

  shouldShowAuditCost() {
    // Show the field if manager or if Audit Cost greater than zero
    if (this.isManager() || this.depositForm.get('auditCost').value > 0) {
      return true;
    }

    return false;
  }

  getAuditFees() {
    // Update the Audit form with the calculated details
      const auditForm = this.projectSharedService.filmProject.getProjectForm(
        this.getAuditFormIdentifier()
      );

    return auditForm.fees;
  }

  requestPaymentorRefund() {
    // Ensure the most up to date deposit detail information.
    // This works to ensure migrated data without an amountDue gets set.
    this.updateDepositDetails(this.form);

    const that = this;
    if (this.amountDue < 0) {
      // Request Refund.
      // 1. Add outgoing payments to the payment collection.
      // 2. Email Fiscal to make payment to the applicant.
      const outGoingPayment = new OutgoingPayment();
      outGoingPayment.outgoingPaymentStatuses = [
        new StatusLog({
          status: outgoingPaymentStatuses.notPaid.name,
          statusDate: new Date(),
          creator: that.userContextServ.currentUser.id
        })
      ];
      outGoingPayment.createDate = new Date();
      outGoingPayment.paymentProject = <PaymentProject>{
        feeType: feeTypes.audit.upperAbbrev,
        projectId: that.projectSharedService.project.projectInfo.projectId,
        incentiveProgram:
          that.projectSharedService.project.projectInfo.incentiveProgram,
        paidAmount: that.amountDue,
        formId: that.formService.formId
      };
      outGoingPayment.totalPaidAmount = that.amountDue;
      outGoingPayment.paymentMethod = '';
      outGoingPayment.fiscalNotificationDate = new Date();
      // Send Email.
      that.swalService.load('Notifying fiscal about the refund...');
      const context = <EventContext<ProjectFormWrapper>>{
        data: <ProjectFormWrapper>{
          form: that.formService.filmForm as ProjectForm,
          projectInfo: that.projectSharedService.filmProject
            .projectInfo as ProjectInfoBase,
          contacts: that.projectSharedService.filmProject.projectContacts,
          projectGuid: that.projectSharedService.filmProject.id
        },
        eventType: events.auditDepositTransaction.code,
        eventAction: auditDepositTransactionActions.requestRefund
      };
      // Check if the payments collection have unpaid outgoing payment for this form.
      this.dataService
        .checkPendingOutgoingPaymentsForForm(that.formService.formId)
        .subscribe(response => {
          if (
            response.id &&
            response.outgoingPaymentStatuses &&
            response.outgoingPaymentStatuses[0].status ===
              outgoingPaymentStatuses.notPaid.name
          ) {
            Object.assign(response, outGoingPayment);
            that.dataService
              .updateOutgoingPayment(response)
              .subscribe(success => {
                that.swalService.success({
                  title: 'Notified Fiscal Sucessfully',
                  text: 'Email has been sent to fiscal to make the payment.'
                });
              });
          } else {
            this.createOutgoingPayment(outGoingPayment, context);
          }
        });
    } else {
      // Request Payment
      // 1. Set Payment Status to Pending Payment
      // 2. Email the applicants to make the payment

      that.formService.form.statuses.unshift(
        new StatusLog({
          status: formStatuses.pendingPayment.name,
          statusDate: new Date(),
          creator: that.userContextServ.currentUser.id
        })
      );
      const context = <EventContext<ProjectFormWrapper>>{
        data: <ProjectFormWrapper>{
          form: that.formService.filmForm as ProjectForm,
          projectInfo: that.projectSharedService.filmProject
            .projectInfo as ProjectInfoBase,
          contacts: that.projectSharedService.filmProject.projectContacts,
          projectGuid: that.projectSharedService.filmProject.id
        },
        eventType: events.auditDepositTransaction.code,
        eventAction: auditDepositTransactionActions.requestPayment
      };
      that
        .saveProject()
        .then(() => {
          that.pubSubServ.raise(
            events.auditDepositTransaction.code,
            auditDepositTransactionActions.requestPayment,
            context
          );
        })
        .catch(() => {});
    }
  }
  //#endregion

  //#region HelperFunctions

  /**
   * @summary Use this method for comparing two fee calculations in order to avoid duplications
   * @param oldFee old computed fee from audit.fees[0]
   * @param newFee  new computed fee or deposit detail
   */
  isSameFee(oldFee: FeeCalculations, newFee: FeeCalculations) {
    return (
      oldFee.amountDue === newFee.amountDue &&
      oldFee.assessedFee === newFee.assessedFee &&
      // tslint:disable-next-line:triple-equals - The use of == below is key because we want null == undefined to return true.
      oldFee.isRefundable == newFee.isRefundable
    );
  }

  /**
   * @summary Use this method to get the total amount paid toward the deposit of an applicable audit.
   */
  getAmountPaid() {
    // Entertainment Applications will generate the formId of the next audit when it is first created. The application
    // should preserve the auditFormId for events in which user is reingaged by asking for additional information via INFO REQ STATUS
    var auditPayments;
    if (this.isDM) {
      auditPayments = _.filter(
        this.projectSharedService.payments,
        payment =>
          payment.paymentProject.feeType ===
          feeTypes[formTypes.evr.abbrev].upperAbbrev &&
          this.getAuditFormIdentifier().formIndex ===
          payment.paymentProject.formId.formIndex);
    }
    else {
      auditPayments = _.filter(
        this.projectSharedService.payments,
        payment =>
          payment.paymentProject.feeType ===
          feeTypes[formTypes.audit.abbrev].upperAbbrev &&
          this.getAuditFormIdentifier().formIndex ===
          payment.paymentProject.formId.formIndex
      );
    }

    // Sum up all payments
    return auditPayments.reduce((prev, next) => prev + next.totalPaidAmount, 0);
  }

  /**
   * @summary Generates a new formId that corresponds to a new Audit.
   * If no audit exists, the first audit will be created. This will primarily be used for linking payments indirectly.
   */
  getAuditFormIdentifier() {
    // Is there already an identifier used?
    if (this.form.auditFormId) {
      return this.form.auditFormId;
    }

    // Find highest formIndex for audits of this project
    var project;
    var lastAudit;

    if (this.isDM) {
      project = this.projectSharedService.digitalProject as EntertainmentProject;
      lastAudit = project.evr && project.evr.length > 0
        ? project.evr[project.evr.length - 1]
        : null;
    }
    else {
      project = this.projectSharedService.filmProject as EntertainmentProject;
      lastAudit = project.audit && project.audit.length > 0
          ? project.audit[project.audit.length - 1]
          : null;
    }

    // Generate and save the new form Identifier
    const newFormIdentifier = this.isDM
      ? <FormIdentifier>{
        formIndex: lastAudit ? lastAudit.formIndex + 1 : 0,
        formType: formTypes.evr.abbrev,
        projectGuid: this.projectSharedService.project.id
      } 
      : <FormIdentifier>{
        formIndex: lastAudit ? lastAudit.formIndex + 1 : 0,
        formType: formTypes.audit.abbrev,
        projectGuid: this.projectSharedService.project.id
      };

    // Set the new formIdentifier in the parent form
    this.form.auditFormId = newFormIdentifier;

    // if this is an application, make sure the sourceformid is set to the application so it doesn't default to an initialCert :^)
    if (this.isApplication) {
      const newAudit = this.isDM ? this.projectSharedService.digitalProject.getProjectForm(newFormIdentifier) as Evr : this.projectSharedService.filmProject.getProjectForm(newFormIdentifier) as Audit;

      newAudit.sourceFormId = <FormIdentifier>{
        formIndex: null,
        formType: formTypes.application.abbrev,
        projectGuid: this.projectSharedService.project.id
      }
    }
    // Create the new audit and initialize it

    // Push the deposit information to index 0 of auditDeposits array

    // Compute the next available formIdentifier
    return newFormIdentifier;
  }

  updateDepositDetails(form: AuditDepositCreator) {
    // Update the expenditure field
    const laExpenditureAmount = form.laExpenditure;
    this.depositForm.get('laExpenditure').patchValue(laExpenditureAmount);

    // See if there is already an assessed deposit
    let assessedDeposit = this.depositForm.get('assessedDeposit').value;

    // Update the Audit form with the calculated details
    const auditForm = this.projectSharedService.filmProject.getAuditDepositCreatorForm(
      this.getAuditFormIdentifier()
    );

    // Sync up the la expenditure for both application and audit viewing cases
    auditForm.laExpenditure = laExpenditureAmount;

    // Cost of the audit as provided by manager
    const auditCost = (this.depositForm.get('auditCost').value as number) || 0;

    // Get deposit Adjustment
    let depositAdjustment =
      (this.depositForm.get('depositAdjustment').value as number) || 0;

    // if previous fees has assessed Deposit + adjusted deposit then do not recalculate assessed fee
    const isDepositAdjusted =
      auditForm.fees.length > 0
        ? auditForm.fees[0].isDepositAdjusted
          ? true
          : depositAdjustment > 0
          ? true
          : false
        : false;

    const calculatedDeposit = calculateAuditAssessedDeposit(
      this.projectSharedService.projectBase.projectInfo.incentiveProgram,
      laExpenditureAmount,
      this.projectSharedService.projectBase.projectInfo.legislation
    );
    // If assessed deposit was not adjusted then use calculatedDeposit
    if (!isDepositAdjusted) {
      assessedDeposit = calculatedDeposit;
    } else {
      // if assessed deposit was adjusted then use calculated deposit as assessed deposit
      // only if calculated Deposit is greater than adjusted deposit
      assessedDeposit =
        calculatedDeposit > assessedDeposit
          ? calculatedDeposit
          : assessedDeposit >= 0
          ? assessedDeposit
          : 0;
    }

    // If Audit Cost is greater than zero, it becomes the new assessed deposit
    if (auditCost > 0) {
      assessedDeposit = auditCost;
      depositAdjustment = 0;

      // Overwrite the value and make sure an event is not emitted to avoid unlimited call stack
      this.depositForm
        .get('depositAdjustment')
        .patchValue(0, { emitEvent: false });
    }

    // Get total amount paid from payment collection
    const amountPaid = this.getAmountPaid();

    // Get total amount due
    const amountDue = !auditCost
      ? assessedDeposit + depositAdjustment - amountPaid
      : auditCost - amountPaid;
    this.depositForm.get('amountDue').patchValue(amountDue);

    const depositDetails = <FeeCalculations>{
      assessedFee: assessedDeposit + depositAdjustment,
      amountDue: amountDue,
      amountPaid: amountPaid,
      isDepositAdjusted: isDepositAdjusted,
      isRefundable: auditCost === 0 ? true : null // Only refundable until the audit cost is saved.
    };

    // Attempt to get the last fee or a new empty calculation for comparisons below.
    const lastRecordedFee =
      auditForm.fees && auditForm.fees.length > 0
        ? auditForm.fees[0]
        : new FeeCalculations();

    if (!this.isSameFee(lastRecordedFee, depositDetails)) {
      // Store new Deposit Details in Audit Form
      auditForm.fees.unshift(depositDetails);
      if (depositDetails.amountDue > 0 && !this.isManager()) {
        this.changeAuditFormStatus(auditForm, formStatus.pendingPayment);
      }
    }

    // Update form model assessedDeposit value
    this.depositForm
      .get('assessedDeposit')
      .patchValue(depositDetails.assessedFee);

    // Ensure amountPaid shows latest calculation
    this.depositForm.get('amountPaid').patchValue(depositDetails.amountPaid);
  }

  populateFeeDetailsFromAudit() {
    // Get the corresponding Audit form with the calculated details
    const auditForm = this.projectSharedService.filmProject.getProjectForm(
      this.getAuditFormIdentifier()
    );   

    // Get last fee and determine whether or not it was refundable to use as audit cost
    const lastFee =
      auditForm.fees && auditForm.fees.length > 0
        ? auditForm.fees[0]
        : new FeeCalculations();
    if (lastFee.assessedFee > 0 && lastFee.isRefundable) {
      // This is assessed deposit
      this.depositForm.get('assessedDeposit').patchValue(lastFee.assessedFee);
    } else {
      this.depositForm.get('auditCost').patchValue(lastFee.assessedFee);
    }
    this.depositForm.get('amountPaid').patchValue(lastFee.amountPaid);
  }

  changeAuditFormStatus(auditForm: AuditDepositCreator, status: string) {
    if (
      auditForm.statuses.length === 0 ||
      auditForm.statuses[0].status !== status
    ) {
      // Only change status if different from the current status
      const newStatus = <StatusLog>{ status: status, statusDate: new Date() };

      newStatus.creator = 'fastlane';

      auditForm.statuses.unshift(newStatus);
    }
  }

  isManager() {
    return this.userContextServ.isUserManager();
  }

  enableLegendPopover() {
    $(function() {
      $('[data-toggle="popover"]').popover();
    });
  }

  setAuditSourceFormId() {
    const formId = this.getAuditFormIdentifier();
    this.projectSharedService.projectBase[formId.formType][formId.formIndex][
      'sourceFormId'
    ] = this.formService.formId;
  }

  saveProject() {
    return this.formActionService.exec('save');
  }

  createOutgoingPayment(
    outgoingPayment: OutgoingPayment,
    context: EventContext<ProjectFormWrapper>
  ) {
    const that = this;
    this.subs.push(
      that.dataService
        .saveOutgoingPayment(outgoingPayment)
        .subscribe(newPayment => {
          outgoingPayment.id = newPayment.id;
          that.dataService.saveForm().subscribe(success => {
            that.pubSubServ
              .raise(
                events.auditDepositTransaction.code,
                auditDepositTransactionActions.requestRefund,
                context
              )
              .subscribe(done => {
                that.swalService.success({
                  title: 'Notified Fiscal Sucessfully',
                  text: 'Email has been sent to fiscal to make the payment.'
                });
              });
          });
        })
    );
  }
  //#endregion
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FiscalModule } from '../fiscal/fiscal.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
// import { IncentiveModule } from '../incentive/incentive.module';
import { LoggedInComponent } from './layouts/logged-in/logged-in.component';
import { LoginComponent } from './components/login/login.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PrintModule } from '../print/print.module';
import { ProjectModule } from '../project/project.module';
import { SecurityModule } from '../security/security.module';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { RegisterComponent } from './components/register/register.component';
import { VerifyComponent } from './components/verify/verify.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PublicReportsComponent } from './public-reports/public-reports.component';
import { DocsComponent } from './docs/docs.component';
import { HelpComponent } from './help/help.component';
import { AdvancePublicSearchComponent } from './public-search/advance-public-search/advance-public-search.component';
import { PublicSearchComponent } from './public-search/public-search.component';
import { QjPublicSearchComponent } from './public-search/qj-public-search/qj-public-search.component';
import { RtaPublicSearchComponent } from './public-search/rta-public-search/rta-public-search.component';
import { ItePublicSearchComponent } from './public-search/ite-public-search/ite-public-search.component';
import { EzPublicSearchComponent } from './public-search/ez-public-search/ez-public-search.component';
import { LandingComponent } from './landing/landing.component';
import { PublicEntSearchComponent } from './public-ent-search/public-ent-search.component';

@NgModule({
  imports: [
    CommonModule,
    FiscalModule,
    ReactiveFormsModule,
    PrintModule,
    ProjectModule,
    RouterModule,
    SecurityModule,
    SharedModule,
    UserModule,
    FormsModule,
    NgxPageScrollModule
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoggedInComponent,
    LoginComponent,
    NavigationComponent,
    RegisterComponent,
    RegisterComponent,
    VerifyComponent,
    SettingsComponent,
    PublicReportsComponent,
    DocsComponent,
    HelpComponent,
    AdvancePublicSearchComponent,
    PublicSearchComponent,
    QjPublicSearchComponent,
    RtaPublicSearchComponent,
    ItePublicSearchComponent,
    EzPublicSearchComponent,
    LandingComponent,
    PublicEntSearchComponent
  ],
  exports: [
    FiscalModule,
    LoginComponent,
    LoggedInComponent,
    PrintModule,
    ProjectModule,
    RouterModule,
    SharedModule,
    UserModule,
    RegisterComponent,
    VerifyComponent,
    DocsComponent,
    HelpComponent,
    AdvancePublicSearchComponent,
    PublicSearchComponent,
    QjPublicSearchComponent,
    RtaPublicSearchComponent,
    ItePublicSearchComponent,
    EzPublicSearchComponent
  ],
  providers: []
})
export class CoreModule {}

export class AuthorizationPayload {
  public audience: string;
  public expiration: number;
  public issuer: string;
  public not_before: number;
  public role: string;
  public subject: string;
  public unique_name: string;
  public username: string;
  public userGuid: string;

  constructor(payload?: any) {
    if (!payload) {
      payload = {};
    }
    this.audience = payload.aud || null;
    this.expiration = payload.exp || null;
    this.issuer = payload.iss || null;
    this.not_before = payload.nbf || null;
    this.role = payload.role || null;
    this.unique_name = payload.unique_name || null;
    this.username = payload.actort || null;
    this.subject = payload.sub || null;
    this.userGuid = payload.userId || null;
  }
}

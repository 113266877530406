import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../security/services/authentication.service';
import { environment } from '../../../environments/environment';
import { IteTaxRateLookup } from '../models/lookup/ite-tax-rate-lookup';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProgramManagerLookup } from '../models/lookup/program-manager-lookup';
import { User } from '../../user/models/user.model';
import { chain } from 'underscore';
import { AuditingCompany } from '../models/lookup/auditing-company.model';
import {
  HttpClient,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

@Injectable()
export class LookupHttpService {
  private readonly getIteTaxRatesLookupUrl = `${
    environment.apiUrl
  }/lookup/iteTaxRates`;
  private readonly getProgramManagersLookupUrl = `${
    environment.apiUrl
  }/lookup/programManagers`;
  private readonly getAgreementReviewersLookupUrl = `${
    environment.apiUrl
  }/lookup/agreementReviewers`;
  private readonly getIteTaxRateLookupUrl = `${
    environment.apiUrl
  }/lookup/iteTaxRate`;
  private readonly getProgramManagerLookupUrl = `${
    environment.apiUrl
  }/lookup/programManager`;
  private readonly addIteTaxRateLookupUrl = `${
    environment.apiUrl
  }/lookup/createIteTaxRate`;
  private readonly updateIteTaxRateLookupUrl = `${
    environment.apiUrl
  }/lookup/updateIteTaxRate`;
  private readonly updateProgramManagerLookupUrl = `${
    environment.apiUrl
  }/lookup/updateProgramManager`;
  private readonly getActiveAuditingCompaniesUrl = `${
    environment.apiUrl
  }/lookup/auditingCompanies`;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) {}

  getActiveAuditingCompanies(): Observable<AuditingCompany[]> {
    return this.http
      .get(
        this.getActiveAuditingCompaniesUrl,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(
        map((res: AuditingCompany[]) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  // TODO: Map the response value to return the new model
  public getIteTaxLookup(): Observable<IteTaxRateLookup[]> {
    return this.http
      .get(this.getIteTaxRatesLookupUrl, this.authService.getAuthOptions())
      .pipe(
        map((res: IteTaxRateLookup[]) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  // TODO: Map the response value to return the new model
  public getProgramManagerLookup(): Observable<
    { program: string; user: User }[]
  > {
    return this.http
      .get(this.getProgramManagersLookupUrl, this.authService.getAuthOptions())
      .pipe(
        map((res: any) => {
          if (res) {
            const rawUserData = res;
            const programManagers: { program: string; user: User }[] = chain(
              rawUserData
            )
              .pairs()
              .map(pair => {
                const programManager = {
                  program: pair[0],
                  user: Object.assign(new User(), pair[1])
                };
                programManager.user.init();
                return programManager;
              })
              .value();
            return programManagers;
          }
          return [];
        })
      )
      .pipe(catchError(this.handleError));
  }

  public getAgreementReviewerLookup(): Observable<
    { program: string; user: User }[]
  > {
    return this.http
      .get(
        this.getAgreementReviewersLookupUrl,
        this.authService.getAuthOptions()
      )
      .pipe(
        map((res: any) => {
          if (res) {
            const rawUserData = res;
            const agreementReviewers: { program: string; user: User }[] = chain(
              rawUserData
            )
              .pairs()
              .map(pair => {
                const agreementReviewer = {
                  program: pair[0],
                  user: Object.assign(new User(), pair[1])
                };
                agreementReviewer.user.init();
                return agreementReviewer;
              })
              .value();
            return agreementReviewers;
          }
          return [];
        })
      )
      .pipe(catchError(this.handleError));
  }

  // TODO: Map the response value to return the new model
  public getIteTaxRate(
    year: number,
    parish: string
  ): Observable<IteTaxRateLookup> {
    const url =
      this.getIteTaxRateLookupUrl + `/${year}/` + encodeURIComponent(parish);

    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(
        map((res: IteTaxRateLookup) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  public getProgramManager(program: string): Observable<User> {
    const url = this.getProgramManagerLookupUrl + `/${program}`;

    return this.http
      .get(url, this.authService.getAuthOptions())
      .pipe(
        map((res: User) => {
          if (res) {
            const user: User = Object.assign(new User(), res);
            user.init();
            return user;
          }

          return <User>{};
        })
      )
      .pipe(catchError(this.handleError));
  }

  createIteTaxRate(iteTaxRate: IteTaxRateLookup): Observable<IteTaxRateLookup> {
    return this.http
      .post(
        this.addIteTaxRateLookupUrl,
        iteTaxRate,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(
        map((res: IteTaxRateLookup) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  updateIteTaxRate(iteTaxRate: IteTaxRateLookup): Observable<IteTaxRateLookup> {
    return this.http
      .put(
        this.updateIteTaxRateLookupUrl,
        iteTaxRate,
        this.authService.getAuthOptions('application/json')
      )
      .pipe(
        map((res: IteTaxRateLookup) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  updateProgramManager(
    programType: string,
    newUserGuid: string,
    managerType: string
  ): Observable<ProgramManagerLookup> {
    return this.http
      .put(
        `${
          this.updateProgramManagerLookupUrl
        }/${programType}/${managerType}/${newUserGuid}`,
        {},
        this.authService.getAuthOptions('application/json')
      )
      .pipe(
        map((res: ProgramManagerLookup) => {
          return res;
        })
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return throwError(errMsg);
  }
}

<div class="modal-header py-3">
  <h2 class="modal-title">
    {{ pageTitle }}
  </h2>
</div>

<div class="modal-body py-3">
  <div class="card border border-primary" style="overflow:visible;">
    <div class="card-header bg-primary text-white">
      <b>USER DETAILS</b>
    </div>

    <div *ngIf="thenBlock || createMode; then usrBlock; else spin"></div>

    <div *ngIf="!createMode" class="row justify-content mx-2 px-5 py-2">
      <label class="form-control-label px-3">
        Send Password Reset Email &nbsp;&nbsp;
      </label>
      <button
        type="button"
        [ngClass]="{ active: sendResetEmail }"
        value="false"
        (click)="sendEmailsChanged($event.target.value, 'reset')"
        class="btn btn-toggle mx-3"
        aria-pressed="sendResetEmail"
      >
        <div class="handle"></div>
      </button>
    </div>
  </div>
</div>

<div class="modal-footer button-group pt-3 px-5 d-flex justify-content-end">
  <button type="button" class="btn btn-primary" (click)="cancel()">
    CANCEL
  </button>
  <button
    *ngIf="createMode"
    type="button"
    (click)="save()"
    class="btn btn-primary"
    [disabled]="form.invalid"
  >
    CREATE ACCOUNT
  </button>
  <button
    *ngIf="!createMode"
    type="button"
    (click)="update()"
    class="btn btn-primary"
    [disabled]="form.invalid"
  >
    UPDATE ACCOUNT
  </button>
</div>

<ng-template #usr>
  <div class="card-body form-group p-3 px-5" [formGroup]="form" required>
    <div class="d-flex py-1">
      <label class="col-4 form-control-label">Account Status:</label>
      <div class="col-8">
        <select class="form-control" formControlName="isActive">
          <option [value]="true">Active</option>
          <option [value]="false">Inactive</option>
        </select>
      </div>
    </div>

    <div class="d-flex py-1">
      <label class="col-4 form-control-label">User Type:</label>
      <div class="col-8">
        <select
          class="form-control"
          name="userType"
          id="userType"
          formControlName="userType"
          required
          (change)="resetPermissionsandPrograms(); switchChildForm()"
        >
          <option value="" disabled>Choose a user type</option>
          <option *ngFor="let option of userTypeOptions" [value]="option.key">{{
            option.value
          }}</option>
        </select>
      </div>
    </div>

    <div *ngIf="thenBlock; then thenBlock"></div>
  </div>
</ng-template>

<ng-template #assessor>
  <fl-user-detail-assessor
    [user]="user"
    [form]="form"
  ></fl-user-detail-assessor>
  <fl-event-notification-preferences
    [user]="user"
  ></fl-event-notification-preferences>
  <fl-permission-settings [user]="user"></fl-permission-settings>
</ng-template>

<ng-template #common>
  <fl-user-detail-applicant
    [user]="user"
    [form]="form"
  ></fl-user-detail-applicant>
</ng-template>

<ng-template #brd>
  <fl-user-detail-board [user]="user" [form]="form"></fl-user-detail-board>
  <fl-event-notification-preferences
    [user]="user"
  ></fl-event-notification-preferences>
</ng-template>

<ng-template #ext>
  <fl-user-detail-external
    [user]="user"
    [form]="form"
  ></fl-user-detail-external>
  <ng-container *ngTemplateOutlet="programSelectionTemplate"></ng-container>
  <fl-event-notification-preferences
    [user]="user"
    [agency]="user.externalReviewAgency"
  ></fl-event-notification-preferences>
  <fl-permission-settings [user]="user"></fl-permission-settings>
</ng-template>

<ng-template #mgr>
  <fl-user-detail-management
    [user]="user"
    [form]="form"
  ></fl-user-detail-management>
  <ng-container *ngTemplateOutlet="programSelectionTemplate"></ng-container>
  <fl-event-notification-preferences
    [user]="user"
  ></fl-event-notification-preferences>
  <fl-permission-settings [user]="user"></fl-permission-settings>
</ng-template>

<ng-template #auditor>
  <fl-user-detail-applicant
    [user]="user"
    [form]="form"
  ></fl-user-detail-applicant>
  <fl-auditor-assignment [form]="user"></fl-auditor-assignment>
</ng-template>

<ng-template #programSelectionTemplate>
  <div class="card border-primary m-t m-b">
    <div class="card-header bg-primary font-weight-bold">
      AVAILABLE PROGRAMS
      <i
        class="fas fa-exclamation-circle px-3 ml-2"
        [ngClass]="{
          'animated text-warning rubberBand infinite':
            user.incentivePrograms?.length === 0
        }"
        title="Choose at least one program"
        data-toggle="tooltip"
        data-placement="top"
      ></i>
    </div>
    <div class="card-body">
      <div id="incentiveProgramsForm" class="d-flex align-items-center">
        <fl-user-detail-program-selection
          [user]="user"
          [form]="form"
        ></fl-user-detail-program-selection>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #spin>
  <div class="col-12 py-4 d-flex align-items-center justify-content-center">
    <i class="fa fa-spinner fa-spin fa-3x"></i>
  </div>
</ng-template>

<!-- {{ user.userType }} {{ userPermissionMap[user.userType] | json }} {{ userNotificationMap[user.userType] | json }} -->
